import { operations } from "@openapi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import _ from "lodash";

export type BrandUsersResponse =
  operations["user_api_get_brand_users"]["responses"][200]["content"]["application/json"];
export type BrandUsersRequestParams =
  operations["user_api_get_brand_users"]["parameters"]["query"];

const getQueryKey = (brandID: string | null) => ["get_brand_users", brandID];

const useBrandUsersQuery = (brandID: string | null) => {
  return useQuery<BrandUsersResponse, BrandUsersRequestParams>({
    queryKey: getQueryKey(brandID),
    queryFn: async () => {
      const { data } = await axios.get(`/api/v1/user/list`, {
        params: { brand_id: brandID } as BrandUsersRequestParams,
      });
      return data;
    },
    enabled: !!brandID,
  });
};

export const useBrandUsersQueryDataPatch = () => {
  const queryClient = useQueryClient();
  return ({
    brandId,
    patchData: { removeEmails, upsertUsers },
  }: {
    brandId: string;
    patchData: {
      removeEmails?: string[];
      upsertUsers?: BrandUsersResponse["users"];
    };
  }) => {
    queryClient.setQueryData<BrandUsersResponse>(
      getQueryKey(brandId),
      (oldData) => {
        if (!oldData) return oldData;

        const removedEmailsSet = new Set(removeEmails);
        const [updatedUsers, insertedUsers] = _.partition(
          upsertUsers ?? [],
          (user) =>
            oldData.users.some((oldUser) => oldUser.email === user.email)
        );

        return {
          users: [
            ...(insertedUsers ?? []),
            ...oldData.users
              .filter((user) => !removedEmailsSet.has(user.email))
              .map((user) => {
                const updatedUser = updatedUsers.find(
                  (oldUser) => oldUser.email === user.email
                );
                return updatedUser ?? user;
              }),
          ],
        };
      }
    );
  };
};

export default useBrandUsersQuery;
