import BackgroundColorModifier from "./modifiers/BackgroundColorModifier";
import CollectionMetadataModifier from "./modifiers/CollectionMetadataModifier";
import CollectionModifier from "./modifiers/CollectionModifier";
import GeneratedVariantSelector from "./modifiers/GeneratedVariantSelector";
import TextModifier from "./modifiers/TextModifier";
import { EmailElementType } from "@openapi";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useInitEmailSectionElementMutation from "~/hooks/emails/useInitEmailSectionElementQuery";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { isCollectionGridSection } from "~/utils/emails/useSectionTypeCheck";

const CollectionGridView = () => {
  const activeBrandId = useActiveBrandID();
  const updateSection = useUpdateSectionField();

  const { selectedSectionId, sections } = useEmailState();

  const { mutate: initEmailSectionElement } =
    useInitEmailSectionElementMutation({
      onSuccess: (data) => {
        updateSection({
          sectionId: selectedSectionId,
          field: "collections",
          value: data,
        });
      },
    });

  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );

  if (!selectedSection) {
    return null;
  }

  if (!isCollectionGridSection(selectedSection)) {
    return null;
  }

  const title = selectedSection.title;
  const subtitle = selectedSection.subtitle;
  const collections = selectedSection.collections;

  return (
    <>
      <GeneratedVariantSelector />

      <CollectionModifier
        collections={collections}
        onCollectionChange={(collections) => {
          initEmailSectionElement({
            brand_id: activeBrandId,
            element_type: EmailElementType.collection,
            palette: selectedSection.palette,
            collection_element_defaults: collections.map((c) => {
              const existingCollectionElement =
                selectedSection.collections.find(
                  (collection) => collection.commerce_platform_item_id === c
                );

              return {
                commerce_platform_id: c,
                title: existingCollectionElement?.title
                  ? {
                      text: existingCollectionElement.title.text,
                      enabled: existingCollectionElement.title.enabled,
                    }
                  : null,
                description: existingCollectionElement?.description
                  ? {
                      text: existingCollectionElement.description.text,
                      enabled: existingCollectionElement.description.enabled,
                    }
                  : null,
                cta_button: existingCollectionElement?.cta_button
                  ? {
                      background_color:
                        existingCollectionElement.cta_button.background_color,
                      enabled: existingCollectionElement.cta_button.enabled,
                      text: existingCollectionElement.cta_button.text,
                    }
                  : null,
                background_image: existingCollectionElement?.background_image
                  ? {
                      image_url:
                        existingCollectionElement.background_image.image_url,
                      enabled:
                        existingCollectionElement.background_image.enabled,
                    }
                  : null,
              };
            }),
          });
        }}
        onDragEnd={(newCollections) => {
          updateSection({
            sectionId: selectedSectionId,
            field: "collections",
            value: newCollections,
          });
        }}
      />

      <TextModifier
        textElement={title}
        label="Title"
        enabled={title.enabled ?? false}
        fieldName="title.text"
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "title.enabled",
            value: enabled,
          });
        }}
      />
      <TextModifier
        textElement={subtitle}
        label="Subtitle"
        enabled={subtitle.enabled ?? false}
        fieldName="subtitle.text"
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "subtitle.enabled",
            value: enabled,
          });
        }}
      />

      <CollectionMetadataModifier collections={collections} />

      <BackgroundColorModifier
        sectionId={selectedSection.id}
        palette={selectedSection.palette}
      />
    </>
  );
};

export default CollectionGridView;
