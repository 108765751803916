import { getDefaultProperties, ToolbarButton } from "../../TipTap";
import { getLineHeight } from "../LineHeightExtension";
import AppButton from "@components/core/buttons/AppButton/AppButton";
import * as Popover from "@radix-ui/react-popover";
import { Flex, Text, Box, Theme } from "@radix-ui/themes";
import { Editor } from "@tiptap/react";
import { MoveVertical } from "lucide-react";
import { useState } from "react";
import NumberInput from "~/components/core/inputs/NumberInput";

const LineHeightButton = ({
  activeTipTapID,
  editor,
}: {
  activeTipTapID: string | null;
  editor: Editor;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover.Root
      open={open}
      onOpenChange={(value) => {
        setOpen(value);
      }}
    >
      <Popover.Trigger asChild>
        <ToolbarButton
          onClick={() => {
            setOpen(true);
          }}
          $isSelected={!!getLineHeight(editor)}
        >
          <MoveVertical size={16} />
        </ToolbarButton>
      </Popover.Trigger>
      <Popover.Portal>
        <Theme>
          <Popover.Content
            sideOffset={8}
            className="color-picker-content"
            align="start"
          >
            <Flex direction="column" gap="3">
              <Box>
                <Text as="label" size="1" mb="2">
                  Line Height
                </Text>
                <Flex align="center" gap="2">
                  <NumberInput
                    radius="large"
                    value={(
                      getLineHeight(editor) ??
                      getDefaultProperties(activeTipTapID).lineHeight
                    ).replace("px", "")}
                    style={{
                      maxWidth: "74px",
                      overflow: "hidden",
                    }}
                    onValueChange={(value) => {
                      editor
                        .chain()
                        .setLineHeight(value + "px")
                        .run();
                    }}
                  />
                  <Text size="2">px</Text>
                </Flex>
              </Box>
              <Flex gap="2" justify="between">
                <AppButton
                  size="2"
                  radius="medium"
                  variant="soft"
                  onClick={() => {
                    editor.chain().unsetLineHeight().run();
                  }}
                >
                  Clear
                </AppButton>
                <AppButton
                  size="2"
                  radius="medium"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Done
                </AppButton>
              </Flex>
            </Flex>
          </Popover.Content>
        </Theme>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default LineHeightButton;
