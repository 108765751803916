import {
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "../../contexts/CampaignWizardContext";
import { useCallback, useEffect, useRef } from "react";
import scrollIntoView from "scroll-into-view";
import scrollIntoViewIfNeeded from "scroll-into-view-if-needed";

const isChrome = "chrome" in window;
// can be transformed into a more generic function if needed
//  - most params are mappable between the two implementations
const scrollSmoothToCenter = (element: HTMLElement) => {
  if (isChrome) {
    // custom implementation for smooth scrolling, as Chrome is very buggy with it
    //  This works well with other browsers also, but causes some jumpiness on Safari
    scrollIntoView(element, { time: 300, maxSynchronousAlignments: 1 });
  } else {
    scrollIntoViewIfNeeded(element, {
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }
};

const useCenterCampaignCard = ({ cardStep }: { cardStep: number }) => {
  const { currentStep, transitionIteration } = useCampaignWizardState();
  const wizardDispatch = useCampaignWizardDispatch();
  const currentStepRef = useRef<number>(currentStep);
  const triggerRef = useRef<number>(transitionIteration);

  useEffect(() => {
    currentStepRef.current = currentStep;
  }, [currentStep]);

  useEffect(() => {
    triggerRef.current = transitionIteration;
  }, [transitionIteration]);

  const centerCard = useCallback((step: number) => {
    const element = document.getElementById(`step-${step}`);
    if (!element) {
      return;
    }
    setTimeout(() => {
      if (step !== currentStepRef.current) {
        return;
      }
      const trigger = triggerRef.current + 1;
      wizardDispatch({
        type: "SET_TRANSITION_ITERATION",
        payload: trigger,
      });
      scrollSmoothToCenter(element);

      setTimeout(() => {
        if (step !== currentStepRef.current) {
          return;
        }
        if (triggerRef.current !== trigger) {
          return;
        }
        // fallback to instant scroll, just in case some browsers
        //  still have issues with the smooth scrolling
        scrollIntoViewIfNeeded(element, {
          behavior: "instant",
          block: "center",
          inline: "center",
          scrollMode: "if-needed",
        });
      }, 1000);
    }, 50);
  }, []);

  const centerCurrentCard = useCallback(() => {
    if (cardStep !== currentStep) {
      return;
    }
    centerCard(currentStep);
  }, [cardStep, currentStep]);

  const centerCurrentCardSmooth = useCallback(() => {
    if (cardStep !== currentStep) {
      return;
    }
    const element = document.getElementById(`step-${currentStep}`);
    if (!element) {
      return;
    }
    scrollSmoothToCenter(element);
  }, [cardStep, currentStep]);

  return { centerCurrentCard, centerCurrentCardSmooth };
};

export default useCenterCampaignCard;
