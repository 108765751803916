import CampaignWizardLayout from "../../../components/campaign/wizard/layout/CampaignWizardLayout";
import {
  RecommendedCampaignWizardSteps,
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "../../../contexts/CampaignWizardContext";
import { RecommendedCampaignCards } from "../../../views/campaign/wizard/CampaignCards";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useCampaignRecommendationQuery from "~/hooks/campaign/useCampaignRecommendationQuery";

const CampaignWizardPage = () => {
  const activeBrandID = useActiveBrandID();
  const { currentRequiredStep, currentStep } = useCampaignWizardState();
  const dispatch = useCampaignWizardDispatch();
  const { recommendationID } = useParams() as { recommendationID: string };

  const { campaignRecommendation, isSuccess } = useCampaignRecommendationQuery(
    activeBrandID,
    recommendationID
  );

  useEffect(() => {
    dispatch({
      type: "SET_WIZARD_TYPE",
      payload: { wizardType: "RECOMMENDED", recommendationID },
    });
  }, []);

  useEffect(() => {
    if (isSuccess && campaignRecommendation) {
      dispatch({
        type: "UPDATE_CAMPAIGN_DATA",
        payload: {
          campaign_recommendation_id: campaignRecommendation.id,
        },
      });
    }
  }, [isSuccess, campaignRecommendation]);

  return (
    <CampaignWizardLayout>
      {(
        Object.values(
          RecommendedCampaignWizardSteps
        ) as RecommendedCampaignWizardSteps[]
      )
        .filter((value) => !isNaN(value))
        .map((value) => {
          const Card = RecommendedCampaignCards[value];
          return (
            <div
              style={{
                display:
                  currentRequiredStep >= Number(value) ? undefined : "none",
                pointerEvents:
                  currentStep === Number(value) ? undefined : "none",
                width: "800px",
              }}
              id={`step-${value}`}
              key={value}
            >
              <Card step={value} />
            </div>
          );
        })}
    </CampaignWizardLayout>
  );
};

export default CampaignWizardPage;
