import AppButton from "../core/buttons/AppButton/AppButton";
import TextFieldWithLabel from "../core/inputs/TextFieldWithLabel";
import { Flex, Text } from "@radix-ui/themes";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { MailIcon, Trash2Icon } from "~/assets/icons";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useBrandUsersQuery, {
  BrandUsersResponse,
} from "~/hooks/brand/useBrandUsersQuery";
import useInviteUserMutation from "~/hooks/user/useInviteUserMutation";
import useRemoveBrandUserMutation from "~/hooks/user/useRemoveBrandUserMutation";
import { isValidEmailAddress, sanitizeEmailAddress } from "~/utils/validators";

const Button = styled(AppButton)`
  width: max-content;
  margin-left: auto;
`;

const TableHead = styled.th`
  text-align: left;
  color: var(--text-tertiary);
  font-weight: 400;
  font-size: 14px;
  border-top: 1px solid rgba(240, 240, 240, 1);
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  background-color: rgba(252, 251, 250, 1);
  padding-top: 12px;
  padding-bottom: 12px;
  &:first-child {
    padding-left: 32px;
  }
  &:last-child {
    padding-right: 32px;
  }
`;

const TableCol = styled.td`
  padding-top: 12px;
  &:first-child {
    padding-left: 32px;
  }
  &:last-child {
    padding-right: 32px;
  }
`;

function userStatus(user: BrandUsersResponse["users"][number]) {
  if (user.type === "user") {
    if (user.is_active) {
      return _.capitalize(user.role);
    }
    return "Inactive";
  }
  return user.status;
}

const AccountSettingsPermissions = () => {
  const activeBrandID = useActiveBrandID();
  const { data: userResponse } = useBrandUsersQuery(activeBrandID);
  const users = userResponse?.users;
  const activeAdmins = useMemo(() => {
    return users?.filter(
      (user) => user.type === "user" && user.role === "admin" && user.is_active
    );
  }, [users]);

  const [emailInput, setEmailInput] = useState("");

  const { mutate: inviteUser, isPending: isInvitingUser } =
    useInviteUserMutation({
      onSuccess: () => {
        setEmailInput("");
      },
    });

  const handleClickInvite = () => {
    inviteUser({
      brand_id: activeBrandID,
      email: sanitizeEmailAddress(emailInput),
      resend_email: true,
    });
  };

  const removeUser = useRemoveBrandUserMutation();

  const handleDelete = (user: BrandUsersResponse["users"][number]) => {
    removeUser.mutate({
      brand_id: activeBrandID,
      email: user.email,
    });
  };

  return (
    <Flex gap="32px" direction="column">
      <Flex direction="column" gap="24px" px="32px" pt="32px">
        <Text weight="medium">Invite Admins</Text>
        <TextFieldWithLabel
          label="Email Address"
          type="email"
          required
          size="3"
          radius="large"
          value={emailInput}
          onChange={(e) => setEmailInput(e.target.value)}
        />
        <Button
          radius="large"
          onClick={handleClickInvite}
          disabled={isInvitingUser || !isValidEmailAddress(emailInput.trim())}
        >
          <MailIcon /> Send Invite
        </Button>
      </Flex>
      <table>
        <thead>
          <tr>
            <TableHead>Users</TableHead>
            <TableHead>Role/Status</TableHead>
            <TableHead style={{ width: 1 }}></TableHead>
          </tr>
        </thead>
        <tbody>
          {users?.map((user) => (
            <tr key={user.id}>
              <TableCol>
                <Flex direction="column">
                  {user.type === "user" && (
                    <Text size="3">{`${user.first_name} ${user.last_name}`}</Text>
                  )}
                  <Text size="2">{user.email}</Text>
                </Flex>
              </TableCol>
              <TableCol>{userStatus(user)}</TableCol>
              <TableCol>
                <AppButton
                  variant="outlined"
                  size="3"
                  radius="large"
                  onClick={() => handleDelete(user)}
                  disabled={
                    user.type === "user" &&
                    (user.is_brand_admin || (activeAdmins?.length ?? 0) <= 1)
                  }
                >
                  <Trash2Icon /> Remove
                </AppButton>
              </TableCol>
            </tr>
          ))}
        </tbody>
      </table>
    </Flex>
  );
};

export default AccountSettingsPermissions;
