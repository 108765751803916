import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";

interface MarkPopupContextState {
  markPopupOpen: boolean;
  message?: string;
}
const initialState: MarkPopupContextState = {
  markPopupOpen: false,
};
interface SetIsPopupOpen {
  type: "SET_IS_POPUP_OPEN";
  payload: boolean;
}
interface TogglePopup {
  type: "TOGGLE_POPUP";
}
interface SetMessage {
  type: "SET_MESSAGE";
  payload?: string;
}
interface ShowMarkPopup {
  type: "SHOW_MARK_POPUP";
  payload: string;
}
type MarkPopupDispatchAction =
  | SetIsPopupOpen
  | TogglePopup
  | SetMessage
  | ShowMarkPopup;
function reducer(
  state: MarkPopupContextState,
  action: MarkPopupDispatchAction
) {
  switch (action.type) {
    case "SET_IS_POPUP_OPEN":
      return {
        ...state,
        markPopupOpen: action.payload,
      };
    case "TOGGLE_POPUP":
      return { ...state, markPopupOpen: !state.markPopupOpen };
    case "SET_MESSAGE":
      return { ...state, message: action.payload, markPopupOpen: false };
    case "SHOW_MARK_POPUP":
      return { ...state, markPopupOpen: true, message: action.payload };
  }
}
const MarkPopupContext = createContext<MarkPopupContextState>(initialState);
const DispatchContext =
  createContext<React.Dispatch<MarkPopupDispatchAction> | null>(null);
export const MarkPopupProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <DispatchContext.Provider value={useMemo(() => dispatch, [dispatch])}>
      <MarkPopupContext.Provider value={useMemo(() => state, [state])}>
        {children}
      </MarkPopupContext.Provider>
    </DispatchContext.Provider>
  );
};

export const useMarkPopupDispatch = () => {
  const dispatch = useContext(DispatchContext);
  if (!dispatch) {
    throw "MarkPopup Context dispatch context is missing";
  }
  return dispatch;
};

export const useToggleMarkPopup = () => {
  const dispatch = useContext(DispatchContext);
  return useCallback(() => {
    dispatch?.({ type: "TOGGLE_POPUP" });
  }, [dispatch]);
};

export const useShowMarkPopup = () => {
  const dispatch = useContext(DispatchContext);
  return useCallback(
    (message: string) => {
      dispatch?.({ type: "SHOW_MARK_POPUP", payload: message });
    },
    [dispatch]
  );
};

export const useMarkPopupContext = () => {
  const state = useContext(MarkPopupContext);
  const dispatch = useContext(DispatchContext);
  if (!dispatch) {
    throw "MarkPopup Context dispatch context is missing";
  }
  return { state, dispatch };
};
