import ImageContainer from "./previews/ImageContainer";
import { AdMediaAspectRatio } from "@openapi";
import { Flex } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";
import { PreviewCardProps } from "~/components/ads/editor/Preview/AdPreview";
import FacebookAdCard from "~/components/ads/editor/Preview/ad_cards/facebook/FacebookAdCard";
import InstagramAdCard from "~/components/ads/editor/Preview/ad_cards/instagram/InstagramAdCard";
import StoryAdCard from "~/components/ads/editor/Preview/ad_cards/story/StoryAdCard";
import { useActiveBrandName } from "~/contexts/CurrentUserContext";
import { CampaignAdData } from "~/hooks/campaign/useGetCampaignDetails";

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

const CampaignOverviewAdContent = ({
  ad,
  destination,
}: {
  ad: CampaignAdData;
  destination: string;
}) => {
  const brandName = useActiveBrandName();
  const previewContent = (ratio: AdMediaAspectRatio) => {
    const mediaUrl = ad.preview_images.find(
      (preview) => preview.aspect_ratio === ratio
    )?.url;
    return (
      <ImageContainer>
        <Image src={mediaUrl} />
      </ImageContainer>
    );
  };

  const previewCardProps = (ratio: AdMediaAspectRatio): PreviewCardProps => ({
    brandName: brandName,
    destination: destination,
    content: previewContent(ratio),
    primaryText: ad.primary_text,
    headline: ad.title,
    style: {
      width: "100%",
      borderLeft: "none",
      borderRight: "none",
      border: "1px solid #DDDDDD",
    } as React.CSSProperties,
  });

  return (
    <Flex direction="column" gap="4">
      <FacebookAdCard {...previewCardProps(AdMediaAspectRatio.square)} />
      <InstagramAdCard {...previewCardProps(AdMediaAspectRatio.square)} />
      <StoryAdCard
        {...previewCardProps(AdMediaAspectRatio.full_screen_vertical)}
      />
    </Flex>
  );
};
export default CampaignOverviewAdContent;
