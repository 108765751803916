import CampaignPublishDialogChatAdsContainer from "./CampaignPublishDialogChatAdsContainer";
import CampaignPublishDialogChatEmailContainer from "./CampaignPublishDialogChatEmailContainer";

const CampaignPublishDialogChatContainer = ({
  campaignId,
  selectedAds,
  selectedEmails,
  selectedCampaignType,
  toggleSelectedCampaignType,
  onClearSelectedCreatives,
}: {
  campaignId: string;
  toggleSelectedCampaignType: () => void;
  selectedEmails: string[];
  selectedAds: string[];
  selectedCampaignType: "email" | "ads";
  onClearSelectedCreatives: (type: "ads" | "emails") => void;
}) => {
  if (selectedCampaignType === "email") {
    return (
      <CampaignPublishDialogChatEmailContainer
        campaignId={campaignId}
        selectedEmails={selectedEmails}
        toggleSelectedCampaignType={toggleSelectedCampaignType}
        onClearSelectedCreatives={onClearSelectedCreatives}
      />
    );
  }

  return (
    <CampaignPublishDialogChatAdsContainer
      campaignId={campaignId}
      selectedAds={selectedAds}
      toggleSelectedCampaignType={toggleSelectedCampaignType}
      onClearSelectedCreatives={onClearSelectedCreatives}
    />
  );
};

export default CampaignPublishDialogChatContainer;
