import { Flex, Heading, Skeleton } from "@radix-ui/themes";
import styled, { CSSProperties } from "styled-components";
import DraperText from "~/components/core/DraperText";
import ThreeSlotsPreview from "~/components/core/layout/ThreeSlotsPreview";

const Card = styled(Flex)<{ $opacity?: CSSProperties["opacity"] }>`
  padding: 20px;
  border: 1px solid var(--border-primary);
  border-radius: 32px;
  background-color: var(--primary-white);
  box-shadow: 0px 4px 8px 0px #0000000a;
  ${({ $opacity }) => $opacity && `opacity: ${$opacity};`}
`;

const CampaignPlaceholderSlot = styled.div`
  flex: 1;
  background-color: #ff9999;
`;

const CampaignPlaceholder: React.FC<{ opacity?: CSSProperties["opacity"] }> = ({
  opacity,
}) => (
  <Card direction="row" gap="5" height="225px" $opacity={opacity}>
    <ThreeSlotsPreview width="300px">
      <CampaignPlaceholderSlot />
      <CampaignPlaceholderSlot />
      <CampaignPlaceholderSlot />
    </ThreeSlotsPreview>

    <Flex gap="5" direction="column" width="100%" justify="between">
      <Flex gap="3" direction="column">
        <Heading size="6" style={{ fontWeight: "600" }} as="h4">
          Campaign
        </Heading>
        <DraperText size="4" weight="medium" variant="secondary">
          Coming soon...
        </DraperText>
      </Flex>
      <Flex align="center" gap="3" width="100%">
        <Skeleton width="122px" height="25px" />
        <Skeleton width="75%" height="20px" />
      </Flex>
      <Skeleton
        width="191px"
        height="48px"
        style={{
          borderRadius: "100px",
        }}
      />
    </Flex>
  </Card>
);

const RecommenderZeroState = () => {
  return (
    <Flex
      direction="column"
      gap="5"
      width="100%"
      maxWidth="1200px"
      overflow="hidden"
      pt="26px"
    >
      <CampaignPlaceholder />
      <CampaignPlaceholder opacity="0.5" />
      <CampaignPlaceholder opacity="0.5" />
    </Flex>
  );
};

export default RecommenderZeroState;
