import GenerateVariantButton from "./GenerateVariantButton";
import { Box, Flex } from "@radix-ui/themes";
import styled from "styled-components";
import { CheckIcon } from "~/assets/icons";

const VariantBox = styled(Box)<{ $imageUrl?: string }>`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  width: 80px;
  height: 80px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top;
  ${({ $imageUrl }) => $imageUrl && `background-image: url(${$imageUrl});`}
`;

const Overlay = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0 4px 0 4px;
`;

type LayoutVariantItem = {
  preview?: string;
  id: string;
};

interface LayoutVariantsProps {
  isGenerating?: boolean;
  generationError?: string | null;
  onClickGenerate: () => void;
  onSelect: (index: number) => void;
  variants: LayoutVariantItem[];
  selectedIndex: number;
}

const LayoutVariants: React.FC<LayoutVariantsProps> = ({
  isGenerating = false,
  generationError,
  onClickGenerate,
  onSelect,
  variants,
  selectedIndex,
}) => {
  return (
    <Flex direction="column" gap="4">
      <GenerateVariantButton
        isGenerating={isGenerating}
        serverError={generationError}
        onClick={onClickGenerate}
      />

      <Flex direction="row" gap="2" wrap="wrap">
        {variants.map((variant, index) => (
          <VariantBox
            $imageUrl={variant.preview}
            key={variant.id}
            onClick={() => {
              onSelect(index);
            }}
          >
            {index === selectedIndex && (
              <Overlay align="center" justify="center">
                <CheckIcon size={24} color="white" />
              </Overlay>
            )}
          </VariantBox>
        ))}
      </Flex>
    </Flex>
  );
};

export default LayoutVariants;
