import styled from "styled-components";

const Glassmorph = styled.div`
  cursor: default;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(13px);
`;

const GlassmorphicOverlay = ({ enabled }: { enabled?: boolean }) => {
  return enabled && <Glassmorph />;
};
export default GlassmorphicOverlay;
