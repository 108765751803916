import { useBrandUsersQueryDataPatch } from "../brand/useBrandUsersQuery";
import { operations } from "@openapi";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { toast } from "sonner";

type RequestData =
  operations["user_api_delete_brand_user"]["requestBody"]["content"]["application/json"];

const useRemoveBrandUserMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
} = {}) => {
  const patchBrandUsersQuery = useBrandUsersQueryDataPatch();
  return useMutation<undefined, AxiosError | Error, RequestData>({
    mutationFn: async (params) => {
      const response = await axios.delete("/api/v1/user/", {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken") ?? "",
        },
        data: params,
      });
      return response.data;
    },
    onError: (error, { email }) => {
      toast.error(`Failed to remove user ${email}`);
      onError?.(error);
    },
    onSuccess: (_, { brand_id, email }) => {
      toast.success(`User removed ${email}`);
      patchBrandUsersQuery({
        brandId: brand_id,
        patchData: { removeEmails: [email] },
      });
      onSuccess?.();
    },
  });
};

export default useRemoveBrandUserMutation;
