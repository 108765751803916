import DraperText from "../DraperText";
import ImagePreview from "../ImagePreview";
import AppButton from "../buttons/AppButton/AppButton";
import {
  components,
  GeneratedImageAssetCategory,
  ImageAssetOrientation,
} from "@openapi";
import { Flex, Spinner } from "@radix-ui/themes";
import { useMemo, useState } from "react";
import { toast } from "sonner";
import { CropIcon, ImageIcon, SparklesIcon } from "~/assets/icons";
import AssetSelectorDialog, {
  AssetSelectorItem,
} from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import CropImageDialog from "~/components/editor/dialogs/CropImageDialog";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useGetImageDimensionsAndSize from "~/hooks/common/useGetImageDimensionsAndSize";
import { GenerateImageAssetRequestData } from "~/hooks/style-library/useGenerateMediaImage";
import useGenerateMediaImageWithStatus from "~/hooks/style-library/useGenerateMediaImageWithStatus";
import { dataURIToFile } from "~/utils/fileUtils";
import { getFilenameFromUrl } from "~/utils/helpers";

export type RelatedAssetItem = AssetSelectorItem & {
  commerceId: string;
};

export type ImageSelectorProps = {
  value?: string | File;
  onSelect: (assets: AssetSelectorItem[]) => void;
  isUploading?: boolean;
  alt?: string;
  buttonText?: string;
  addButtonText?: string;
  onCrop?: (file: File) => void;
  assetsMetadata?: {
    relatedAssets?: RelatedAssetItem[];
    productCommerceId?: string;
    collectionCommerceId?: string;
    /**
     * True if it should only use passed in commerce id (product/collection) for
     * picking source for image generation
     * Default: false
     */
    keepCommerceId?: boolean;
  };
  onGenerateComplete?: (
    asset: components["schemas"]["ImageAssetSchema"]
  ) => void;
  generateMetadata?: Partial<
    Pick<GenerateImageAssetRequestData, "category" | "orientation">
  > & {
    buttonSuffix?: string;
  };
};

const ImageSelector: React.FC<ImageSelectorProps> = ({
  value,
  onSelect,
  isUploading = false,
  alt,
  addButtonText = "Add Image",
  buttonText = "Change",
  onCrop,
  assetsMetadata: {
    relatedAssets,
    productCommerceId,
    collectionCommerceId,
    keepCommerceId = false,
  } = {},
  generateMetadata,
  onGenerateComplete,
}) => {
  const activeBrandID = useActiveBrandID();
  const [cropOpen, setCropOpen] = useState(false);
  const [imageSelectorOpen, setImageSelectorOpen] = useState(false);

  const imageUrl = useMemo(() => {
    if (!value) {
      return;
    }
    if (typeof value === "string") {
      return value;
    }
    return URL.createObjectURL(value);
  }, [value]);
  const { dimensions, size } = useGetImageDimensionsAndSize(imageUrl);
  const fileName = imageUrl ? getFilenameFromUrl(imageUrl) : undefined;

  const generateButtonSuffix = generateMetadata?.buttonSuffix ?? "Hero Image";

  const handleCrop = (data: string) => {
    const file = dataURIToFile(
      data,
      fileName ? `${fileName}.png` : "cropped-image.png"
    );
    onCrop?.(file);
  };

  const { generateImageMutation, isGenerating } =
    useGenerateMediaImageWithStatus({
      onScheduleError(error) {
        toast.error(error);
      },
      onGenerationSuccess(data) {
        if (!data.image_asset) {
          return;
        }
        onGenerateComplete?.(data.image_asset);
      },
      onGenerationError(error) {
        toast.error(error);
      },
    });

  const canGenerate = relatedAssets && relatedAssets.length > 0;
  const isBusy = isUploading || isGenerating;
  const handleGenerateImage = () => {
    if (!relatedAssets || !relatedAssets.length) {
      toast.error("Not enough context to generate image");
      return;
    }

    const relatedCommerceId =
      relatedAssets.find((asset) => asset.url === imageUrl)?.commerceId ??
      productCommerceId ??
      collectionCommerceId;
    let compatibleAssets: RelatedAssetItem[] | undefined;
    if (keepCommerceId && relatedCommerceId) {
      compatibleAssets = relatedAssets.filter(
        (asset) => asset.commerceId === relatedCommerceId
      );
      if (!compatibleAssets.length) {
        compatibleAssets = relatedAssets;
      }
    } else {
      compatibleAssets = relatedAssets;
    }

    const index = Math.floor(Math.random() * compatibleAssets.length);
    const asset = compatibleAssets[index];
    const assetUrl = asset.url;
    const commerceId = asset.commerceId;
    if (!commerceId || !assetUrl) {
      toast.error("Not enough context to generate image");
      return;
    }

    generateImageMutation({
      brand_id: activeBrandID,
      category: GeneratedImageAssetCategory.hero,
      orientation:
        size && size.width > size.height
          ? ImageAssetOrientation.landscape
          : ImageAssetOrientation.portrait,
      image_asset_id: null,
      image_url: assetUrl,
      commerce_platform_item_id: commerceId,
      ...generateMetadata,
    });
  };

  const assetDialog = (
    <AssetSelectorDialog
      singleAsset
      isDialogOpen={imageSelectorOpen}
      relatedAssets={relatedAssets}
      onToggleDialogOpen={(open) => setImageSelectorOpen(open)}
      onSelect={onSelect}
      preselectedAssets={imageUrl ? [{ url: imageUrl }] : []}
    />
  );

  if (!imageUrl) {
    return (
      <>
        <AppButton
          disabled={isBusy}
          variant="outlined"
          size="3"
          radius="large"
          onClick={() => setImageSelectorOpen(true)}
        >
          <ImageIcon />
          {addButtonText}
        </AppButton>
        {assetDialog}
      </>
    );
  }

  return (
    <Flex direction="column" gap="16px">
      <Flex gap="16px">
        <ImagePreview
          src={imageUrl}
          alt={alt ?? "logo"}
          isLoading={isGenerating}
        />

        <Flex direction="column" gap="12px" overflow="hidden">
          <DraperText clamp={2} size="2" color="gray">
            {fileName ?? ""}
          </DraperText>
          <DraperText clamp={1} size="2" color="gray">
            {dimensions}
          </DraperText>
        </Flex>
      </Flex>
      <Flex direction="column" gap="3">
        <Flex gap="3" justify="between">
          <AppButton
            disabled={isBusy}
            variant="outlined"
            size="3"
            radius="large"
            style={{
              flex: 1,
            }}
            onClick={() => setImageSelectorOpen(true)}
          >
            <ImageIcon />
            {buttonText}
          </AppButton>
          {onCrop && (
            <>
              <AppButton
                variant="outlined"
                size="3"
                radius="large"
                disabled={!imageUrl}
                style={{ flex: 1 }}
                onClick={() => setCropOpen(true)}
              >
                <CropIcon />
                Crop
              </AppButton>
              {imageUrl && (
                <CropImageDialog
                  open={cropOpen}
                  onOpenChange={setCropOpen}
                  img={imageUrl}
                  onCrop={handleCrop}
                />
              )}
            </>
          )}
        </Flex>
        {onGenerateComplete && (
          <AppButton
            disabled={isBusy || !canGenerate}
            variant="outlined"
            size="3"
            radius="large"
            style={{ gridColumn: "span 2" }}
            onClick={handleGenerateImage}
          >
            {isGenerating ? <Spinner size="3" /> : <SparklesIcon />}
            {isGenerating
              ? `Generating ${generateButtonSuffix}...`
              : `Generate ${generateButtonSuffix}`}
          </AppButton>
        )}
      </Flex>
      {assetDialog}
    </Flex>
  );
};

export default ImageSelector;
