import { AudienceSelectorItem } from "./AudienceSelector";
import { Flex } from "@radix-ui/themes";
import AudienceChip from "~/components/assets/selector/AudienceChip";
import DraperText from "~/components/core/DraperText";

type AudienceChipSelectorProps = {
  label?: string;
  audiences: AudienceSelectorItem[];
  onDeselect: (id: string) => void;
  children: React.ReactNode;
} & Pick<React.ComponentProps<typeof AudienceChip>, "variant">;

const AudienceChipSelector: React.FC<AudienceChipSelectorProps> = ({
  label,
  audiences,
  onDeselect,
  children,
  variant,
}) => {
  return (
    <Flex direction="column" gap="3">
      {label && (
        <DraperText size="4" variant="secondary">
          {label}
        </DraperText>
      )}
      <Flex direction="row" gap="2" wrap="wrap">
        {audiences.map((audience) => (
          <AudienceChip
            key={audience.audience_id}
            id={audience.audience_id}
            onClick={onDeselect}
            text={audience.audience_name}
            variant={variant}
          />
        ))}
        {children}
      </Flex>
    </Flex>
  );
};

export default AudienceChipSelector;
