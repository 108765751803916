import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type CampaignDetailResponse =
  operations["campaign_api_get_campaign_detail"]["responses"][200]["content"]["application/json"];
export const getCampaignDetailsQueryKey = (campaignId?: string) => [
  "campaign-",
  campaignId,
];
export type CampaignEmailData =
  CampaignDetailResponse["email_creatives"][number];

export type CampaignAdData = CampaignDetailResponse["ad_creatives"][number];

const useGetCampaignDetails = ({ campaignId }: { campaignId?: string }) => {
  const campaignQuery = useQuery<CampaignDetailResponse>({
    queryKey: getCampaignDetailsQueryKey(campaignId),
    queryFn: async () => {
      const { data } = await axios.get(`/api/v1/campaign/${campaignId}`, {});
      return data;
    },
    retry: false,
    staleTime: Infinity,
    enabled: !!campaignId,
  });

  return campaignQuery;
};

export default useGetCampaignDetails;
