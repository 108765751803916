import { Flex, Text } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { NEW_CAMPAIGN_ROUTE } from "~/routes/constants";
import {
  campaignTypeButtonText,
  CampaignTypeUnion,
} from "~/views/campaign/wizard/hooks/useCampaignTypeProps";

const StyledButton = styled(AppButton)`
  flex: 1;
  background: var(--background_white);
  color: var(--primary-deep-purple);
  box-shadow: 0px 4px 16px 0px #00000014;
  &:hover {
    background-color: var(--background_light_grey_2);
  }
`;

const NewCampaignType: React.FC = () => {
  const navigate = useNavigate();
  const handleCreateCampaign = (value: CampaignTypeUnion) => {
    navigate(NEW_CAMPAIGN_ROUTE, { state: { campaignType: value } });
  };

  return (
    <Flex direction="row" gap="4" justify="between">
      {Object.keys(campaignTypeButtonText).map((value) => (
        <StyledButton
          key={value}
          onClick={() => handleCreateCampaign(value as CampaignTypeUnion)}
          variant="outlined"
          radius="large"
          style={{
            flex: 1,
            border: "1px solid var(--focus-8)",
            height: "64px",
          }}
        >
          {campaignTypeButtonText[value as CampaignTypeUnion].icon}
          <Text size="3">
            {campaignTypeButtonText[value as CampaignTypeUnion].text}
          </Text>
        </StyledButton>
      ))}
    </Flex>
  );
};

export default NewCampaignType;
