import classes from "./OnboardingPulse.module.css";
import React, { RefObject, useEffect, useState } from "react";

interface PulseStyles {
  width: React.CSSProperties["width"];
  height: React.CSSProperties["height"];
  borderRadius: React.CSSProperties["borderRadius"];
  top?: React.CSSProperties["top"];
  left?: React.CSSProperties["left"];
}

interface OnboardingPulseProps {
  children: JSX.Element | JSX.Element[];
  pulseStyle: PulseStyles | RefObject<HTMLDivElement>;
  enabled?: boolean;
}
const OnboardingPulse = ({
  children,
  pulseStyle,
  enabled,
}: OnboardingPulseProps) => {
  const [style, setStyle] = useState<PulseStyles | null>(null);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    if ("current" in pulseStyle) {
      //setting the styles inherited from the given element if that's the case
      if (!pulseStyle?.current) {
        return;
      }
      const elementStyles = window.getComputedStyle(pulseStyle.current);
      const elementPosition = pulseStyle.current.getBoundingClientRect();
      setStyle({
        width: elementPosition.width - 1,
        height: elementPosition.height - 1,
        borderRadius: elementStyles.borderRadius,
        top: elementPosition.top + elementPosition.height / 2,
        left: elementPosition.left + elementPosition.width / 2,
      });
    } else {
      //setting the styles directly otherwise
      setStyle(pulseStyle);
    }
  }, [pulseStyle, enabled]);

  return (
    <>
      {!!style && enabled && <div className={classes.pulse} style={style} />}
      {children}
    </>
  );
};
export default OnboardingPulse;
