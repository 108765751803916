import { campaignRecommendationTypeContent } from "../recommenderSection/RecommenderCard";
import { IconType } from "@icons-pack/react-simple-icons";
import { CampaignType, components } from "@openapi";
import { Flex, Text, Separator, Skeleton } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ArrowRightIcon, PackageOpenIcon } from "~/assets/icons";
import DraperText from "~/components/core/DraperText";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const campaignTypeContent: {
  [key in CampaignType]: {
    icon: IconType;
    title: string;
  };
} = {
  [CampaignType.new_products]: {
    icon: PackageOpenIcon,
    title: "New Products",
  },
  [CampaignType.inventory_clearance]: {
    icon: PackageOpenIcon,
    title: "Inventory Clearance",
  },
  [CampaignType.sales_event]: {
    icon: PackageOpenIcon,
    title: "Sales Event",
  },
  [CampaignType.other]: {
    icon: PackageOpenIcon,
    title: "Other",
  },
};

const Card = styled(Flex)`
  border: 1px solid var(--border-primary);
  border-radius: 32px;
  background-color: var(--primary-white);
  box-shadow: 0px 4px 8px 0px #0000000a;
`;

const Image = styled.img<{
  $aspectRatio: string;
  $roundCorners: "left" | "right";
  height: string;
}>`
  width: 100%;
  height: ${(props) => props.height};
  aspect-ratio: ${(props) => props.$aspectRatio};
  object-fit: cover;
  border-radius: ${(props) =>
    props.$roundCorners === "left" ? "12px 0 0 12px" : "0 12px 12px 0"};
  border: none;
`;

const TitleText = styled(DraperText)`
  font-family: var(--heading-font-family);
`;

const CampaignDraftCard = ({
  campaign,
  isLoading,
}: {
  campaign: components["schemas"]["CampaignSchema"];
  isLoading: boolean;
}) => {
  const navigate = useNavigate();

  const IconComponent = campaign.campaign_recommendation
    ? campaignRecommendationTypeContent[
        campaign.campaign_recommendation.item_set_type
      ].icon
    : campaignTypeContent[campaign.type].icon;

  const emailImages = campaign.email_images ?? [];
  const adImages = campaign.ad_images ?? [];

  return (
    <Card direction="column" p="16px" gap="5" justify="between">
      <Flex direction="column" gap="4">
        <Skeleton loading={isLoading}>
          <Flex style={{ borderRadius: "12px" }}>
            <Image
              height="220px"
              $aspectRatio="0.5"
              $roundCorners="left"
              src={emailImages[0] ?? adImages[1] ?? ""}
              alt=""
            />
            <Image
              height="220px"
              $aspectRatio="0.5"
              $roundCorners="right"
              src={adImages[0] ?? emailImages[1] ?? ""}
              alt=""
            />
          </Flex>
        </Skeleton>

        <Skeleton loading={isLoading}>
          <TitleText size="7" clamp={2} weight="medium" wordBreak="break-word">
            {campaign.campaign_recommendation?.creative_recommendations?.[0]
              ?.title ?? campaign.title}
          </TitleText>
        </Skeleton>

        <Flex gap="16px" align="center" style={{ color: "#3b3b3b" }}>
          <Flex align="center" gap="10px">
            {IconComponent && (
              <IconComponent
                size={24}
                style={{ color: "var(--primary-vibrant-orange)" }}
              />
            )}
            <Text size="3" weight="medium">
              <Skeleton loading={isLoading}>
                {campaign.campaign_recommendation
                  ? campaignRecommendationTypeContent[
                      campaign.campaign_recommendation.item_set_type
                    ].title
                  : campaignTypeContent[campaign.type].title}
              </Skeleton>
            </Text>
          </Flex>
          <Separator orientation="vertical" size="4" />
          <Text size="3" weight="medium">
            <Skeleton loading={isLoading}>
              {campaign.products_count || 0}{" "}
              {campaign.products_count === 1 ? "Product" : "Products"}
            </Skeleton>
          </Text>
        </Flex>
      </Flex>

      <Skeleton loading={isLoading}>
        <AppButton
          variant="outlined"
          onClick={() => navigate(`/campaign/${campaign.id}`)}
          style={{ width: "max-content" }}
        >
          Resume Editing
          <ArrowRightIcon />
        </AppButton>
      </Skeleton>
    </Card>
  );
};

export default CampaignDraftCard;
