import { AudienceSelectorItem } from "./AudienceSelector";
import { useMemo } from "react";
import DraperPill from "~/components/core/DraperPill";

interface AudiencePillProps {
  variant?: "target" | "exclude";
  audience?: Pick<AudienceSelectorItem, "audience_name">;
}

const AudiencePill: React.FC<AudiencePillProps> = ({
  variant = "target",
  audience,
}) => {
  if (!audience) {
    return (
      <DraperPill backgroundColor="rgba(255, 0, 0, 0.2)">
        No audiences selected
      </DraperPill>
    );
  }

  return (
    <DraperPill
      backgroundColor={
        variant === "target" ? undefined : "rgba(255, 0, 0, 0.3)"
      }
    >
      {audience.audience_name}
    </DraperPill>
  );
};

export default AudiencePill;
