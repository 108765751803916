import { Heading } from "@radix-ui/themes";
import anime, { AnimeInstance } from "animejs";
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import styled from "styled-components";

const Span = styled.span`
  font-family: unset;
`;

const getRelativeBounds = (el: Element, inputRect?: DOMRect) => {
  const rect = inputRect ?? el.getClientRects().item(0);
  if (!rect) {
    return { x: 0, y: 0, maxX: 0, maxY: 0 };
  }
  const x = rect.left - (el.parentElement?.getBoundingClientRect()?.left ?? 0);
  const y = rect.top - (el.parentElement?.getBoundingClientRect()?.top ?? 0);
  return {
    x,
    y,
    maxX: x + rect.width,
    maxY: y,
  };
};

const generateWordFragments = (
  text: string,
  {
    minWords = 2,
    maxWords = 5,
    maxFragments,
  }: { minWords?: number; maxWords?: number; maxFragments?: number } = {}
) => {
  const words = text.split(" ");
  const minFragmentLength = maxFragments
    ? Math.max(Math.round(words.length / maxFragments), minWords)
    : minWords;
  let i = 0;
  let fragments: string[] = [];
  while (i < words.length) {
    const fragmentLength = Math.max(
      Math.ceil(Math.random() * Math.min(words.length - i, maxWords)),
      minFragmentLength
    );
    const fragment =
      (i === 0 ? "" : " ") + words.slice(i, i + fragmentLength).join(" ");
    fragments.push(fragment);
    i += fragmentLength;
  }
  return fragments;
};

type TypingAnimationProps = {
  delay?: number;
  // mode?: "letters" | "fragments";
  onAnimationComplete?: () => void;
  fragmentDelay?: number;
  letterDelay?: number;
  disabled?: boolean;
  cursorRef?: React.RefObject<HTMLDivElement>;
  children: string;
} & Omit<React.ComponentProps<typeof Heading>, "children" | "as" | "ref">;

type FragmentKeyFrames = {
  delay: number;
  duration: number;
};

const TypingAnimation: React.FC<TypingAnimationProps> = ({
  delay = 300,
  fragmentDelay = 50,
  letterDelay = 5,
  disabled = false,
  onAnimationComplete,
  cursorRef,
  children,
  ...props
}) => {
  /**
   * "letters" | "fragments"
   * with "fragments" mode, the cursor gets out of sync atm
   */
  const mode: string = "letters";
  const animationRef = useRef<AnimeInstance | null>(null);
  const typingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    typingRef.current?.style?.setProperty("opacity", disabled ? "1" : "0");
  }, [disabled]);

  useEffect(() => {
    if (disabled) {
      if (animationRef.current) {
        animationRef.current.pause();
      }
      if (typingRef.current) {
        typingRef.current.innerHTML = children;
      }
      return;
    }
    if (!typingRef.current || animationRef.current) {
      return;
    }

    typingRef.current.innerHTML = "";
    const fragments =
      mode === "fragments" ? generateWordFragments(children) : [children];

    const content = fragments.map((fragment, i) => (
      <Span key={i}>
        {fragment.split("").map((char, j) => (
          <Span key={j} className="letter">
            {char}
          </Span>
        ))}
      </Span>
    ));
    typingRef.current.innerHTML = ReactDOMServer.renderToString(content);

    let totalTime = delay;
    const keyframes: FragmentKeyFrames[] = fragments.map((fragment) => {
      const keyframe = {
        delay: totalTime,
        duration: fragment.length * letterDelay,
      };

      totalTime += keyframe.duration + fragmentDelay;
      return keyframe;
    });
    totalTime -= fragmentDelay;

    animationRef.current = anime.timeline().add({
      targets: typingRef.current.querySelectorAll(".letter"),
      opacity: [0, 1],
      easing: "linear",
      duration: 250,
      delay: (el, i) => {
        if (mode === "letters") {
          return delay + letterDelay * i;
        }
        const fragment = el.parentElement;
        const root = fragment?.parentElement;
        if (!root || !fragment) {
          return delay + letterDelay * i;
        }
        const fragmentIndex = Array.prototype.indexOf.call(
          root.children,
          fragment
        );
        const keyframe = keyframes[fragmentIndex];

        return keyframe.delay + letterDelay * i;
      },
      begin: (anim) => {
        if (!cursorRef?.current || !typingRef.current) {
          return;
        }
        const root = typingRef.current;
        const cursor = cursorRef.current;
        const { x: cursorX, y: cursorY } = getRelativeBounds(root);

        root.style.setProperty("opacity", "1");

        cursor.style.setProperty("display", "block");
        cursor.style.setProperty("top", `${cursorY + 2}px`);
        cursor.style.setProperty("left", `${cursorX ?? 0}px`);

        keyframes.forEach((keyframe, i) => {
          setTimeout(() => {
            const fragment = typingRef.current?.children[i];
            if (!fragment) {
              return;
            }
            const rects = fragment.getClientRects();
            const totalWidth = Array.from(rects).reduce(
              (acc, rect) => acc + rect.width,
              0
            );
            let currentWidth = 0;

            Array.from(rects).forEach((rect, i) => {
              const { x, y, maxX, maxY } = getRelativeBounds(fragment, rect);

              setTimeout(() => {
                cursor.style.setProperty("transition", "none");
                cursor.style.setProperty("top", `${cursorY + y + 2}px`);
                cursor.style.setProperty("left", `${cursorX + x}px`);

                setTimeout(() => {
                  cursorRef.current?.style?.setProperty(
                    "transition",
                    `${
                      ((rect.width / totalWidth) * keyframe.duration) / 1000
                    }s all`
                  );
                  cursorRef.current?.style?.setProperty(
                    "top",
                    `${cursorY + maxY + 2}px`
                  );
                  cursorRef.current?.style?.setProperty(
                    "left",
                    `${cursorX + maxX}px`
                  );
                }, 10);
              }, (currentWidth / totalWidth) * keyframe.duration);
              currentWidth += rect.width;
            });
          }, keyframe.delay);
        });
      },
      complete: (anim) => {
        cursorRef?.current?.style?.setProperty("display", "none");
        onAnimationComplete?.();
      },
    });
  }, [typingRef, disabled]);

  return <Heading {...props} ref={typingRef} />;
};

export default TypingAnimation;
