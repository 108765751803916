import { Section, SectionType } from "../../../routes/root";
import OnboardingOverlay from "../../common/onboarding/OnboardingOverlay";
import { capitalizeFirstLetter } from "../../editor/utils";
import { Flex, Text } from "@radix-ui/themes";
import { useRef } from "react";
import { Link, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import styled from "styled-components";
import useUserOnboarding from "~/hooks/common/useUserOnboarding";

const LinkRoot = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: inherit;
  text-decoration: none;
  margin-bottom: 40px;
  cursor: pointer;
`;

const Icon = styled(Flex)<{ $isSelected: boolean }>`
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.$isSelected ? "var(--primary-deep-purple)" : "var(--primary-white)"};
  color: ${(props) =>
    props.$isSelected ? "var(--primary-white)" : "var(--primary-deep-purple)"};
  width: 56px;
  height: 56px;
  stroke-width: 1.5;
  border-radius: 50%;
  border: 1px solid var(--border-primary);
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: ${(props) =>
      props.$isSelected ? "var(--primary-deep-purple)" : "rgba(0, 0, 0, 0.1)"};
  }
`;

export function SidePanelNavigator({ section }: { section: Section }) {
  const resolved = useResolvedPath(section.route);
  const navigate = useNavigate();
  const isSelected = useMatch({
    path: resolved.pathname,
    end: false,
  });
  const sectionIcon = useRef<HTMLDivElement>(null);
  const icon = (
    <Icon $isSelected={!!isSelected} ref={sectionIcon}>
      <section.icon strokeWidth={1.3} size={"26px"} />
    </Icon>
  );

  const { shouldDisplay } = useUserOnboarding();
  const onboarding = shouldDisplay(section.onboardingData?.onboardedKey);
  return onboarding ? (
    <Flex direction="column" align="center" justify="center" gap="3" mb="40px">
      <OnboardingOverlay
        pulseElementRef={sectionIcon}
        pulseComponent={icon}
        onboardedKey={section.onboardingData?.onboardedKey}
        cardProps={{
          text: section.onboardingData?.text ?? "",
          proceedText: `Review ${section.title}`,
          onProceed: () =>
            navigate(section.onboardingData?.onboardingRoute ?? section.route),
        }}
        leftRelativeToElement={100}
      >
        <Text size="1" style={{ zIndex: onboarding ? 1 : 0 }}>
          {section.title ?? capitalizeFirstLetter(section.type)}
        </Text>
      </OnboardingOverlay>
    </Flex>
  ) : (
    <LinkRoot to={section.route}>
      {icon}
      <Text size="1">
        {section.title ?? capitalizeFirstLetter(section.type)}
      </Text>
    </LinkRoot>
  );
}
