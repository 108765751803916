import { useActiveBrandID } from "../../../contexts/CurrentUserContext";
import ChatMessageContainer from "../../chat/ChatMessageContainer";
import AppButton from "../../core/buttons/AppButton/AppButton";
import LoadingDots from "../wizard/misc/LoadingDots";
import { MessageType } from "./CampaignPublishDialogChatEmailContainer";
import { components, operations } from "@openapi";
import { Flex, Select, Text, Avatar } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { CheckIcon, PlusIcon } from "lucide-react";
import { useState, useRef, useEffect, useMemo } from "react";
import { toast } from "sonner";
import styled from "styled-components";
import SpinningLoader from "~/components/core/SpinningLoader";
import MetaNewAdSetDialog from "~/components/dialogs/MetaNewAdSetDialog";
import useGetCampaignDetails, {
  CampaignDetailResponse,
} from "~/hooks/campaign/useGetCampaignDetails";
import { getCampaignDetailsQueryKey } from "~/hooks/intern/useInternCampaignsCampaignQuery";
import useMetaAdAccountsQuery from "~/hooks/meta/useMetaAdAccountsQuery";
import useMetaAdsetsQuery from "~/hooks/meta/useMetaAdsetsQuery";
import useMetaBusinessPagesQuery from "~/hooks/meta/useMetaBusinessPagesQuery";
import useMetaCampaignsQuery from "~/hooks/meta/useMetaCampaignsQuery";
import { getUnpublishedCreatives } from "~/utils/campaign/utils";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

const Root = styled(Flex)`
  border-right: 1px solid var(--border-primary);
  width: 430px;
  flex-shrink: 0;
  height: 100%;
  padding: 24px;
  gap: 24px;
`;

const SelectTrigger = styled(Select.Trigger)`
  height: 48px;
  border-radius: 12px;
`;

const MessageContainer = styled(Flex)`
  padding: 24px;
  border-radius: 16px;
  border: 1px solid var(--border-primary);
  justify-content: center;
  align-items: center;
  width: 100%;
`;

type GenMetaAdCreativeResponse =
  operations["meta_api_gen_meta_ad_creative"]["responses"]["200"]["content"]["application/json"];
type GenMetaAdCreativeRequestData =
  operations["meta_api_gen_meta_ad_creative"]["requestBody"]["content"]["application/json"];

type SystemMessageType = "publish" | "review-emails";

type Message = {
  id: string;
  content: string | SystemMessageType;
  type: MessageType;
};

const ChatSystemMessage = ({
  campaignId,
  selectedAds,
  addMessage,
  systemMessage,
  toggleSelectedCampaignType,
  onClearSelectedCreatives,
}: {
  campaignId: string;
  selectedAds: string[];
  addMessage: (message: Message) => void;
  systemMessage: SystemMessageType;
  toggleSelectedCampaignType: () => void;
  onClearSelectedCreatives: (type: "ads" | "emails") => void;
}) => {
  const { data: campaignDetail, isLoading } = useGetCampaignDetails({
    campaignId,
  });
  const isDisabled = selectedAds.length === 0;
  const [selectedAdAccount, setSelectedAdAccount] = useState<string | null>(
    null
  );
  const [selectedCampaign, setSelectedCampaign] = useState<string | null>(null);
  const [selectedAdSet, setSelectedAdSet] = useState<string | null>(null);
  const [selectedPage, setSelectedPage] = useState<
    components["schemas"]["BusinessPage"] | null
  >(null);

  const queryClient = useQueryClient();
  const activeBrandID = useActiveBrandID();
  const { adAccounts, isLoading: isLoadingAdAccounts } =
    useMetaAdAccountsQuery(activeBrandID);
  const {
    campaignsData,
    fetchNextCampaigns,
    hasNextCampaigns,
    isFetchingNextCampaigns,
  } = useMetaCampaignsQuery(selectedAdAccount);
  const { adSetsData, fetchNextAdSets, hasNextAdSets, isFetchingNextAdSets } =
    useMetaAdsetsQuery(selectedAdAccount, selectedCampaign);
  const { businessPages } = useMetaBusinessPagesQuery(selectedAdAccount);

  const campaigns =
    campaignsData?.pages.flatMap((page) => page.campaigns) ?? [];
  const adSets = adSetsData?.pages.flatMap((page) => page.ad_sets) ?? [];

  const createAdCreativeMutation = useDraperApiPostMutation<
    GenMetaAdCreativeResponse,
    GenMetaAdCreativeRequestData
  >({
    path: "/meta/create-ad-creative",
    mutationKey: ["create-ad-creative"],
    onSuccess: (data) => {
      toast.success("Ad created");

      queryClient.setQueryData(
        getCampaignDetailsQueryKey(campaignId),
        (oldData: CampaignDetailResponse) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            ad_creatives: oldData.ad_creatives.map((creative) => {
              const publishedCreative = data.find((p) => p.id === creative.id);
              return publishedCreative || creative;
            }),
          };
        }
      );

      if (!campaignDetail) return;

      const unpublishedAdCount = getUnpublishedCreatives(
        campaignDetail
      ).ads.filter((ad) => !selectedAds.includes(ad.id)).length;
      const unpublishedEmailCount =
        getUnpublishedCreatives(campaignDetail).emails.length;

      if (unpublishedAdCount > 0) {
        addMessage({
          id: "unpublished",
          content: `Would you like to publish the remaining ${
            unpublishedAdCount === 1 ? "ad" : "ads"
          }?`,
          type: "ai",
        });
        addMessage({
          id: "publish-or-cancel",
          content: "system",
          type: "system",
        });
      } else {
        addMessage({
          id: "success",
          content: `You've published all your ads.`,
          type: "ai",
        });
        if (unpublishedEmailCount > 0) {
          addMessage({
            id: "success",
            content: "Would you like to review the emails?",
            type: "ai",
          });
          addMessage({
            id: "review-emails",
            content: "review-emails",
            type: "system",
          });
        }
      }
      onClearSelectedCreatives("ads");
    },
    onError: () => {
      toast.error("Ad creation failed");
    },
  });

  const handlePublishAds = () => {
    if (selectedAdAccount && selectedAdSet && selectedPage) {
      createAdCreativeMutation.mutate({
        meta_ad_account_id: selectedAdAccount,
        adset_id: selectedAdSet,
        ad_creative_ids: selectedAds,
        page: selectedPage,
      });
    }
  };

  if (isLoadingAdAccounts) {
    return <LoadingDots />;
  }

  if (
    createAdCreativeMutation.isPending ||
    createAdCreativeMutation.isSuccess
  ) {
    return (
      <MessageContainer>
        <Flex gap="12px" align="center">
          {createAdCreativeMutation.isPending ? (
            <SpinningLoader />
          ) : (
            <CheckIcon />
          )}
          <Text size="3" weight="medium">
            {createAdCreativeMutation.isPending
              ? `Sending ${selectedAds.length === 1 ? "ad" : "ads"} to Meta...`
              : `Successfully sent ${
                  selectedAds.length === 1 ? "ad" : "ads"
                } to Meta`}
          </Text>
        </Flex>
      </MessageContainer>
    );
  }

  const reviewEmailsButton = (
    <AppButton
      disabled={createAdCreativeMutation.isPending}
      radius="large"
      size="4"
      variant="outlined"
      onClick={toggleSelectedCampaignType}
    >
      <Text size="3" weight="medium">
        Review Emails
      </Text>
    </AppButton>
  );

  if (systemMessage === "review-emails") {
    return reviewEmailsButton;
  }

  return (
    <Flex direction="column" gap="12px" width="100%">
      <Flex direction="column" gap="12px" width="100%">
        <Text weight="medium">Meta Account</Text>
        <Select.Root
          value={selectedAdAccount || undefined}
          onValueChange={(value) => {
            setSelectedAdAccount(value);
            setSelectedCampaign(null);
            setSelectedAdSet(null);
          }}
        >
          <SelectTrigger color="gray" placeholder="Select Meta Account" />
          <Select.Content>
            {adAccounts?.map((account) => (
              <Select.Item key={account.account_id} value={account.account_id}>
                <Flex align="center" gap="2">
                  <Avatar
                    size="1"
                    src={account.profile_picture_url ?? undefined}
                    fallback={account.name[0]}
                    radius="medium"
                  />
                  <Text>{account.name}</Text>
                </Flex>
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
      </Flex>
      {selectedAdAccount && (
        <Flex direction="column" gap="12px" width="100%">
          <Text weight="medium">Meta Campaign</Text>
          <Select.Root
            value={selectedCampaign || undefined}
            onValueChange={(value) => {
              setSelectedCampaign(value);
              setSelectedAdSet(null);
            }}
          >
            <SelectTrigger color="gray" placeholder="Select Meta Campaign" />
            <Select.Content>
              {campaigns.map((campaign) => (
                <Select.Item key={campaign.id} value={campaign.id}>
                  {campaign.name}
                </Select.Item>
              ))}
              {hasNextCampaigns && (
                <Select.Item
                  value="load-more"
                  onSelect={() => fetchNextCampaigns()}
                >
                  {isFetchingNextCampaigns ? "Loading..." : "Load more..."}
                </Select.Item>
              )}
            </Select.Content>
          </Select.Root>
        </Flex>
      )}
      {selectedAdAccount && selectedCampaign && (
        <Flex direction="column" gap="12px" width={"100%"}>
          <Flex justify="between" align="center">
            <Text weight="medium">Ad Set</Text>
            <MetaNewAdSetDialog
              campaignID={selectedCampaign}
              metaAdAccountID={selectedAdAccount}
              trigger={
                <AppButton variant="ghost" radius="large" size="2">
                  <PlusIcon size="20" /> New
                </AppButton>
              }
              onSuccess={setSelectedAdSet}
            />
          </Flex>
          <Select.Root
            value={selectedAdSet || undefined}
            onValueChange={(value) => setSelectedAdSet(value)}
          >
            <SelectTrigger color="gray" placeholder="Select Ad Set" />
            <Select.Content>
              {adSets.map((adSet) => (
                <Select.Item key={adSet.id} value={adSet.id}>
                  {adSet.name}
                </Select.Item>
              ))}
              {hasNextAdSets && (
                <Select.Item
                  value="load-more"
                  onSelect={() => fetchNextAdSets()}
                >
                  {isFetchingNextAdSets ? "Loading..." : "Load more..."}
                </Select.Item>
              )}
            </Select.Content>
          </Select.Root>
        </Flex>
      )}
      {selectedAdAccount && selectedCampaign && selectedAdSet && (
        <Flex direction="column" gap="12px">
          <Text weight="medium">Publishing Page</Text>
          <Select.Root
            value={selectedPage?.id || undefined}
            onValueChange={(value) =>
              setSelectedPage(
                businessPages?.find((page) => page.id === value) ?? null
              )
            }
          >
            <SelectTrigger color="gray" placeholder="Select Publishing Page" />
            <Select.Content>
              {(businessPages ?? []).map((page) => (
                <Select.Item key={page.id} value={page.id}>
                  <Flex align="center" gap="2">
                    <Avatar
                      size="1"
                      src={page.picture_url}
                      fallback={page.name[0]}
                    />
                    <Text>{page.name}</Text>
                  </Flex>
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
          {selectedPage?.instagram_business_account && (
            <>
              <Text size="2" weight="medium">
                Instagram Page
              </Text>
              <Flex
                align="center"
                gap="2"
                p="2"
                style={{
                  border: "1px solid var(--border-primary)",
                  borderRadius: "12px",
                }}
              >
                <Avatar
                  size="1"
                  radius="full"
                  src={
                    selectedPage.instagram_business_account.profile_picture_url
                  }
                  fallback={selectedPage.instagram_business_account.username}
                />
                <Flex direction="column">
                  <Text size="2" weight="medium">
                    @{selectedPage.instagram_business_account.username}
                  </Text>
                  <Text size="1" color="gray">
                    ID: {selectedPage.instagram_business_account.id}
                  </Text>
                </Flex>
              </Flex>
            </>
          )}
        </Flex>
      )}

      {selectedAdAccount &&
        selectedCampaign &&
        selectedAdSet &&
        selectedPage && (
          <AppButton
            radius="large"
            style={{ width: "max-content" }}
            onClick={handlePublishAds}
            disabled={isDisabled || createAdCreativeMutation.isPending}
          >
            {createAdCreativeMutation.isPending
              ? "Publishing..."
              : "Publish Ads"}
          </AppButton>
        )}
    </Flex>
  );
};

const CampaignPublishDialogChatAdsContainer = ({
  campaignId,
  selectedAds,
  toggleSelectedCampaignType,
  onClearSelectedCreatives,
}: {
  campaignId: string;
  selectedAds: string[];
  toggleSelectedCampaignType: () => void;
  onClearSelectedCreatives: (type: "ads" | "emails") => void;
}) => {
  const [messageQueue, setMessageQueue] = useState<Message[]>([]);
  const messagesRef = useRef<Message[]>([]);

  const { data: campaignDetail, isLoading } = useGetCampaignDetails({
    campaignId,
  });

  console.log("selectedAds container", selectedAds);

  // Initialize messages
  useEffect(() => {
    if (isLoading || !campaignDetail || messagesRef.current.length > 0) return;

    const unpublishedEmailsCount =
      getUnpublishedCreatives(campaignDetail).emails.length;
    const unpublishedAdsCount =
      getUnpublishedCreatives(campaignDetail).ads.length;

    let initialMessages: Message[] = [];

    if (unpublishedAdsCount > 0) {
      initialMessages = [
        {
          id: "0",
          content: `You have ${
            unpublishedAdsCount === 1 ? "1 ad" : `${unpublishedAdsCount} ads`
          } to publish.`,
          type: "ai" as MessageType,
        },
        {
          id: "1",
          content: "publish",
          type: "system" as MessageType,
        },
      ];
    } else {
      initialMessages = [
        {
          id: "0",
          content: "You've already published all your ads.",
          type: "ai" as MessageType,
        },
      ];

      if (unpublishedEmailsCount > 0) {
        initialMessages.push({
          id: "1",
          content: "Should we start reviewing the emails?",
          type: "ai" as MessageType,
        });
        initialMessages.push({
          id: "2",
          content: "review-emails",
          type: "system" as MessageType,
        });
      }
    }

    messagesRef.current = [];
    setMessageQueue(initialMessages);
  }, [isLoading]);

  // Process message queue
  useEffect(() => {
    if (messageQueue.length > 0) {
      const timer = setTimeout(() => {
        const newMessages = [...messagesRef.current, messageQueue[0]];
        messagesRef.current = newMessages;
        setMessageQueue((prev) => prev.slice(1));
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [messageQueue]);

  const chatUI = useMemo(
    () =>
      isLoading || messagesRef.current.length === 0 ? (
        <ChatMessageContainer message={<LoadingDots />} animate={true} />
      ) : (
        messagesRef.current.map((message) => {
          if (message.type === "system") {
            return (
              <ChatSystemMessage
                campaignId={campaignId}
                selectedAds={selectedAds}
                systemMessage={message.content as SystemMessageType}
                toggleSelectedCampaignType={toggleSelectedCampaignType}
                onClearSelectedCreatives={onClearSelectedCreatives}
                addMessage={(message: Message) => {
                  setMessageQueue((prev) => [
                    ...prev,
                    {
                      ...message,
                      id: String(messagesRef.current.length),
                    },
                  ]);
                }}
              />
            );
          }
          return (
            <ChatMessageContainer
              key={message.id}
              message={message.content}
              animate={true}
            />
          );
        })
      ),
    [messagesRef.current, isLoading, selectedAds]
  );

  return (
    <Root direction="column" overflowY={"auto"}>
      {chatUI}
    </Root>
  );
};

export default CampaignPublishDialogChatAdsContainer;
