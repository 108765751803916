import React, { useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";

const EmailEditorPreviewIFrame = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe) return;

    const doc = iframe.contentDocument || iframe.contentWindow?.document;
    if (!doc) return;

    doc.open();
    doc.write(
      `<!DOCTYPE html><html><head>
        <style type="text/css">
          #outlook a {
            padding: 0;
          }

          body {
            margin: 0;

            -webkit-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
          }


          img {
            border: 0;
            height: auto;
            line-height: 100%;
            outline: none;
            text-decoration: none;
            -ms-interpolation-mode: bicubic;
          }
        </style>
        <style type="text/css">
          @media only screen and (min-width:480px) {
            .mj-column-per-66-66666666666666 {
              width: 66.66666666666666% !important;
              max-width: 66.66666666666666%;
            }

            .mj-column-per-33-33333333333333 {
              width: 33.33333333333333% !important;
              max-width: 33.33333333333333%;
            }

            .mj-column-per-100 {
              width: 100% !important;
              max-width: 100%;
            }

            .mj-column-per-50 {
              width: 50% !important;
              max-width: 50%;
            }
          }
        </style>
      </head><body><div id="root"></div></body></html>`
    );
    doc.close();

    // // Render React inside the iframe
    const rootElement = doc.getElementById("root");
    if (!rootElement) return;
    createRoot(rootElement).render(children);

    return () => {
      rootElement.innerHTML = ""; // TODO: Clean up the iframe content??
    };
  }, [children]);

  return (
    <iframe
      ref={iframeRef}
      style={{ width: "100%", height: "100%", border: "none" }}
    />
  );
};

export default EmailEditorPreviewIFrame;
