import { Flex } from "@radix-ui/themes";
import { forwardRef } from "react";
import styled from "styled-components";
import MarkAvatar from "~/components/core/avatars/MarkAvatar";
import { useCurrentUserState } from "~/contexts/CurrentUserContext";

const DisplayAnimation = styled.div<{ $isDisplayed?: boolean }>`
  position: absolute;
  overflow: hidden;
  right: 0;
  top: 125%;
  width: ${(props) => (props.$isDisplayed ? "100%" : "0%")};
  height: ${(props) => (props.$isDisplayed ? "100px" : "0px")};
  opacity: ${(props) => (props.$isDisplayed ? "1" : "0")};
  border-radius: 32px 8px 32px 32px;
  box-shadow: 0px 1.57px 3.78px 0px #58422d06, 0px 6.93px 7.82px 0px #58422d0a,
    0px 17px 15.6px 0px #58422d0d, 0px 32.74px 30.58px 0px #58422d0f;
  transition: all 0.5s;
`;

const Container = styled(Flex)`
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  padding-left: 24px;
  background-color: var(--primary-white);
  font-size: 22px;
  font-weight: 600;
  & > .rt-Text {
    line-height: 0.5;
  }
`;
const TextContainer = styled.div`
  max-width: 65%;
  font-size: 22px;
  line-height: 0px;
`;
const StyledText = styled.span`
  font-family: var(--heading-font-family);
  line-height: 26.4px;
`;

type MarkPopupProps = {
  text?: string;
  isOpen?: boolean;
} & React.ComponentProps<typeof Flex>;

const AnnouncementPopup: React.FC<MarkPopupProps> = forwardRef<
  HTMLDivElement,
  MarkPopupProps
>(({ children, text, isOpen, ...props }, ref) => {
  const { user } = useCurrentUserState();
  return (
    <DisplayAnimation $isDisplayed={isOpen}>
      <Container
        direction="column"
        align="start"
        justify={"center"}
        {...props}
        ref={ref}
      >
        {user.status === "LOGGED_IN" && (
          <Flex align={"center"} justify={"start"} gap="4">
            <MarkAvatar size={"4"} />
            <TextContainer>
              <StyledText
                style={{
                  color: "var(--primary-vibrant-orange)",
                }}
              >
                Hello {user.firstName}.
              </StyledText>
              <StyledText> {text}</StyledText>
            </TextContainer>
          </Flex>
        )}
        {children}
      </Container>
    </DisplayAnimation>
  );
});

export default AnnouncementPopup;
