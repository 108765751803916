import AudienceChipSelector from "./AudienceChipSelector";
import { CDPAudienceType, CDPType, components } from "@openapi";
import { useMemo, useState } from "react";
import { MinusIcon, PlusIcon } from "~/assets/icons";
import CategorizedSelect from "~/components/campaign/wizard/inputs/CategorizedSelect";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import useCDPAudiencesQuery from "~/hooks/cdp/useCDPAudiencesQuery";

enum SelectionCategory {
  None,
  Target,
  Exclude,
}

export type AudienceSelectorItem =
  components["schemas"]["CDPAudienceRequestData"];

interface AudienceSelectorProps {
  audiences: AudienceSelectorItem[];
  onChange: (audiences: AudienceSelectorItem[]) => void;
  exclusions?: AudienceSelectorItem[];
  onChangeExclusions?: (exclusions: AudienceSelectorItem[]) => void;
}

const AudienceSelector: React.FC<AudienceSelectorProps> = ({
  audiences,
  onChange,
  exclusions,
  onChangeExclusions,
}) => {
  const showExclusions =
    exclusions !== undefined && onChangeExclusions !== undefined;
  const [selectionCategory, setSelectionCategory] = useState<SelectionCategory>(
    SelectionCategory.None
  );

  const { audiencesData: data, isLoading } = useCDPAudiencesQuery();

  // Callbacks
  const toggleSelectionCategory = (category: SelectionCategory) => {
    if (category === selectionCategory) {
      setSelectionCategory(SelectionCategory.None);
      return;
    }
    setSelectionCategory(category);
  };
  const handleDeselect = (id: string) => {
    onChange?.(audiences.filter((audience) => audience.audience_id !== id));
  };
  const handleSelect = (
    id: string,
    name: string,
    cdpType: string,
    audienceType: string
  ) => {
    const audience: AudienceSelectorItem = {
      cdp_type: cdpType as CDPType,
      audience_id: id,
      audience_name: name,
      audience_type: audienceType as CDPAudienceType,
    };
    if (selectionCategory === SelectionCategory.Target) {
      handleSelectTarget(audience);
    } else if (selectionCategory === SelectionCategory.Exclude) {
      handleSelectExclusion(audience);
    }
  };
  const handleSelectTarget = (audience: AudienceSelectorItem) => {
    if (audiences.find((aud) => aud.audience_id === audience.audience_id)) {
      return;
    }
    onChange([...audiences, audience]);
  };
  const handleDeselectExclusion = (id: string) => {
    if (!exclusions) {
      return;
    }
    onChangeExclusions?.(
      exclusions.filter((exclusion) => exclusion.audience_id !== id)
    );
  };
  const handleSelectExclusion = (audience: AudienceSelectorItem) => {
    if (exclusions?.find((aud) => aud.audience_id === audience.audience_id)) {
      return;
    }
    onChangeExclusions?.([...(exclusions ?? []), audience]);
  };

  const audienceData = useMemo(() => {
    if (selectionCategory === SelectionCategory.None || !data) {
      return data;
    }
    const filteredAudienceIds =
      selectionCategory === SelectionCategory.Exclude
        ? audiences.map((aud) => aud.audience_id)
        : exclusions?.map((aud) => aud.audience_id) ?? [];
    const filteredAudienceIdsSet = new Set(filteredAudienceIds);

    const audienceData = data.map((category) => {
      return {
        ...category,
        audiences: category.audiences.filter(
          (aud) => !filteredAudienceIdsSet.has(aud.id)
        ),
      };
    });
    return audienceData;
  }, [data, audiences, exclusions, selectionCategory]);

  const selector = (
    <CategorizedSelect
      data={audienceData}
      onSelect={handleSelect}
      loading={isLoading}
    />
  );

  return (
    <>
      <AudienceChipSelector
        label="Target audience:"
        audiences={audiences}
        onDeselect={handleDeselect}
      >
        <AppButton
          onClick={() => {
            toggleSelectionCategory(SelectionCategory.Target);
          }}
          variant="soft"
          size="3"
          style={{
            border: audiences ? "1px solid var(--text-link)" : undefined,
          }}
        >
          <PlusIcon />
          Add audience
        </AppButton>
      </AudienceChipSelector>

      {selectionCategory === SelectionCategory.Target && selector}

      {showExclusions && (
        <AudienceChipSelector
          label="Excluded audience:"
          audiences={exclusions ?? []}
          onDeselect={handleDeselectExclusion}
          variant="exclude"
        >
          <AppButton
            onClick={() => {
              toggleSelectionCategory(SelectionCategory.Exclude);
            }}
            variant="soft"
            size="3"
            style={{
              border: exclusions ? "1px solid var(--text-link)" : undefined,
            }}
          >
            <MinusIcon />
            Exclude audience
          </AppButton>
        </AudienceChipSelector>
      )}

      {selectionCategory === SelectionCategory.Exclude && selector}
    </>
  );
};

export default AudienceSelector;
