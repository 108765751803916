import classes from "./LoadingDots.module.css";
import { Box, Flex } from "@radix-ui/themes";
import React from "react";

const LoadingDot = ({
  disableAnimation,
}: { disableAnimation?: boolean } = {}) => (
  <Flex width="24px" height="24px" justify="center" align="center">
    <Box
      className={`${classes["wrapper-single"]} ${
        !disableAnimation && classes["wrapper-animation"]
      }`}
    >
      <Box className={classes["dot-flashing-single"]} />
    </Box>
  </Flex>
);

export default LoadingDot;
