import ModifierContainer from "./ModifierContainer";
import { ImageAssetCategory } from "@openapi";
import { toast } from "sonner";
import { AssetSelectorItem } from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import ImageSelector from "~/components/core/inputs/ImageSelector";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useGetEmailRelatedImages from "~/hooks/emails/useGetEmailRelatedImages";
import useMediaBrandImagesUpload from "~/hooks/style-library/useMediaBrandImagesUpload";

const ImageModifier = ({
  initialImageUrl,
  onAssetChange,
  productId,
  collectionId,
  generateButtonSuffix,
}: {
  enabled: boolean;
  initialImageUrl?: string;
  collectionId?: string | null;
  productId?: string | null;
  generateButtonSuffix?: string;
  onSwitchToggle: (enabled: boolean) => void;
  onAssetChange: (assets: AssetSelectorItem) => void;
}) => {
  const activeBrandID = useActiveBrandID();

  const { images: relatedAssets } = useGetEmailRelatedImages({
    productId,
    collectionId,
  });

  const { mutate: uploadImageAssets, isPending: isUploading } =
    useMediaBrandImagesUpload({
      data: {
        brandID: activeBrandID,
        category: ImageAssetCategory.other,
      },
      onSuccess(data) {
        onAssetChange?.({
          asset: data.new_assets[0],
          url: data.new_assets[0].url,
        });
      },
      onError() {
        toast.error("Failed to upload image");
      },
    });
  const handleUpload = (file: File) => {
    uploadImageAssets({
      images: [file as unknown as string],
    });
  };

  return (
    <ModifierContainer
      title={initialImageUrl ? "Image" : "Featured Image (optional)"}
      hideSwitch
    >
      <ImageSelector
        value={initialImageUrl}
        onSelect={(assets) => onAssetChange(assets[0])}
        onCrop={handleUpload}
        isUploading={isUploading}
        assetsMetadata={{
          relatedAssets: relatedAssets,
          productCommerceId: productId ?? undefined,
          collectionCommerceId: collectionId ?? undefined,
          keepCommerceId: true,
        }}
        generateMetadata={{
          buttonSuffix: generateButtonSuffix,
        }}
        onGenerateComplete={(asset) => {
          onAssetChange?.({
            asset: asset,
            url: asset.url,
          });
        }}
      />
    </ModifierContainer>
  );
};

export default ImageModifier;
