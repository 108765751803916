import { operations } from "@openapi";
import {
  Badge,
  Button,
  Code,
  DataList,
  DropdownMenu,
  Flex,
  Heading,
  Link,
} from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { Ellipsis } from "lucide-react";
import { useEffect } from "react";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

type GetBrandResponse =
  operations["intern_api_get_brand"]["responses"][200]["content"]["application/json"];

type SyncBrandCDPResponse =
  operations["intern_api_sync_brand_cdp"]["responses"][200]["content"]["application/json"];

interface InternBrandJobsCardProps {
  parentState: number;
  refresh: () => void;
}

const InternBrandInfoCard: React.FC<InternBrandJobsCardProps> = ({
  parentState,
  refresh,
}) => {
  const activeBrandID = useActiveBrandID();

  const {
    data: brand,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["intern-brand", activeBrandID],
    queryFn: async (): Promise<GetBrandResponse> => {
      const { data } = await axios.get(
        `/api/v1/intern/brand/${activeBrandID}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    retry: true,
    staleTime: 3600,
  });

  const mutation = useMutation({
    mutationFn: async (): Promise<SyncBrandCDPResponse> => {
      const { data } = await axios.post(
        `/api/v1/intern/brand/${activeBrandID}/sync-cdp`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: () => {
      refetch();
    },
    onError: () => {
      alert("CDP Sync Failed."); // Optional: to show error message
    },
  });

  useEffect(() => {
    refetch();
  }, [parentState, refetch]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {"Loading..."}
      </div>
    );
  }

  if (brand === null || brand === undefined) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {"Error..."}
      </div>
    );
  }

  return (
    <Flex direction="column" gap="24px" height="100%">
      <Flex justify="between">
        <Heading>{brand.name}</Heading>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button variant="ghost">
              <Ellipsis></Ellipsis>
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <DropdownMenu.Item onSelect={() => mutation.mutate()}>
              Sync CDP
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Flex>
      <Flex direction="column" flexGrow="1">
        <DataList.Root orientation={{ initial: "vertical", sm: "horizontal" }}>
          <DataList.Item>
            <DataList.Label minWidth="88px">ID</DataList.Label>
            <DataList.Value>
              <Code variant="ghost" size="1">
                {brand.id}
              </Code>
            </DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px">Timezone</DataList.Label>
            <DataList.Value>
              {brand.iana_timezone
                ? `${brand.iana_timezone} (${brand.timezone_offset_hours} hours)`
                : "---"}
            </DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px">Is Active</DataList.Label>
            <DataList.Value>
              <Badge
                color={brand.is_active ? "jade" : "crimson"}
                variant="soft"
                radius="full"
              >
                {brand.is_active ? "Yes" : "No"}
              </Badge>
            </DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px">
              Is Klaviyo Configured
            </DataList.Label>
            <DataList.Value>
              <Badge
                color={brand.is_klaviyo_configured ? "jade" : "amber"}
                variant="soft"
                radius="full"
              >
                {brand.is_klaviyo_configured ? "Yes" : "No"}
              </Badge>
            </DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px">Is Meta Configured</DataList.Label>
            <DataList.Value>
              <Badge
                color={brand.is_meta_configured ? "jade" : "amber"}
                variant="soft"
                radius="full"
              >
                {brand.is_meta_configured ? "Yes" : "No"}
              </Badge>
            </DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px">Is Onboarded</DataList.Label>
            <DataList.Value>
              <Badge
                color={brand.is_onboarded ? "jade" : "amber"}
                variant="soft"
                radius="full"
              >
                {brand.is_onboarded ? "Yes" : "No"}
              </Badge>
            </DataList.Value>
          </DataList.Item>
        </DataList.Root>
      </Flex>
    </Flex>
  );
};

export default InternBrandInfoCard;
