import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

type ShopifyCollectionImagesType =
  operations["shopify_integration_api_get_collection_images"]["responses"]["200"]["content"]["application/json"];
type ShopifyCollectionImagesRequestData =
  operations["shopify_integration_api_get_collection_images"]["parameters"]["query"];

const key = "commerce-platform/collection/images";
const useShopifyCollectionImagesQuery = ({
  collectionId,
  limit,
}: {
  collectionId?: string | null;
  limit?: number;
}) => {
  const activeBrandID = useActiveBrandID();
  const collectionQuery = useQuery<ShopifyCollectionImagesType>({
    queryKey: [key, activeBrandID, collectionId],
    queryFn: async () => {
      const params: ShopifyCollectionImagesRequestData = {
        brand_id: activeBrandID,
        collection_id: collectionId ?? "",
        limit,
      };
      const { data } = await axios.get(`/api/v1/${key}`, {
        params: params,
      });
      return data;
    },
    enabled: !!collectionId && !!activeBrandID,
  });

  return collectionQuery;
};

export default useShopifyCollectionImagesQuery;
