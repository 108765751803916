import CloseIconButton from "../core/buttons/CloseIconButton";
import PalettePicker from "../core/inputs/PalettePicker";
import ColorBox from "../style-library/color-palette/ColorBox";
import { components } from "@openapi";
import { Flex, Dialog, Spinner, Box } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useBrandColorPaletteCreateMutation from "~/hooks/brand/useBrandColorPaletteCreateMutation";
import useBrandColorPaletteDeleteMutation from "~/hooks/brand/useBrandColorPaletteDeleteMutation";
import useBrandColorPaletteUpdateMutation from "~/hooks/brand/useBrandColorPaletteUpdateMutation";

const EMPTY_PALETTE: components["schemas"]["ColorPaletteSchema"] = {
  id: null,
  background: "#FFFFFF",
  foreground: "#000000",
  accent_background: "#000000",
  accent_foreground: "#FFFFFF",
};

const ColorPaletteDialog = ({
  activePalette,
  isOpen,
  onOpenChange,
  hideDeleteButton = false,
}: {
  activePalette?: components["schemas"]["ColorPaletteSchema"];
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  hideDeleteButton?: boolean;
}) => {
  const activeBrandID = useActiveBrandID();

  const [palette, setPalette] = useState<
    components["schemas"]["ColorPaletteSchema"]
  >(activePalette ?? EMPTY_PALETTE);

  useEffect(() => {
    if (!isOpen) {
      setPalette(EMPTY_PALETTE);
    } else if (activePalette) {
      setPalette(activePalette);
    }
  }, [isOpen, activePalette]);

  const { createBrandColorPalette, isLoading: isCreating } =
    useBrandColorPaletteCreateMutation({
      brandID: activeBrandID,
      onSuccess: () => {
        onOpenChange(false);
        toast.success("Color palette created");
      },
    });

  const { updateBrandColorPalette } = useBrandColorPaletteUpdateMutation({
    brandID: activeBrandID,
    onSuccess: () => {
      onOpenChange(false);
      toast.success("Color palette updated");
    },
  });

  const { deleteBrandColorPalette, isLoading: isDeleting } =
    useBrandColorPaletteDeleteMutation({
      brandID: activeBrandID,
      paletteID: palette.id!,
      onSuccess: () => {
        onOpenChange(false);
      },
    });

  const header = (
    <Flex
      justify="between"
      align="center"
      p="16px"
      style={{ borderBottom: "1px solid #E2E2E2" }}
    >
      <Dialog.Title size="4" weight="medium" mb="0">
        {palette?.id ? "Edit Color Palette" : "Add Color Palette"}
      </Dialog.Title>
      <Dialog.Close disabled={isCreating}>
        <CloseIconButton />
      </Dialog.Close>
    </Flex>
  );
  const content = (
    <Flex direction="column" pb="24px" px="0">
      <Flex
        mb="24px"
        style={{
          borderBottom: "1px solid #E2E2E2",
          backgroundColor: "#F0EDEB",
        }}
        p="4"
        align="center"
        justify="center"
      >
        <PalettePicker palette={palette} />
      </Flex>

      <Flex direction="row" gap="4" p="16px" wrap="wrap">
        <ColorBox
          key={0}
          color={palette.background}
          label="Background"
          disabled={false}
          onChange={(color) => {
            setPalette({
              ...palette,
              background: color,
            });
          }}
          colorPickerSide={"right"}
        />
        <ColorBox
          key={1}
          color={palette.foreground}
          label="Foreground"
          disabled={false}
          onChange={(color) => {
            setPalette({
              ...palette,
              foreground: color,
            });
          }}
          colorPickerSide="right"
        />
        <ColorBox
          key={2}
          color={palette.accent_background}
          label="Accent Background"
          disabled={false}
          onChange={(color) => {
            setPalette({
              ...palette,
              accent_background: color,
            });
          }}
          colorPickerSide="right"
        />
        <ColorBox
          key={3}
          color={palette.accent_foreground}
          label="Accent Foreground"
          disabled={false}
          onChange={(color) => {
            setPalette({
              ...palette,
              accent_foreground: color,
            });
          }}
          colorPickerSide="right"
        />
      </Flex>
    </Flex>
  );

  const footer = (
    <Flex
      ml="auto"
      gap="3"
      p="24px"
      style={{ borderTop: "1px solid #DDD7D7" }}
      justify={"between"}
    >
      {!hideDeleteButton && palette?.id ? (
        <AppButton
          radius="large"
          variant="outlined"
          disabled={isDeleting}
          color="red"
          onClick={() => deleteBrandColorPalette()}
        >
          {isDeleting && <Spinner />}
          Delete
        </AppButton>
      ) : (
        <Box />
      )}

      <Flex gap="3">
        <Dialog.Close>
          <AppButton radius="large" variant="outlined" disabled={isCreating}>
            Cancel
          </AppButton>
        </Dialog.Close>
        <AppButton
          variant="dark"
          radius="large"
          disabled={isCreating}
          onClick={() =>
            palette?.id
              ? updateBrandColorPalette({
                  ...palette,
                  id: palette.id,
                })
              : createBrandColorPalette({
                  ...palette,
                })
          }
        >
          {isCreating && <Spinner />}
          {isCreating ? "Saving" : "Save Changes"}
        </AppButton>
      </Flex>
    </Flex>
  );
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content
        style={{
          width: "50%",
          padding: "0",
        }}
      >
        {header}

        {content}
        {footer}
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default ColorPaletteDialog;
