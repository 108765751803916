import ModifierContainer from "./ModifierContainer";
import { Spinner } from "@radix-ui/themes";
import { useState } from "react";
import { PencilIcon } from "~/assets/icons";
import ProductSelectorDialog from "~/components/campaign/wizard/products/components/productSelector/ProductSelectorDialog";
import { Product } from "~/components/campaign/wizard/products/components/productSelector/hooks/useGetFilteredProducts";
import SidePanelComponents from "~/components/core/editor/sidepane";

const FeaturedProductModifier = ({
  onChangeProduct,
  isLoading,
}: {
  onChangeProduct: (product: Product) => void;
  isLoading?: boolean;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return (
    <ModifierContainer title="Featured Product" hideSwitch>
      <ProductSelectorDialog
        isOpen={isDialogOpen}
        onOpenChange={setIsDialogOpen}
        onSelectSingleProduct={(product) => {
          onChangeProduct(product);
          setIsDialogOpen(false);
        }}
        triggerComponent={
          <SidePanelComponents.Button
            variant="outlined"
            onClick={() => setIsDialogOpen(true)}
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : <PencilIcon />}
            {isLoading ? "Changing" : "Change"} Product
          </SidePanelComponents.Button>
        }
      />
    </ModifierContainer>
  );
};

export default FeaturedProductModifier;
