import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import {
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "../../../contexts/CampaignWizardContext";
import useCenterCampaignCard from "../../../hooks/campaign/useCenterCampaignCard";
import { CampaignCardCommonProps } from "./CampaignCards";
import { operations } from "@openapi";
import { useEffect, useState } from "react";
import AudienceSelector, {
  AudienceSelectorItem,
} from "~/components/assets/selector/AudienceSelector";

export type Audience =
  operations["cdp_api_cdps"]["responses"][200]["content"]["application/json"][0]["audiences"][0];

const CampaignAudience: React.FC<CampaignCardCommonProps> = (props) => {
  const dispatch = useCampaignWizardDispatch();
  const { centerCurrentCard } = useCenterCampaignCard({ cardStep: props.step });

  const { wizardType } = useCampaignWizardState();

  const [selectedAudiences, setSelectedAudiences] = useState<
    AudienceSelectorItem[]
  >([]);
  const [audienceExclusions, setAudienceExclusions] = useState<
    AudienceSelectorItem[]
  >([]);

  const handleSelectionChange = (audiences: AudienceSelectorItem[]) => {
    setSelectedAudiences(audiences);
    centerCurrentCard();
  };

  useEffect(() => {
    dispatch({
      type: "UPDATE_CAMPAIGN_DATA",
      payload: {
        audiences: selectedAudiences,
      },
    });
  }, [selectedAudiences, wizardType, dispatch]);

  useEffect(() => {
    dispatch({
      type: "UPDATE_CAMPAIGN_DATA",
      payload: {
        excluded_audiences: audienceExclusions,
      },
    });
  }, [audienceExclusions, wizardType, dispatch]);

  return (
    <CampaignWizardCard
      badgeTitle="Audience"
      subtitle="Select the audience for your email. You can edit this later."
      nextButtonProps={{
        text: "Looks good",
        disabled: !selectedAudiences.length,
      }}
      {...props}
    >
      <AudienceSelector
        audiences={selectedAudiences}
        onChange={handleSelectionChange}
        exclusions={audienceExclusions}
        onChangeExclusions={(exclusions) => setAudienceExclusions(exclusions)}
      />
    </CampaignWizardCard>
  );
};

export default CampaignAudience;
