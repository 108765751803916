import { Flex, IconButton } from "@radix-ui/themes";
import styled from "styled-components";
import { XIcon } from "~/assets/icons";
import DraperText from "~/components/core/DraperText";

const Chip = styled(Flex)<{
  $selected?: boolean;
}>`
  height: var(--space-7);
  border-radius: 50px;
  box-shadow: inset 0 0 0 1px var(--gray-a8);
  cursor: var(--cursor-button);
  padding: 0 16px;
  gap: var(--space-3);

  &:hover {
    background-color: var(--gray-a2);
  }

  &:active {
    background-color: var(--gray-a3);
  }
`;

export default function AudienceChip({
  id,
  onClick,
  text,
  variant = "target",
}: {
  id: string;
  onClick?: (id: string) => void;
  text: string;
  variant?: "target" | "exclude";
}) {
  return (
    <Chip key={id} align="center">
      <DraperText
        variant={variant === "target" ? "secondary" : "error"}
        size="3"
        weight="medium"
      >
        {text}
      </DraperText>
      {onClick && (
        <IconButton
          radius="full"
          onClick={() => onClick(id)}
          color="gray"
          variant="ghost"
        >
          <XIcon color="#000000A6" size={16} />
        </IconButton>
      )}
    </Chip>
  );
}
