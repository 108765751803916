import { getFontEmbedCSS, toPng } from "html-to-image";
import { Options as HTMLImageOptions } from "html-to-image/lib/types";

const download = require("downloadjs");

export const sleep = (ms = 1000) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getImageMetaFromUrl = async (url: string) => {
  try {
    const img = new Image();
    img.src = url;
    await img.decode();
    return img;
  } catch (error) {
    console.error("Error retrieving image meta:", error);
    return null;
  }
};

export const getFilenameFromUrl = (url?: string) => {
  if (!url) return null;
  return url.split("/").pop();
};

export async function getFileSizeFromUrl(url: string): Promise<string | null> {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(
        `Failed to fetch: ${response.status} ${response.statusText}`
      );
    }

    const contentLength = response.headers.get("Content-Length");

    if (contentLength) {
      const fileSizeInBytes = parseInt(contentLength, 10);
      const fileSizeInKB = fileSizeInBytes / 1024;
      return fileSizeInKB.toFixed(2);
    } else {
      console.warn("Content-Length header is not available.");
      return null;
    }
  } catch (error) {
    console.error("Error retrieving file size:", error);
    return null;
  }
}

export const isSomeEnum = (enumType: any, value: any) => {
  return Object.values(enumType).includes(value);
};

export const timestampToDateString = (timestamp: string) => {
  return new Date(timestamp).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const currencyFormatter = (currency?: string | null) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency ?? "USD",
  });

export function formatNumberLocale(
  value: number,
  locale: string = navigator.language
): string {
  return new Intl.NumberFormat(locale).format(value);
}

export const getImageFromElement = async (
  element: HTMLElement | null,
  { width, height }: { width?: number; height?: number }
): Promise<string | undefined> => {
  if (!element) {
    console.log("Media element not found");
    return;
  }
  const options: HTMLImageOptions = {};
  if (width) {
    options.canvasWidth = width;
  }
  if (height) {
    options.canvasHeight = height;
  }

  try {
    const fontEmbedCSS = await getFontEmbedCSS(element);
    options.fontEmbedCSS = fontEmbedCSS;
  } catch (error) {
    console.error("Error embedding fonts:", error);
    options.fontEmbedCSS = "";
  }
  try {
    // there's an issue with conversion when the svg contains images with no href or xlink:href
    // this is a workaround to remove those attributes, which shouldn't have a visual effect
    const parent = element.parentNode;
    const clone = element.cloneNode(true) as HTMLElement;
    parent?.replaceChild(clone, element);
    const images = clone.querySelectorAll("image");
    images.forEach((image) => {
      if (image.hasAttribute("xlink:href")) {
        if (!image.hasAttribute("href")) {
          image.setAttribute("href", image.getAttribute("xlink:href")!);
        }
        image.removeAttribute("xlink:href");
      } else if (!image.hasAttribute("href")) {
        image.remove();
      }
    });

    const dataUrl = await toPng(clone, options);
    parent?.replaceChild(element, clone);

    return dataUrl;
  } catch (error) {
    console.error(
      `Error converting element with id ${element.id} to PNG`,
      error
    );
    return;
  }
};

export const downloadElementImage = async (
  element: HTMLElement | null,
  filename?: string,
  width?: number,
  height?: number
): Promise<boolean> => {
  if (!element) {
    console.log("Media element not found");
    return false;
  }
  try {
    const dataUrl = await getImageFromElement(element, { width, height });
    if (!dataUrl) {
      console.log("Failed to generate image from element");
      return false;
    }

    download(dataUrl, `asset_${filename ?? element.id}`);
    return true;
  } catch (error) {
    console.error(
      `Error converting element with id ${element.id} to PNG`,
      error
    );
    return false;
  }
};
