import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type Params = operations["user_api_get_invite"]["parameters"]["query"];
type Response =
  operations["user_api_get_invite"]["responses"]["200"]["content"]["application/json"];
const getQueryKey = (params?: Params) => ["user_api_get_invite", params];

const useGetInviteDetailsQuery = (params?: Params) => {
  return useQuery<Response>({
    queryKey: getQueryKey(params),
    queryFn: async () => {
      const { data } = await axios.get("/api/v1/user/invite", { params });
      return data;
    },
    enabled: !!params,
  });
};

export default useGetInviteDetailsQuery;
