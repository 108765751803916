import {
  AdMediaUpdateableElement,
  AdMediaUpdateableImageElement,
} from "../../AdMediaContext";
import AdImageSelector from "../AdImageSelector";
import { AdMediaImageElementType } from "@openapi";
import { Text } from "@radix-ui/themes";
import { useMemo } from "react";
import SidePanelComponents from "~/components/core/editor/sidepane";
import { useAdEditorSelectedAdState } from "~/contexts/AdEditorContext";

interface AdMediaProductCollectionElementComponentProps {
  element: Extract<AdMediaUpdateableElement, { type: "collection_group" }>;
  onElementUpdate: (
    element: Extract<AdMediaUpdateableElement, { type: "collection_group" }>
  ) => void;
}

const AdProductCollectionCard = ({
  element,
  onElementUpdate,
}: AdMediaProductCollectionElementComponentProps) => {
  const { data } = useAdEditorSelectedAdState();
  const handleImageUpdate = (file: File) => {
    onElementUpdate({
      ...element,
      elements: element.elements.map((el) => {
        switch (el.type) {
          case "image":
            return {
              ...el,
              uploadedFile: file,
            };
          default:
            return el;
        }
      }),
    });
  };

  const imageElement = useMemo(() => {
    return element.elements.find(
      (el): el is AdMediaUpdateableImageElement =>
        el.type === "image" &&
        el.image_type === AdMediaImageElementType.collection
    );
  }, [element]);

  return (
    <SidePanelComponents.EditorCard
      checkable={false}
      title="Product Collection"
    >
      {!!data.campaign.destination_collection?.title && (
        <Text style={{ color: "--var(text-secondary)" }}>
          {data.campaign.destination_collection?.title}
        </Text>
      )}
      <AdImageSelector
        value={imageElement?.uploadedFile ?? imageElement?.file ?? ""}
        onUpload={(file) => handleImageUpdate(file)}
        onCrop={(file) => handleImageUpdate(file)}
      />
    </SidePanelComponents.EditorCard>
  );
};

export default AdProductCollectionCard;
