import * as Form from "@radix-ui/react-form";
import { Text } from "@radix-ui/themes";
import styled from "styled-components";
import FormErrorText from "~/components/auth/FormErrorText";

const Label = styled(Text)`
  color: var(--text-secondary);
`;

const FormInputField = ({
  label,
  valueMissingText,
  name,
  input,
}: {
  label: string;
  valueMissingText: string;
  name: string;
  input: React.ReactNode;
}) => {
  return (
    <Form.Field name={name}>
      <Label size="2">{label}</Label>
      <Form.Control asChild>{input}</Form.Control>
      <Form.Message match="valueMissing">
        <FormErrorText>{valueMissingText}</FormErrorText>
      </Form.Message>
    </Form.Field>
  );
};

export default FormInputField;
