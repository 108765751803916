import useGetCampaignRecommendations from "../../hooks/recommendations/useGetCampaignRecommendations";
import NewCampaignType from "./NewCampaignType";
import RecommenderCard from "./RecommenderCard";
import RecommenderZeroState from "./RecommenderZeroState";
import { Flex, Skeleton, Spinner } from "@radix-ui/themes";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { DraperHeading } from "~/components/core/DraperText";
import { useMarkPopupDispatch } from "~/contexts/MarkPopupContext";

interface SectionContainerProps {
  children: React.ReactNode;
  label: string;
}

const SectionContainer: React.FC<SectionContainerProps> = ({
  children,
  label,
}) => (
  <Flex direction="column" gap="5">
    <DraperHeading size="5">{label}</DraperHeading>
    {children}
  </Flex>
);

const RecommenderSection = () => {
  const { data, hasNextPage, fetchNextPage, isLoading } =
    useGetCampaignRecommendations();

  const popupDispatch = useMarkPopupDispatch();

  const [thisWeek, older] = useMemo(() => {
    if (!data) {
      return [[], []];
    }
    return _(data.pages)
      .flatMap((page) => page.campaign_recommendations)
      .partition((recommendation) => {
        return dayjs().isSame(recommendation.created_at, "week");
      })
      .value();
  }, [data]);

  const sections = useMemo(() => {
    const results: { label: string; recommendations: typeof thisWeek }[] = [
      {
        label: "New recommendations this week",
        recommendations: thisWeek,
      },
      {
        label: "Suggested campaigns",
        recommendations: older,
      },
    ].filter((section) => section.recommendations.length > 0);
    return results;
  }, [thisWeek, older]);

  const hasRecommendations = thisWeek.length > 0 || older.length > 0;

  useEffect(() => {
    const message = hasRecommendations
      ? "We've reviewed your past 12 months of emails and ads and prepared a report to check."
      : "We're currently analyzing your brand voice to tailor the best campaign ideas for you.";
    popupDispatch({
      type: "SET_MESSAGE",
      payload: message,
    });
  }, [hasRecommendations]);

  if (!isLoading && !hasRecommendations) {
    return <RecommenderZeroState />;
  }

  return (
    <Flex direction="column" gap="7" width="100%">
      <SectionContainer label="Create a new campaign">
        <NewCampaignType />
      </SectionContainer>
      <InfiniteScroll
        next={fetchNextPage}
        hasMore={hasNextPage ?? false}
        dataLength={thisWeek.length + older.length}
        loader={
          <Flex height="100%" width="100%" justify="center" align="center">
            <Spinner m="5" />
          </Flex>
        }
        scrollThreshold={"90%"}
      >
        <Flex direction="column" gap="7" width="100%">
          {!sections.length && (
            <Flex direction="column" gap="5">
              <Skeleton width="30%" height="30px" />
              <Skeleton width="100%" height="180px" />
              <Skeleton width="100%" height="180px" />
              <Skeleton width="100%" height="180px" />
            </Flex>
          )}
          {sections.map((section) => (
            <SectionContainer key={section.label} label={section.label}>
              <Flex direction="column" gap="5">
                {section.recommendations.map((recommendation) => (
                  <RecommenderCard
                    key={recommendation.id}
                    recommendation={recommendation}
                  />
                ))}
              </Flex>
            </SectionContainer>
          ))}
        </Flex>
      </InfiniteScroll>
    </Flex>
  );
};

export default RecommenderSection;
