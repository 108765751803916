import { CampaignWizardCardProps } from "../../../../components/campaign/wizard/cards/CampaignWizardCard";
import { useCampaignWizardDispatch } from "../../../../contexts/CampaignWizardContext";
import useCenterCampaignCard from "../../../../hooks/campaign/useCenterCampaignCard";
import { CampaignCardCommonProps } from "../CampaignCards";
import { useMemo, useState, useEffect } from "react";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";

const useCampaignCustomAssetsProps = ({ step }: CampaignCardCommonProps) => {
  const dispatch = useCampaignWizardDispatch();
  const { centerCurrentCard } = useCenterCampaignCard({ cardStep: step });

  const [selectedAssets, setSelectedAssets] = useState<ImageAssetSchema[]>([]);
  const [hasSelectedNo, setHasSelectedNo] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const cardProps = useMemo<Omit<CampaignWizardCardProps, "children" | "step">>(
    () => ({
      badgeTitle: "Custom Assets",
      nextButtonProps: {
        text: "Continue",
        disabled: selectedAssets.length === 0 && !hasSelectedNo,
      },
    }),
    [selectedAssets, hasSelectedNo]
  );

  useEffect(() => {
    if (hasSelectedNo && selectedAssets.length > 0) {
      setHasSelectedNo(false);
    }
    centerCurrentCard();
    dispatch({
      type: "UPDATE_CAMPAIGN_DATA",
      payload: { asset_ids: selectedAssets.map((asset) => asset.id) },
    });
  }, [selectedAssets, dispatch, hasSelectedNo, centerCurrentCard]);

  const props = useMemo(
    () => ({
      hasSelectedNo,
      setHasSelectedNo,
      isDialogOpen: openDialog,
      onToggleDialogOpen: (open: boolean) => setOpenDialog(open),
      selectedAssets: selectedAssets,
      setSelectedAssets: (assets: ImageAssetSchema[]) =>
        setSelectedAssets(assets),
    }),
    [
      hasSelectedNo,
      setHasSelectedNo,
      openDialog,
      setOpenDialog,
      selectedAssets,
      setSelectedAssets,
    ]
  );

  return { cardProps, props };
};

export default useCampaignCustomAssetsProps;
