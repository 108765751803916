import {
  NewCampaignWizardSteps,
  NewCampaignWizardStepsLabels,
  RecommendedCampaignWizardSteps,
  RecommendedCampaignWizardStepsLabels,
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "../../../../contexts/CampaignWizardContext";
import useCenterCampaignCard from "../../../../hooks/campaign/useCenterCampaignCard";
import CloseIconButton from "../../../core/buttons/CloseIconButton";
import classes from "./CampaignWizardLayout.module.css";
import { Box, Flex, ScrollArea, Tooltip } from "@radix-ui/themes";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import KinLogo from "~/components/core/KinLogo";
import BackgroundGradient from "~/components/core/layout/BackgroundGradient";
import { HOME_ROUTE } from "~/routes/constants";

const LogoWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
`;

const CampaignWizardLayout = ({ children }: { children: React.ReactNode }) => {
  const { currentStep, currentRequiredStep, wizardType } =
    useCampaignWizardState();
  const { centerCurrentCard } = useCenterCampaignCard({
    cardStep: currentStep,
  });
  const dispatch = useCampaignWizardDispatch();
  const navigate = useNavigate();

  const isNewCampaign = wizardType === "NEW";

  useEffect(() => {
    centerCurrentCard();
  }, [centerCurrentCard]);

  return (
    <Flex
      justify="between"
      px="24px"
      height="100vh"
      width="100%"
      position={"relative"}
      style={{ overflow: "hidden" }}
    >
      <BackgroundGradient />
      <LogoWrapper>
        <KinLogo height="28px" />
      </LogoWrapper>
      <Flex
        direction="column"
        height="100%"
        gap="8px"
        justify="center"
        align="center"
      >
        {!!currentRequiredStep &&
          Array.from({ length: currentRequiredStep + 1 }).map((_, index) => (
            <Tooltip
              side="right"
              key={index}
              content={
                isNewCampaign
                  ? NewCampaignWizardStepsLabels[
                      index as NewCampaignWizardSteps
                    ]
                  : RecommendedCampaignWizardStepsLabels[
                      index as RecommendedCampaignWizardSteps
                    ]
              }
            >
              <Box
                onClick={() =>
                  dispatch({
                    type: "SET_CURRENT_STEP",
                    payload: { currentStep: index },
                  })
                }
                className={classes.dot}
              >
                <Box
                  style={{
                    width: index === currentStep ? "12px" : "8px",
                    height: index === currentStep ? "12px" : "8px",
                    borderRadius: "50%",
                    backgroundColor:
                      index === currentStep ? "#4F4343" : "#D2C3C3",
                    cursor: "pointer",
                  }}
                />
              </Box>
            </Tooltip>
          ))}
      </Flex>
      <ScrollArea
        style={{ overflow: "hidden" }}
        type="auto"
        scrollbars="horizontal"
      >
        <Flex
          direction="column"
          align="center"
          py="50%"
          gap="100px"
          height="100%"
          style={{ overflow: "hidden" }}
        >
          {children}
        </Flex>
      </ScrollArea>
      <CloseIconButton
        onClick={() => {
          if (window.history.length > 1) {
            navigate(-1);
          } else {
            navigate(HOME_ROUTE);
          }
        }}
        style={{
          right: "24px",
          top: "24px",
          position: "absolute",
          borderRadius: "8px",
        }}
      />
    </Flex>
  );
};

export default CampaignWizardLayout;
