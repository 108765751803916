import {
  CampaignDetailResponse,
  getCampaignDetailsQueryKey,
} from "../campaign/useGetCampaignDetails";
import { AdMediaElementType, components, operations } from "@openapi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import _ from "lodash";
import { useState } from "react";
import {
  AdMediaUpdateableElement,
  useAdMediaContext,
} from "~/components/ads/AdMediaContext";
import {
  useAdEditorDispatch,
  useAdEditorState,
} from "~/contexts/AdEditorContext";
import { getNumericAdMediaAspectRatio } from "~/utils/ads/helpers";
import { dataURIToFile } from "~/utils/fileUtils";
import { getImageFromElement } from "~/utils/helpers";
import { assertNever } from "~/utils/typeUtils";

const AD_PREVIEW_HEIGHT = 500;

type UpdateAdCreativeMutationRequestData = Omit<
  operations["ads_api_update_ad_creative"]["requestBody"]["content"]["multipart/form-data"],
  "files" | "variant_previews"
> & {
  files?: File[];
  variant_previews?: File[];
  creativeId: string;
  /** Used for updating states and queries data */
  campaignId?: string;
};

export const useUpdateAdCreativeBaseMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (vars: UpdateAdCreativeMutationRequestData) => void;
  onError?: (message: string, error?: AxiosError) => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation<null, AxiosError, UpdateAdCreativeMutationRequestData>({
    mutationFn: async ({
      files,
      variant_previews,
      request_data,
      creativeId,
    }) => {
      const formData = new FormData();
      files?.forEach((file) => {
        formData.append("files", file);
      });
      variant_previews?.forEach((file) => {
        formData.append("variant_previews", file);
      });
      formData.append("request_data", JSON.stringify(request_data));
      const { data } = await axios.post(
        `/api/v1/ads/ad-creative/${creativeId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (_, vars) => {
      onSuccess?.(vars);

      if (!vars.campaignId) {
        return;
      }
      queryClient.setQueryData<CampaignDetailResponse>(
        getCampaignDetailsQueryKey(vars.campaignId),
        (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            ad_creatives: oldData.ad_creatives.map((ad) => {
              if (ad.id === vars.creativeId) {
                return {
                  ...ad,
                  title: vars.request_data.headline ?? ad.title,
                  // TODO: Update other fields as needed
                };
              }
              return ad;
            }),
          };
        }
      );
    },
    onError: (error) => {
      const errorMessage =
        error.status !== 500 &&
        error.response?.data &&
        typeof error.response?.data === "string"
          ? error.response.data
          : "Something went wrong";
      onError?.(errorMessage, error);
    },
  });
};

const useUpdateAdCreativeMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: ({ creativeId }: { creativeId: string }) => void;
  onError?: (message: string, error?: AxiosError) => void;
}) => {
  const state = useAdEditorState();
  const [isGenerating, setIsGenerating] = useState(false);
  const { elements } = useAdMediaContext();
  const adDispatch = useAdEditorDispatch();

  const generateAdCreativeMedia = useUpdateAdCreativeBaseMutation({
    onSuccess(params) {
      adDispatch({
        type: "SET_IS_DIRTY",
        payload: false,
      });
      onSuccess?.(params);
    },
    onError,
  });

  return {
    ...generateAdCreativeMedia,
    mutate: async () => {
      if (state.status !== "loaded") {
        onError?.("Bad state: ad editor not loaded");
        return;
      }
      const selectedAd = state.availableAds[state.selectedAdIndex];
      if (selectedAd.media.status !== "loaded") {
        onError?.("Bad state: media not loaded");
        return;
      }
      if (
        selectedAd.media.selectedMediaIndex < 0 ||
        selectedAd.media.selectedMediaIndex >= selectedAd.media.list.length
      ) {
        onError?.("No media selected");
        return;
      }
      const selectedMedia =
        selectedAd.media.list[selectedAd.media.selectedMediaIndex];
      const creativeId = selectedAd.data.id;
      let files: File[] = [];
      let currentFileIndex = 0;

      const getElementUpdateInfo = (
        element: AdMediaUpdateableElement
      ): NonNullable<
        UpdateAdCreativeMutationRequestData["request_data"]["selected_media"]
      >["elements"][number] => {
        switch (element.type) {
          case AdMediaElementType.image:
            let fileIndex: number | undefined = undefined;
            if (element.is_enabled && element.uploadedFile) {
              files.push(element.uploadedFile);
              fileIndex = currentFileIndex++;
            }
            return {
              type: element.type,
              id: element.id,
              is_enabled: element.is_enabled,
              file: currentFileIndex ? undefined : element.file,
              upload_idx: fileIndex,
            };
          case AdMediaElementType.text:
            return {
              type: element.type,
              id: element.id,
              is_enabled: element.is_enabled,
              text: element.text,
              font_family: element.font_family,
              font_size: element.font_size,
              font_weight: element.font_weight,
              color: element.color,
            };
          case AdMediaElementType.shape:
            return {
              type: element.type,
              id: element.id,
              is_enabled: element.is_enabled,
              fill_color: element.fill_color,
              stroke_color: element.stroke_color,
            };
          case AdMediaElementType.collection_group:
            return {
              type: element.type,
              id: element.id,
              is_enabled: true,
              commerce_platform_item_id: element.commerce_platform_item_id,
              elements: element.elements.map(
                (groupElement) =>
                  getElementUpdateInfo(
                    groupElement
                  ) as unknown as components["schemas"]["UpdateMediaCollectionGroupElementRequestData"]["elements"][number]
              ),
            };
          case AdMediaElementType.product_group:
            return {
              type: element.type,
              id: element.id,
              is_enabled: true,
              commerce_platform_item_id: element.commerce_platform_item_id,
              elements: element.elements.map(
                (groupElement) =>
                  getElementUpdateInfo(
                    groupElement
                  ) as unknown as components["schemas"]["UpdateMediaProductGroupElementRequestData"]["elements"][number]
              ),
            };
          default:
            assertNever(element);
        }
      };

      setIsGenerating(true);
      const elementUpdates = elements.map((element) =>
        getElementUpdateInfo(element)
      );

      const variantPreviews = await Promise.all(
        selectedMedia.variants.map(async (variant) => {
          const element = document.getElementById(variant.id)!;
          try {
            const dataUrl = await getImageFromElement(element, {
              width: Math.round(
                (1 / getNumericAdMediaAspectRatio(variant.aspect_ratio)) *
                  AD_PREVIEW_HEIGHT
              ),
              height: AD_PREVIEW_HEIGHT,
            });
            if (!dataUrl) {
              return;
            }
            return dataURIToFile(dataUrl, variant.id);
          } catch (e) {
            console.error(e);
          }
        })
      );

      setIsGenerating(false);
      generateAdCreativeMedia.mutate({
        files: files,
        variant_previews: _.compact(variantPreviews),
        request_data: {
          primary_text: selectedAd.data.primary_text,
          selected_media: {
            id: selectedMedia.id,
            elements: elementUpdates,
          },
        },
        creativeId,
        campaignId: selectedAd.data.campaign.id,
      });
    },
    isPending: generateAdCreativeMedia.isPending || isGenerating,
  };
};

export default useUpdateAdCreativeMutation;
