import AppButton from "../core/buttons/AppButton/AppButton";
import CloseIconButton from "../core/buttons/CloseIconButton";
import FormInputField from "../core/inputs/FormInputField";
import * as Form from "@radix-ui/react-form";
import {
  Dialog,
  Flex,
  Spinner,
  Text,
  Select,
  TextField,
} from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import styled from "styled-components";
import useMetaAdSetFormQuery from "~/hooks/meta/useMetaAdSetFormQuery";
import { getAdSetsQueryKey } from "~/hooks/meta/useMetaAdsetsQuery";
import useMetaCreateAdSetMutation from "~/hooks/meta/useMetaCreateAdSetMutation";

const TextFieldRoot = styled(TextField.Root)`
  width: 100%;
  height: 40px;
  border-radius: 8px;
`;

const SelectTrigger = styled(Select.Trigger)`
  height: 48px;
  border-radius: 12px;
  width: 100%;
`;

const MetaNewAdSetDialog = ({
  campaignID,
  metaAdAccountID,
  trigger,
  onSuccess,
}: {
  campaignID: string;
  metaAdAccountID: string;
  trigger: React.ReactNode;
  onSuccess: (adsetID: string) => void;
}) => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const { formData, isLoading, isSuccess } =
    useMetaAdSetFormQuery(metaAdAccountID);
  const createAdSetMutation = useMetaCreateAdSetMutation({
    onSuccess: (adset) => {
      onSuccess(adset.id);
      setIsOpen(false);
      queryClient.invalidateQueries({
        queryKey: getAdSetsQueryKey(metaAdAccountID, campaignID),
      });
    },
  });

  const header = (
    <Flex
      justify="between"
      align="center"
      p="16px"
      style={{ borderBottom: "1px solid #E2E2E2" }}
    >
      <Dialog.Title size="4" weight="medium" mb="0">
        Create Ad Set
      </Dialog.Title>
      <Dialog.Close disabled={createAdSetMutation.isPending}>
        <CloseIconButton />
      </Dialog.Close>
    </Flex>
  );

  const footer = (
    <Flex
      ml="auto"
      gap="3"
      p="24px"
      width="100%"
      style={{ borderTop: "1px solid #DDD7D7" }}
      justify={"end"}
    >
      <Flex gap="3">
        <Dialog.Close>
          <AppButton
            radius="large"
            variant="outlined"
            disabled={createAdSetMutation.isPending}
          >
            Cancel
          </AppButton>
        </Dialog.Close>
        <Form.Submit asChild>
          <AppButton
            variant="dark"
            radius="large"
            disabled={createAdSetMutation.isPending}
          >
            {createAdSetMutation.isPending && <Spinner />}
            {createAdSetMutation.isPending ? "Creating" : "Create"}
          </AppButton>
        </Form.Submit>
      </Flex>
    </Flex>
  );

  const content = (
    <Flex p="24px" direction="column" gap="2">
      <FormInputField
        label="Name"
        valueMissingText="Field is required"
        name="name"
        input={
          <TextFieldRoot required name="name" placeholder="Enter ad set name" />
        }
      />

      <FormInputField
        label="Pixel"
        valueMissingText="Field is required"
        name="pixel_id"
        input={
          <Select.Root defaultValue="" required name="pixel_id">
            <SelectTrigger placeholder="Select a pixel" />
            <Select.Content>
              <Select.Group>
                {formData?.pixels.map((pixel) => (
                  <Select.Item key={pixel.id} value={pixel.id}>
                    {pixel.name}
                  </Select.Item>
                ))}
              </Select.Group>
            </Select.Content>
          </Select.Root>
        }
      />

      <FormInputField
        label="Daily Budget"
        valueMissingText="Field is required"
        name="daily_budget"
        input={
          <TextFieldRoot
            required
            name="daily_budget"
            placeholder="Enter daily budget (e.g. 50.00)"
            type="number"
            step="0.01"
            onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
              const input = event.target;
              // Remove non-digits
              const digits = input.value.replace(/\D/g, "");

              // Show formatted dollars in the visible input
              const formatted = (parseInt(digits || "0") / 100).toFixed(2);
              if (!isNaN(parseFloat(formatted))) {
                console.log(formatted);
                input.value = formatted;
              }
            }}
          >
            <TextField.Slot side="right">
              <Text size="2" color="gray">
                {formData?.currency}
              </Text>
            </TextField.Slot>
          </TextFieldRoot>
        }
      />
    </Flex>
  );

  const onSubmit = (data: FormData) => {
    const name = data.get("name") as string;
    const pixel_id = data.get("pixel_id") as string;
    const daily_budget = parseFloat(data.get("daily_budget") as string);

    createAdSetMutation.mutate({
      meta_ad_account_id: metaAdAccountID,
      campaign_id: campaignID,
      name,
      pixel_id,
      daily_budget: daily_budget * 100, // Convert dollars to cents
    });
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>
      <Dialog.Content
        style={{
          width: "50%",
          padding: "0",
        }}
      >
        <Form.Root
          onSubmit={(event) => {
            event.preventDefault();
            const data = new FormData(event.currentTarget);
            onSubmit(data);
          }}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {header}
          {content}
          {footer}
        </Form.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default MetaNewAdSetDialog;
