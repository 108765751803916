import CloseIconButton from "../../../../core/buttons/CloseIconButton";
import AssetSelectorSections from "./sections/AssetSelectorSections";
import LibraryAssets from "./sections/LibraryAssets";
import RelatedImages from "./sections/RelatedImages";
import {
  ImageAssetCategory,
  GeneratedImageAssetCategory,
  ImageAssetOrientation,
} from "@openapi";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Box, Dialog, Flex, Heading, TextField } from "@radix-ui/themes";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast, Toaster } from "sonner";
import styled from "styled-components";
import { SparklesIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import UploadImageButton from "~/components/core/buttons/UploadImageButton";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useGenerateMediaImageWithStatus from "~/hooks/style-library/useGenerateMediaImageWithStatus";
import useMediaBrandImagesUpload from "~/hooks/style-library/useMediaBrandImagesUpload";

export const HoverOverlay = styled.div<{ $isSelected?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.$isSelected ? 1 : 0)};
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

export type AssetSelectorItem = {
  url: string;
  asset?: ImageAssetSchema;
};

const AssetSelectorDialog = ({
  singleAsset,
  isDialogOpen,
  onToggleDialogOpen,
  preselectedAssets,
  onSelect,
  relatedAssets,
}: {
  singleAsset?: boolean;
  isDialogOpen: boolean;
  onToggleDialogOpen: (open: boolean) => void;
  preselectedAssets?: AssetSelectorItem[];
  relatedAssets?: AssetSelectorItem[] /** Items might only contain a url (which may or may or may not be an asset) */;
  onSelect: (assets: AssetSelectorItem[]) => void;
}) => {
  const activeBrandID = useActiveBrandID();

  const [selectedAssets, setSelectedAssets] = useState<AssetSelectorItem[]>([]);

  const [uploadError, setUploadError] = useState<string | null>(null);

  const [isGenerateImageDialogOpen, setIsGenerateImageDialogOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (isDialogOpen) {
      setSelectedAssets(preselectedAssets ?? []);
    }
  }, [isDialogOpen]);

  const [debouncedSearch, setDebouncedSearch] = useState("");

  const [search, setSearch] = useState("");

  const {
    mutate: uploadImageAssetsMutation,
    isPending: isUploadImageAssetMutationPending,
  } = useMediaBrandImagesUpload({
    data: {
      brandID: activeBrandID,
      category: ImageAssetCategory.other,
    },
    onSuccess: (data) => {
      setSelectedAssets(data.new_assets ?? []);
      handleSearchChange("");
      const scrollableTarget = document.getElementsByClassName(
        "infinite-scroll-component "
      )?.[0];
      scrollableTarget?.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    },
    onError: (error) => {
      if (error.response?.status === 403) {
        setUploadError?.("You do not have permission to upload images.");
      } else {
        console.log(error);
        setUploadError?.("An error occurred while uploading images.");
      }
    },
  });

  const {
    generateImageSessionID,
    generateImageMutation,
    isGenerating: isGenerateImageMutationPending,
  } = useGenerateMediaImageWithStatus({
    onScheduleError(error) {
      setIsGenerateImageDialogOpen(false);
      setUploadError?.(error);
    },
    onGenerationSuccess(data) {
      setSelectedAssets(data.image_asset !== null ? [data.image_asset] : []);
      handleSearchChange("");
      const scrollableTarget = document.getElementsByClassName(
        "infinite-scroll-component "
      )?.[0];
      scrollableTarget?.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    },
    onGenerationError(error) {
      toast.error(error);
    },
    onComplete() {
      setIsGenerateImageDialogOpen(false);
    },
  });

  const debouncedSearchCall = useCallback(
    debounce((value: string) => {
      setDebouncedSearch(value);
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSearchCall.cancel();
    };
  }, [debouncedSearchCall]);

  const handleSearchChange = (value: string) => {
    setSearch(value);
    debouncedSearchCall(value);
  };

  const handleGenerateImage = () => {
    if (selectedAssets.length !== 1) {
      alert("Please select one image asset to use to generate a new one.");
      return;
    }
    const asset = selectedAssets[0];
    const commerce_id = asset.asset?.commerce_platform_item_id ?? null;
    generateImageMutation({
      brand_id: activeBrandID,
      category: GeneratedImageAssetCategory.hero,
      orientation: ImageAssetOrientation.landscape,
      image_asset_id: null,
      image_url: asset.url,
      commerce_platform_item_id: commerce_id,
    });
  };

  const selectAsset = (asset: AssetSelectorItem) => {
    if (singleAsset) {
      setSelectedAssets([asset]);
    } else {
      !!selectedAssets.find((file) => file.url === asset.url)
        ? setSelectedAssets(
            selectedAssets.filter((file) => file.url !== asset.url)
          )
        : setSelectedAssets([...selectedAssets, asset]);
    }
  };

  // adding preselected images to the start of the "related images" section
  const completeRelatedAssets = useMemo(
    () =>
      [
        ...(preselectedAssets ?? []),
        ...(relatedAssets ?? [])?.filter(
          (asset) =>
            !preselectedAssets?.map((asset) => asset.url)?.includes(asset.url)
        ),
      ].slice(0, 6),
    [preselectedAssets, relatedAssets]
  );
  return (
    <>
      <Dialog.Root open={isDialogOpen} onOpenChange={onToggleDialogOpen}>
        <Dialog.Content maxWidth="780px" style={{ padding: 0 }}>
          <Toaster richColors />
          <Flex
            justify="between"
            align="center"
            p="16px"
            pb="12px"
            style={{ borderBottom: "1px solid #E2E2E2" }}
          >
            <Dialog.Title size="4" weight="bold" mb="0">
              Select Image
            </Dialog.Title>
            <Dialog.Close>
              <CloseIconButton />
            </Dialog.Close>
          </Flex>
          <Flex direction="column" px="24px" py="16px" gap="3">
            <Flex gap="5" py="5px" align="center">
              <TextField.Root
                value={search}
                onChange={(event) => handleSearchChange(event.target.value)}
                style={{
                  height: "40px",
                  backgroundColor: "#F1F1F0",
                  width: "calc(100% - 24px)",
                }}
                radius="large"
                color="teal"
                variant="soft"
                placeholder="Search…"
              >
                <TextField.Slot>
                  <MagnifyingGlassIcon height="16" width="16" />
                </TextField.Slot>
              </TextField.Root>
              <UploadImageButton
                setUploadError={setUploadError}
                onUpload={uploadImageAssetsMutation}
                isLoading={isUploadImageAssetMutationPending}
              />
            </Flex>
            {uploadError && (
              <Heading size="1" color="red" align={"center"} mb="2">
                {uploadError}
              </Heading>
            )}
            <AssetSelectorSections
              sections={[
                relatedAssets
                  ? {
                      title: "Related Images",
                      section: (
                        <RelatedImages
                          relatedAssets={completeRelatedAssets}
                          selectAsset={selectAsset}
                          selectedAssets={selectedAssets}
                        />
                      ),
                    }
                  : null,
                {
                  title: "Library Assets",
                  section: (
                    <LibraryAssets
                      search={debouncedSearch}
                      selectedAssets={selectedAssets}
                      selectAsset={selectAsset}
                    />
                  ),
                },
              ]}
            />
          </Flex>
          <Flex px="24px" py="12px" justify="end">
            <Flex gap="3">
              <Dialog.Close>
                <AppButton
                  disabled={!selectedAssets.length}
                  onClick={() => onSelect(selectedAssets)}
                  variant="soft"
                  size="3"
                  radius="large"
                >
                  Use Selected Image{selectedAssets.length > 1 ? "s" : ""}
                </AppButton>
              </Dialog.Close>
              <AppButton
                loading={
                  isGenerateImageMutationPending ||
                  generateImageSessionID !== null
                }
                disabled={selectedAssets.length !== 1}
                variant="primary"
                size="3"
                radius="large"
                onClick={() => setIsGenerateImageDialogOpen(true)}
              >
                <SparklesIcon size="20px" />
                Generate from Selected
              </AppButton>
            </Flex>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
      <Dialog.Root
        open={isGenerateImageDialogOpen}
        onOpenChange={setIsGenerateImageDialogOpen}
      >
        <Dialog.Content>
          <Dialog.Title>Generate Image with AI</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            Transform your product image with AI-generated backgrounds using the
            currently selected image. This may take up to 30 seconds.
          </Dialog.Description>
          <Flex gap="3" justify="end">
            <Dialog.Close>
              <AppButton variant="outlined" size="3" radius="large">
                Cancel
              </AppButton>
            </Dialog.Close>
            <AppButton
              variant="dark"
              size="3"
              radius="large"
              loading={
                isGenerateImageMutationPending ||
                generateImageSessionID !== null
              }
              onClick={handleGenerateImage}
            >
              Generate
            </AppButton>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};

export default AssetSelectorDialog;
