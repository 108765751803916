import { Flex, Grid } from "@radix-ui/themes";
import React from "react";
import styled, { CSSProperties } from "styled-components";

const SlotContainer = styled(Flex)<{
  $position?: "top-left" | "bottom-left" | "left" | "right";
  $gridSpan?: boolean;
  $flex?: CSSProperties["flex"];
}>`
  max-height: 100%;
  ${({ $flex }) => $flex && `flex: ${$flex};`}
  ${({ $position, $gridSpan = false }) =>
    $position === "right" &&
    $gridSpan &&
    `
    grid-column: 2;
    grid-row: 1 / span 2;
  `}
  & > * {
    width: 100%;
    height: 100%;
    border: 0.5px solid var(--black-6, #0000000f);
    border-radius: ${({ $position }) => {
      switch ($position) {
        case "top-left":
          return "16px 0 0 0";
        case "bottom-left":
          return "0 0 0 16px";
        case "left":
          return "16px 0 0 16px";
        case "right":
          return "0 16px 16px 0";
        default:
          return "16px";
      }
    }};
  }
`;

type ThreeSlotsPreviewProps = {
  /** `fixed` will always separate layout into 3 slots, while `dynamic` will try to fill blank space */
  layoutStyle?: "fixed" | "dynamic";
  children: React.ReactNode;
  style?: React.CSSProperties;
} & Pick<
  React.ComponentProps<typeof Grid>,
  | "gap"
  | "p"
  | "pb"
  | "px"
  | "py"
  | "m"
  | "mb"
  | "mx"
  | "my"
  | "width"
  | "height"
  | "flexShrink"
>;

const ThreeSlotsPreview: React.FC<ThreeSlotsPreviewProps> = ({
  children: childrenProp,
  layoutStyle = "dynamic",
  gap = "2px",
  ...props
}) => {
  const children = React.Children.toArray(childrenProp);
  const halfGap = `calc(${gap} / 2)`;
  if (layoutStyle === "dynamic") {
    if (children.length <= 1) {
      return <SlotContainer {...props}>{children[0]}</SlotContainer>;
    }
    if (children.length === 2) {
      return (
        <Flex gap={gap} overflow="hidden" {...props}>
          <SlotContainer $flex="1" $position="left">
            {children[0]}
          </SlotContainer>
          <SlotContainer $flex="1" $position="right">
            {children[1]}
          </SlotContainer>
        </Flex>
      );
    }
  }

  return (
    <Grid
      gap={gap}
      overflow="hidden"
      // 1px for the border (0.5px on each side)
      columns={`calc(34% - ${halfGap} - 1px) calc(66% - ${halfGap} - 1px)`}
      rows={`calc(50% - ${halfGap} - 1px) calc(50% - ${halfGap} - 1px)`}
      flexShrink="0"
      {...props}
    >
      <SlotContainer $position="top-left">{children[0]}</SlotContainer>
      <SlotContainer $position="bottom-left">{children[1]}</SlotContainer>
      <SlotContainer $position="right" $gridSpan>
        {children[2]}
      </SlotContainer>
    </Grid>
  );
};

export default ThreeSlotsPreview;
