import AnnouncementPopup from "./AnnouncementPopup";
import { Flex } from "@radix-ui/themes";
import { XIcon } from "lucide-react";
import React, { useEffect } from "react";
import styled from "styled-components";
import MarkAvatar from "~/components/core/avatars/MarkAvatar";
import {
  useMarkPopupContext,
  useToggleMarkPopup,
} from "~/contexts/MarkPopupContext";

const MarkAnnounceAvatar = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: white;
  overflow: hidden;
`;

const StyledMarkAvatar = styled(MarkAvatar)<{ $isDisplayed?: boolean }>`
  position: absolute;
  transition: all 0.6s;
  transform: ${(props) =>
    props.$isDisplayed
      ? "translate(0%, 0%) scale(1)"
      : "translate(-100%, 0%) scale(0.5)"};
`;

const XIconContainer = styled(Flex)<{ $isDisplayed?: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  transform: ${(props) =>
    props.$isDisplayed
      ? "translate(0%, 0%) scale(1)"
      : "translate(100%, 0%) scale(0.5)"};
`;

const MarkAnnouncement: React.FC = () => {
  const { state } = useMarkPopupContext();
  const toggleMarkPopup = useToggleMarkPopup();

  useEffect(() => {
    if (state.markPopupOpen && !state.message) {
      toggleMarkPopup();
    }
  }, [state.markPopupOpen, state.message]);

  const handleToggleMarkPopup = () => {
    if (!state.markPopupOpen && !state.message) {
      return;
    }
    toggleMarkPopup();
  };
  return (
    <Flex direction="column">
      <MarkAnnounceAvatar onClick={handleToggleMarkPopup}>
        <StyledMarkAvatar $isDisplayed={!state.markPopupOpen} size={"4"} />
        <XIconContainer
          $isDisplayed={state.markPopupOpen}
          align={"center"}
          justify={"center"}
        >
          <XIcon />
        </XIconContainer>
      </MarkAnnounceAvatar>
      <AnnouncementPopup text={state.message} isOpen={state.markPopupOpen} />
    </Flex>
  );
};

export default MarkAnnouncement;
