import { components } from "@openapi";
import { SyntheticEvent } from "react";
import styled from "styled-components";
import ThreeSlotsPreview from "~/components/core/layout/ThreeSlotsPreview";

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  border: 0.5px solid var(--black-8, #00000014);
`;
const handleImageLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
  const element = event.currentTarget;
  const ratio = element.naturalWidth / element.naturalHeight;
  if (ratio < 0.25 || ratio > 4) {
    element.style.objectFit = "contain";
  }
};
const CampaignProductsPreview: React.FC<{
  productsData?: components["schemas"]["ShopifyProductInfoExtra"][] | null;
}> = ({ productsData }) => (
  <ThreeSlotsPreview width="300px" flexShrink="0" height="210px">
    <Image
      src={productsData?.[0]?.image_url ?? ""}
      alt={productsData?.[0]?.title}
      onLoad={(event) => handleImageLoad(event)}
    />
    {productsData?.[1]?.image_url && (
      <Image
        src={productsData[1].image_url}
        alt={productsData[1].title}
        onLoad={(event) => handleImageLoad(event)}
      />
    )}
    {productsData?.[2]?.image_url && (
      <Image
        src={productsData[2].image_url ?? ""}
        alt={productsData[2].title}
        onLoad={(event) => handleImageLoad(event)}
      />
    )}
  </ThreeSlotsPreview>
);

export default CampaignProductsPreview;
