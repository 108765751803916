import CampaignPublishDialogAdCard from "./CampaignPublishDialogAdCard";
import CampaignPublishDialogChatContainer from "./CampaignPublishDialogChatContainer";
import CampaignPublishDialogEmailCard from "./CampaignPublishDialogEmailCard";
import { operations } from "@openapi";
import {
  Dialog,
  Flex,
  Text,
  SegmentedControl,
  ScrollArea,
  Box,
  Heading,
} from "@radix-ui/themes";
import { Info } from "lucide-react";
import { useEffect, useState } from "react";
import { Toaster } from "sonner";
import styled from "styled-components";
import { MailIcon, MetaIcon, XIcon } from "~/assets/icons";
import DraperSegmentControl from "~/components/core/DraperSegmentedControl";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import {
  getPublishedCreatives,
  getUnpublishedCreatives,
} from "~/utils/campaign/utils";

const Header = styled.div`
  border: 1px solid var(--border-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const CreativesList = styled(Flex)`
  justify-content: center;
  padding: 24px 12px;
`;

type CampaignDetailResponse =
  operations["campaign_api_get_campaign_detail"]["responses"][200]["content"]["application/json"];

const CampaignPublishDialog = ({
  campaign,
  preselectedCreativeIds,
  open,
  onOpenChange,
}: {
  campaign: CampaignDetailResponse;
  preselectedCreativeIds?: { emails?: string[]; ads?: string[] };
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}) => {
  const ifNoEmails = campaign.email_creatives.length === 0;
  const ifNoAds = campaign.ad_creatives.length === 0;

  const unpublishedEmailCreatives = getUnpublishedCreatives(campaign).emails;
  const publishedEmailCreatives = getPublishedCreatives(campaign).emails;
  const unpublishedAdCreatives = getUnpublishedCreatives(campaign).ads;
  const publishedAdCreatives = getPublishedCreatives(campaign).ads;

  const [isOpen, setIsOpen] = useState(open ?? false);
  const [campaignType, setCampaignType] = useState<"email" | "ads">(
    ifNoEmails ? "ads" : "email"
  );
  const [selectedCreatives, setSelectedCreatives] = useState<{
    emails: string[];
    ads: string[];
  }>({
    emails: unpublishedEmailCreatives.map(({ id }) => id),
    ads: unpublishedAdCreatives.map(({ id }) => id),
  });

  useEffect(() => {
    if (preselectedCreativeIds === undefined) {
      return;
    }
    setSelectedCreatives({
      emails: preselectedCreativeIds.emails ?? [],
      ads: preselectedCreativeIds.ads ?? [],
    });
    const preselectedEmailsCount = preselectedCreativeIds.emails?.length ?? 0;
    const preselectedAdsCount = preselectedCreativeIds.ads?.length ?? 0;
    if (preselectedEmailsCount > 0) {
      setCampaignType("email");
    } else if (preselectedAdsCount > 0) {
      setCampaignType("ads");
    }
  }, [preselectedCreativeIds]);

  const handleCreativeSelection = (
    creativeId: string,
    type: "emails" | "ads"
  ) => {
    setSelectedCreatives((prev) => ({
      ...prev,
      [type]: prev[type].includes(creativeId)
        ? prev[type].filter((id) => id !== creativeId)
        : [...prev[type], creativeId],
    }));
  };

  const emailContent = (
    <CreativesList
      direction={"column"}
      width={{ xs: "100%", sm: "90%", md: "85%", lg: "80%" }}
      gap={"24px"}
      overflow={"auto"}
      align="center"
    >
      {unpublishedEmailCreatives.map((email, idx) => (
        <CampaignPublishDialogEmailCard
          audiences={email.audiences}
          audienceExclusions={email.excluded_audiences}
          status={email.status}
          publishedAt={email.published_at}
          onSelect={() => handleCreativeSelection(email.id, "emails")}
          selected={selectedCreatives.emails.includes(email.id)}
          key={email.id}
          emailId={email.id}
          campaignId={campaign.id}
          previewImage={email.preview_image}
          title={`Email ${idx + 1}`}
          contentHeader={email.title}
          contentSubheader={email.subtitle}
        />
      ))}

      {publishedEmailCreatives.length > 0 && (
        <Box width={"100%"}>
          <Text size={"2"} weight={"bold"}>
            Active or Scheduled Emails
          </Text>

          <Flex align="center" gap="2" mt="2">
            <Info size={16} />
            <Text size="2" color="gray">
              You can still edit & change scheduled date from the email editor.
            </Text>
          </Flex>
        </Box>
      )}
      {publishedEmailCreatives.map((email, idx) => (
        <CampaignPublishDialogEmailCard
          audiences={email.audiences}
          audienceExclusions={email.excluded_audiences}
          status={email.status}
          publishedAt={email.published_at}
          selected={selectedCreatives.emails.includes(email.id)}
          key={email.id}
          emailId={email.id}
          campaignId={campaign.id}
          previewImage={email.preview_image}
          title={`Email ${idx + 1}`}
          contentHeader={email.title}
          contentSubheader={email.subtitle}
        />
      ))}
    </CreativesList>
  );

  const adContent = (
    <CreativesList
      direction={"column"}
      width={"80%"}
      gap={"24px"}
      overflow={"auto"}
      align="center"
    >
      {unpublishedAdCreatives.map((ad) => (
        <CampaignPublishDialogAdCard
          key={ad.id}
          onSelect={() => handleCreativeSelection(ad.id, "ads")}
          selected={selectedCreatives.ads.includes(ad.id)}
          adId={ad.id}
          title={ad.title}
          previewImages={ad.preview_images}
          contentHeader=""
          contentSubheader={ad.primary_text}
        />
      ))}
      {publishedAdCreatives.length > 0 && (
        <Box width={"100%"}>
          <Text size={"2"} weight={"bold"}>
            Published Ads
          </Text>

          <Flex align="center" gap="2" mt="2">
            <Info size={16} />
            <Text size="2" color="gray">
              You can still edit to update the ad creative.
            </Text>
          </Flex>
        </Box>
      )}
      {publishedAdCreatives.map((ad) => (
        <CampaignPublishDialogAdCard
          key={ad.id}
          selected={selectedCreatives.ads.includes(ad.id)}
          adId={ad.id}
          title={ad.title}
          previewImages={ad.preview_images}
          contentHeader=""
          contentSubheader={ad.primary_text}
        />
      ))}
    </CreativesList>
  );

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (onOpenChange) {
      onOpenChange(open);
    }
  };

  return (
    <>
      <Dialog.Root open={open ?? isOpen} onOpenChange={handleOpenChange}>
        {open === undefined && (
          <Dialog.Trigger>
            <AppButton>Publish Campaign</AppButton>
          </Dialog.Trigger>
        )}

        <Dialog.Content
          style={{ padding: 0 }}
          maxWidth={"95%"}
          maxHeight={"95%"}
          height={"90vh"}
        >
          <Toaster richColors />

          <Flex direction={"column"} height={"100%"}>
            <Header>
              <Heading size="5" weight={"bold"}>
                Publish Campaign
              </Heading>
              {ifNoEmails || ifNoAds ? null : (
                <DraperSegmentControl
                  $size="large"
                  value={campaignType}
                  radius="large"
                  size="3"
                  onValueChange={(value) => {
                    if (value) {
                      setCampaignType(value as "email" | "ads");
                    }
                  }}
                >
                  <SegmentedControl.Item value="email">
                    <Flex gap="4px" align="center">
                      <MailIcon size="20" />
                      <Text>Email</Text>
                    </Flex>
                  </SegmentedControl.Item>
                  <SegmentedControl.Item value="ads">
                    <Flex gap="4px" align="center">
                      <MetaIcon width="20" />
                      <Text>Ads</Text>
                    </Flex>
                  </SegmentedControl.Item>
                </DraperSegmentControl>
              )}
              <Dialog.Close>
                <AppButton variant="outlined" radius="large">
                  <XIcon size={16} />
                  Close
                </AppButton>
              </Dialog.Close>
            </Header>

            <Flex
              direction="row"
              height={"100%"}
              minHeight={"0"}
              overflow={"hidden"}
            >
              <CampaignPublishDialogChatContainer
                campaignId={campaign.id}
                toggleSelectedCampaignType={() =>
                  setCampaignType(campaignType === "email" ? "ads" : "email")
                }
                onClearSelectedCreatives={(type: "ads" | "emails") =>
                  setSelectedCreatives({
                    ...selectedCreatives,
                    [type]: [],
                  })
                }
                selectedCampaignType={campaignType}
                selectedAds={selectedCreatives.ads}
                selectedEmails={selectedCreatives.emails}
              />

              <ScrollArea scrollbars="vertical">
                <Flex direction="column" width={"100%"} align="center">
                  {campaignType === "email" ? emailContent : adContent}
                </Flex>
              </ScrollArea>
            </Flex>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};

export default CampaignPublishDialog;
