import BackgroundColorModifier from "./modifiers/BackgroundColorModifier";
import GeneratedVariantSelector from "./modifiers/GeneratedVariantSelector";
import ProductMetadataModifier from "./modifiers/ProductMetadataModifier";
import ProductsListModifier from "./modifiers/ProductsListModifier";
import TextModifier from "./modifiers/TextModifier";
import { EmailElementType } from "@openapi";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useInitEmailSectionElementMutation from "~/hooks/emails/useInitEmailSectionElementQuery";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { isProductsSection } from "~/utils/emails/useSectionTypeCheck";

const ProductGridView = () => {
  const activeBrandId = useActiveBrandID();
  const updateSection = useUpdateSectionField();

  const { selectedSectionId, sections } = useEmailState();

  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );

  const { mutate: initEmailSectionElement } =
    useInitEmailSectionElementMutation({
      onSuccess: (data) => {
        updateSection({
          sectionId: selectedSectionId,
          field: "products",
          value: data,
        });
      },
    });

  if (!selectedSection) {
    return null;
  }
  if (!isProductsSection(selectedSection)) {
    return null;
  }

  const title = selectedSection.title;
  const subtitle = selectedSection.subtitle;
  const products = selectedSection.products;

  return (
    <>
      <GeneratedVariantSelector />

      <ProductsListModifier
        products={products}
        onAddProduct={(products) => {
          initEmailSectionElement({
            brand_id: activeBrandId,
            element_type: EmailElementType.product,
            palette: selectedSection.palette,
            product_element_defaults: Array.from(products.values()).map((p) => {
              const existingProductElement = selectedSection.products.find(
                (product) => product.commerce_platform_id === p.product_id
              );

              return {
                commerce_platform_id: p.product_id,
                title: existingProductElement?.title
                  ? {
                      text: existingProductElement.title.text,
                      enabled: existingProductElement.title.enabled,
                    }
                  : null,
                price: existingProductElement?.price
                  ? {
                      text: existingProductElement.price.text,
                      enabled: existingProductElement.price.enabled,
                    }
                  : null,
                description: existingProductElement?.description
                  ? {
                      text: existingProductElement.description.text,
                      enabled: existingProductElement.description.enabled,
                    }
                  : null,
                cta_button: existingProductElement?.cta_button
                  ? {
                      background_color:
                        existingProductElement.cta_button.background_color,
                      enabled: existingProductElement.cta_button.enabled,
                      text: existingProductElement.cta_button.text,
                    }
                  : null,
                image: existingProductElement?.image
                  ? {
                      image_url: existingProductElement.image.image_url,
                      enabled: existingProductElement.image.enabled,
                    }
                  : null,
              };
            }),
          });
        }}
      />

      <TextModifier
        textElement={title}
        label="Title"
        enabled={title.enabled ?? false}
        fieldName="title.text"
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "title.enabled",
            value: enabled,
          });
        }}
      />
      <TextModifier
        textElement={subtitle}
        label="Subtitle"
        enabled={subtitle.enabled ?? false}
        fieldName="subtitle.text"
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "subtitle.enabled",
            value: enabled,
          });
        }}
      />

      {/* <ButtonModifier
        fieldName="cta_button"
        buttonElement={selectedSection.cta_button}
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "cta_button.enabled",
            value: enabled,
          });
        }}
      /> */}

      <ProductMetadataModifier products={products} />

      <BackgroundColorModifier
        palette={selectedSection.palette}
        sectionId={selectedSection.id}
      />
    </>
  );
};

export default ProductGridView;
