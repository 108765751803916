import { operations } from "@openapi";
import { Box, Button, Dialog, Flex, Heading, Table } from "@radix-ui/themes";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { Plus } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

type CreateRecommenderJobRequestBody =
  operations["intern_api_create_recommender_job"]["requestBody"]["content"]["application/json"];

type CreateRecommenderJobResponse =
  operations["intern_api_create_recommender_job"]["responses"][200]["content"]["application/json"];

const styles = {
  tableContainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "6px",
    padding: "8px",
    overflow: "auto",
  },
  tableHeader: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  tableText: {
    fontSize: "12px",
    whiteSpace: "normal",
  },
  tooltipContent: {
    background: "#fff",
    zIndex: 1000,
    padding: "8px",
    borderRadius: "4px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
};

interface InternBrandRecommenderCardProps {
  parentState: number;
  refresh: () => void;
}

const InternBrandRecommenderCard: React.FC<InternBrandRecommenderCardProps> = ({
  parentState,
  refresh,
}) => {
  const activeBrandID = useActiveBrandID();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { mutate: createJobMutation, isPending: createJobMutationIsPending } =
    useMutation<
      CreateRecommenderJobResponse,
      AxiosError,
      CreateRecommenderJobRequestBody
    >({
      mutationFn: async (
        requestBody
      ): Promise<CreateRecommenderJobResponse> => {
        const { data } = await axios.post(
          `/api/v1/intern/recommender-job`,
          requestBody,
          {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": Cookies.get("csrftoken") ?? "",
            },
          }
        );
        return data;
      },
      onSuccess: (response) => {
        setIsDialogOpen(false);
        if (response.success) {
          refresh();
          alert("Brand recommender job successfully created.");
        } else {
          alert(response);
        }
      },
      onError: (error) => {
        setIsDialogOpen(false);
        const errorMessage =
          error.status !== 500 && error.response?.data
            ? String(error.response?.data)
            : "Something went wrong";
        alert(`Error generating primary text: ${errorMessage}`);
      },
    });

  return (
    <Box height="100%">
      <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <Dialog.Content>
          <Dialog.Title>Generate Recommendations</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            Are you sure you'd like to generate recommendations for today?
            Recommendations will not be generated if they already exist for
            today. Progress can be tracked via Jobs.
          </Dialog.Description>
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="outline">Cancel</Button>
            </Dialog.Close>
            <Button
              onClick={() => {
                setIsDialogOpen(false);
                createJobMutation({ brand_id: activeBrandID });
              }}
              loading={createJobMutationIsPending}
            >
              OK
            </Button>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
      <Flex direction="column" height="100%" gap="18px">
        <Flex justify="between">
          <Heading size="5">Recommender</Heading>
          <Button onClick={() => setIsDialogOpen(true)}>
            <Plus size="18px" />
          </Button>
        </Flex>
        <Flex direction="column" style={styles.tableContainer} flexGrow="1">
          <Table.Root>
            <Table.Header>
              <Table.Row>
                {["Campaign Recommendation", "Created", ""].map(
                  (header, headerIndex) => (
                    <Table.Cell key={headerIndex} style={styles.tableHeader}>
                      {header}
                    </Table.Cell>
                  )
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>{"Under Construction"}</Table.Body>
          </Table.Root>
        </Flex>
      </Flex>
    </Box>
  );
};

export default InternBrandRecommenderCard;
