import { operations } from "@openapi";
import { toast } from "sonner";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type CreateMetaAdSetResponse =
  operations["meta_api_create_meta_ad_set"]["responses"]["200"]["content"]["application/json"];
type CreateMetaAdSetRequestData =
  operations["meta_api_create_meta_ad_set"]["requestBody"]["content"]["application/json"];

const useMetaCreateAdSetMutation = ({
  onSuccess,
}: {
  onSuccess: (adset: CreateMetaAdSetResponse) => void;
}) => {
  return useDraperApiPostMutation<
    CreateMetaAdSetResponse,
    CreateMetaAdSetRequestData
  >({
    path: "/meta/create/adset",
    mutationKey: ["create-meta-ad-set"],
    onSuccess: (result) => {
      toast.success("Ad set created");
      onSuccess(result);
    },
    onError: () => {
      toast.error("Ad set creation failed");
    },
  });
};

export default useMetaCreateAdSetMutation;
