import TypingAnimation from "../animations/TypingAnimation";
import { Avatar, Flex, FlexProps, Heading, Text } from "@radix-ui/themes";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import styled, { CSSProperties } from "styled-components";
import markSrc from "~/assets/mark.png";
import LoadingDot from "~/components/campaign/wizard/misc/LoadingDot";

// TODO: should check if we want to move towards the compact variant for all cards (including Onboarding)
type CardVariant = "default" | "compact";

const Container = styled.div`
  width: 802px;
  position: relative;
`;

const Card = styled(Flex)`
  border: 1px solid var(--border-primary);
  border-radius: 24px;
  background-color: var(--primary-white);
  box-shadow: 0px 12px 120px 0px #00000014;
  box-shadow: 0px 12px 32px -16px #0009320a;
`;

const Footer = styled(Flex)`
  border-top: 1px solid var(--border-primary);
  padding: 24px;
  justify-content: flex-end;
  gap: 16px;
`;

const Title = styled(Text)`
  color: var(--icon-primary-active);
  font-size: 18px;
  font-weight: 500;
`;

const SubtitleAnimation = styled(TypingAnimation)<{
  $variant?: CardVariant;
  $display?: CSSProperties["display"];
}>`
  font-size: ${({ $variant }) => ($variant === "compact" ? "22px" : "24px")};
  font-weight: 600;
  white-space: pre-wrap;
  display: ${({ $display }) => $display};
`;

const AssistantAvatar = styled(Avatar)<{ $variant?: CardVariant }>`
  position: absolute;
  ${({ $variant = "default" }) =>
    $variant === "compact"
      ? "top: -32px; left: -32px; width: 64px; height: 64px;"
      : "top: -32px; left: -32px; width: 72px; height: 72px;"}
`;

const ContentTransition = styled(Flex)<{ $transitionEnabled?: boolean }>`
  flex-direction: column;
  overflow: hidden;
  transition: all 1s, max-height 3s;
  ${({ $transitionEnabled }) =>
    !$transitionEnabled
      ? "max-height: 0px; opacity: 0.1;"
      : "max-height: 3000px;"}
`;

const TypingDot = forwardRef<HTMLDivElement, { style?: CSSProperties }>(
  ({ style }, ref) => {
    return (
      <Flex
        ref={ref}
        style={{
          display: "inline-flex",
          transform: "translateY(2px)",
          ...style,
        }}
      >
        <LoadingDot disableAnimation />
      </Flex>
    );
  }
);

interface CardSubtitleProps {
  text: string | string[];
  variant?: CardVariant;
  animate?: boolean;
  onAnimationComplete?: () => void;
  onAnimationHeightChange?: () => void;
}

const CardSubtitle: React.FC<CardSubtitleProps> = ({
  text,
  variant,
  animate = false,
  onAnimationComplete,
  onAnimationHeightChange,
}) => {
  const textLines = useMemo(
    () => (typeof text === "string" ? [text] : text.filter((sub) => sub)),
    [text]
  );
  const [subtitleAnimationIndex, setSubtitleAnimationIndex] = useState<number>(
    animate ? 0 : textLines.length - 1
  );
  const dotRef = React.useRef<HTMLDivElement>(null);

  return (
    <Flex
      direction="column"
      gap="6"
      align="start"
      position="relative"
      minHeight="30px"
    >
      {textLines.slice(0, subtitleAnimationIndex + 1).map((sub, index) => {
        return (
          <SubtitleAnimation
            key={index}
            $variant={variant}
            cursorRef={dotRef}
            disabled={!animate || subtitleAnimationIndex !== index}
            onAnimationComplete={() => {
              if (subtitleAnimationIndex + 1 === textLines.length) {
                onAnimationComplete?.();
                return;
              }
              setSubtitleAnimationIndex(subtitleAnimationIndex + 1);
              onAnimationHeightChange?.();
            }}
          >
            {sub}
          </SubtitleAnimation>
        );
      })}
      <TypingDot
        ref={dotRef}
        style={{
          display: "none",
          position: "absolute",
          transition: "0.4s all",
        }}
      />
    </Flex>
  );
};

interface ActionCardProps {
  title: string;
  subtitle?: CardSubtitleProps["text"];
  variant?: CardVariant;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  overflowY?: FlexProps["overflowY"];
  maxHeight?: FlexProps["maxHeight"];
  animate?: boolean;
  animationDelay?: number;
  onAnimationStepChange?: (step: ActionCardAnimationStep) => void;
  onAnimationHeightChange?: () => void;
  isLoading?: boolean;
}

export enum ActionCardAnimationStep {
  Initial = 0,
  Subtitle,
  Content,
  Complete,
}

/**
 * @prop {string} title - The title of the card.
 * @prop {string} subtitle - The subtitle of the card.
 * @prop {React.ReactNode} children - The content of the card.
 * @prop {React.ReactNode} footer - The footer of the card.
 *
 * @example
 * <ActionCard
 *  title="Welcome to Draper"
 *  subtitle="Let's get started"
 *  footer={<AppButton>Continue</AppButton>}
 * >
 *   <Text>Some content</Text>
 * </ActionCard>
 */
const Root = ({
  title,
  subtitle,
  variant = "default",
  children,
  footer,
  overflowY = "auto",
  maxHeight,
  animate = false,
  animationDelay,
  onAnimationStepChange,
  onAnimationHeightChange,
  isLoading = false,
}: ActionCardProps) => {
  const cardRef = React.useRef<HTMLDivElement>(null);
  const [animationStep, setAnimationStep] =
    React.useState<ActionCardAnimationStep>(
      animate
        ? ActionCardAnimationStep.Initial
        : ActionCardAnimationStep.Complete
    );
  const [animationMinHeight, setAnimationMinHeight] = useState<number>(80);

  useEffect(() => {
    if (!animate) {
      setAnimationStep(ActionCardAnimationStep.Complete);
    }
  }, [animate]);

  useEffect(() => {
    if (
      isLoading ||
      !animate ||
      animationStep != ActionCardAnimationStep.Initial
    ) {
      return;
    }
    setTimeout(() => {
      setAnimationStep((prev) => {
        const step = subtitle
          ? ActionCardAnimationStep.Subtitle
          : ActionCardAnimationStep.Content;
        if (prev >= step) {
          return prev;
        }
        return step;
      });
    }, animationDelay ?? 0);
  }, [isLoading, animate, animationStep, subtitle]);

  const handleAnimationHeightChange = () => {
    setAnimationMinHeight((prev) => {
      const newHeight = cardRef.current?.clientHeight || 80;
      if (newHeight > prev) {
        return newHeight;
      }
      return prev;
    });
  };

  const incrementAnimationStep = () => {
    setAnimationStep((prev) => prev + 1);
    onAnimationStepChange?.(animationStep + 1);
    handleAnimationHeightChange();
  };

  useEffect(() => {
    if (animationStep === ActionCardAnimationStep.Content) {
      setTimeout(() => {
        incrementAnimationStep();
      }, 300);
    }
  }, [animationStep]);

  useEffect(() => {
    if (animationMinHeight <= 80) {
      return;
    }
    onAnimationHeightChange?.();
  }, [animationMinHeight, onAnimationHeightChange]);

  const padding =
    variant === "compact"
      ? "var(--action-card-compact-xb-padding)"
      : "var(--action-card-xb-padding)";
  return (
    <Container>
      <AssistantAvatar src={markSrc} fallback="Mark" $variant={variant} />
      <Card
        ref={cardRef}
        direction="column"
        overflowY={overflowY}
        maxHeight={maxHeight}
        minHeight={
          animationStep === ActionCardAnimationStep.Complete
            ? undefined
            : `${animationMinHeight}px`
        }
      >
        <Flex direction="column" pt="40px" pb={padding} px={padding} gap="24px">
          <Title>{title}</Title>
          {animationStep == ActionCardAnimationStep.Initial && (
            <Flex minHeight="30px">
              <LoadingDot />
            </Flex>
          )}
          {animationStep >= ActionCardAnimationStep.Subtitle && subtitle && (
            <CardSubtitle
              text={subtitle}
              variant={variant}
              animate={
                animate && animationStep === ActionCardAnimationStep.Subtitle
              }
              onAnimationComplete={incrementAnimationStep}
              onAnimationHeightChange={handleAnimationHeightChange}
            />
          )}
          <ContentTransition
            gap="24px"
            $transitionEnabled={
              animationStep >= ActionCardAnimationStep.Content
            }
          >
            {children}
          </ContentTransition>
        </Flex>
        <ContentTransition
          width="100%"
          $transitionEnabled={
            animationStep >= ActionCardAnimationStep.Content && !!footer
          }
        >
          <Footer>{footer}</Footer>
        </ContentTransition>
      </Card>
    </Container>
  );
};

export default Root;
export const ActionCard = {
  Root,
  Subtitle: CardSubtitle,
};
