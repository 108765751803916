import MarkAvatar from "../core/avatars/MarkAvatar";
import { Flex, Text } from "@radix-ui/themes";
import React from "react";
import styled, { keyframes, css } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedFlex = styled(Flex)<{ $animate?: boolean }>`
  ${(props) =>
    props.$animate &&
    css`
      animation: ${fadeIn} 0.5s ease-out forwards;
    `}
`;

const ChatBubble = styled.div`
  border: 1px solid var(--border-primary);
  border-radius: 6px 24px 24px 24px;
  width: 100%;
  padding: 20px;
  background: var(--primary-white);
  box-shadow: 0px 12px 48px 0px rgba(0, 0, 0, 0.08);
`;

const ChatMessageContainer = ({
  message,
  animate = false,
}: {
  message: string | React.ReactNode;
  animate?: boolean;
}) => {
  return (
    <AnimatedFlex
      direction={"row"}
      gap="8px"
      align={"start"}
      $animate={animate}
    >
      {typeof message === "string" ? (
        <>
          <MarkAvatar />
          <ChatBubble>
            <Text weight="bold" size="4">
              {message}
            </Text>
          </ChatBubble>
        </>
      ) : (
        message
      )}
    </AnimatedFlex>
  );
};

export default ChatMessageContainer;
