import CampaignStatusIndicator from "../../CampaignStatusIndicator";
import { EmailCreativeStatus, AdCreativeStatus } from "@openapi";
import {
  Text,
  ScrollArea,
  IconButton,
  Popover,
  Flex,
  Button,
  TextField,
} from "@radix-ui/themes";
import _ from "lodash";
import { EllipsisVerticalIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { PencilIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import Modal from "~/components/core/dialog/Modal";

const Root = styled.div`
  position: relative;
  border-radius: 16px;
  width: 500px;
  height: 100%;
  display: flex;
  background-color: #fcfbfa;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 32px;
  gap: 8px;
  box-shadow: 0px 2px 8px 0px #0000000a;
  border: 1px solid #f0f0f0;
  border-radius: 16px 16px 0 0;
`;

const ImagePreview = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

const ImageContainer = styled.div`
  position: relative;
  z-index: 0;
`;

const ImageTitle = styled(Text)`
  padding: 0 16px;
`;

const ScrollableContent = styled(ScrollArea)<{ variant: "email" | "ad" }>`
  flex: 1;
  border: ${(props) =>
    props.variant === "email" ? "1px solid #ddd7d7" : "none"};
  border-radius: 16px;
  box-sizing: border-box;
  margin-top: -18px;
`;

const Foreground = styled(Link)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  height: 0;
  width: 100%;
  opacity: 0;
  text-decoration: none;
  transition: opacity 0.3s;
  &:hover {
    opacity: 1;
  }
  overflow: visible;
`;

const ForegroundContent = styled.div`
  background: rgba(0, 0, 0, 0.32);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: start;
  justify-content: center;
`;

const EditButton = styled(AppButton)`
  background-color: var(--primary-white);
  color: var(--primary-deep-purple);
`;

interface PreviewImage {
  title?: string;
  url: string;
}

interface CampaignOverviewCardProps {
  adID?: string;
  title: string;
  subtitle: string;
  count: number;
  previewImages: PreviewImage[];
  status: EmailCreativeStatus | AdCreativeStatus;
  publishedAt: string | null;
  variant: "email" | "ad";
  editPath: string;
  onRename: (newHeadline: string) => void;
  onDuplicate: () => void;
  onDelete: () => void;
  children?: JSX.Element;
}

const CampaignOverviewCard = ({
  adID,
  title: inputTitle,
  count,
  previewImages,
  status,
  publishedAt,
  variant,
  editPath,
  onRename,
  onDuplicate,
  onDelete,
  children,
}: CampaignOverviewCardProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [title, setTitle] = useState(inputTitle);

  useEffect(() => {
    setTitle(inputTitle);
  }, [inputTitle]);

  let statusIndicator = (
    <CampaignStatusIndicator
      status={status}
      publishedAt={publishedAt}
      adID={adID}
    />
  );

  return (
    <Root>
      <Header>
        <Flex justify="between" align="center">
          <Text weight="medium" size="3">
            {variant === "email" ? "Email" : "Ad"} {count}
          </Text>

          <Popover.Root>
            <Popover.Trigger>
              <IconButton radius="large" color="gray" variant="ghost">
                <EllipsisVerticalIcon size={20} />
              </IconButton>
            </Popover.Trigger>
            <Popover.Content sideOffset={5} align="start">
              <Flex direction="column" gap="12px">
                <Popover.Close>
                  <Button
                    size="2"
                    variant="ghost"
                    color="gray"
                    radius="large"
                    onClick={onDuplicate}
                  >
                    <Text align="left" style={{ width: "100%" }}>
                      Duplicate
                    </Text>
                  </Button>
                </Popover.Close>
                <Popover.Close>
                  <Button
                    onClick={() => setIsDeleteModalOpen(true)}
                    disabled={
                      status === EmailCreativeStatus.active ||
                      status === AdCreativeStatus.active
                    }
                    size="2"
                    variant="ghost"
                    color="gray"
                    radius="large"
                  >
                    <Text align="left" style={{ width: "100%" }}>
                      Delete
                    </Text>
                  </Button>
                </Popover.Close>
                <Popover.Close>
                  <Button
                    size="2"
                    variant="ghost"
                    color="gray"
                    radius="large"
                    onClick={() => setIsRenameModalOpen(true)}
                  >
                    <Text align="left" style={{ width: "100%" }}>
                      Rename
                    </Text>
                  </Button>
                </Popover.Close>
              </Flex>
            </Popover.Content>
          </Popover.Root>
          <Modal
            onCancel={() => setIsDeleteModalOpen(false)}
            onSubmit={onDelete}
            submitBtnText="Delete"
            maxWidth="460px"
            open={isDeleteModalOpen}
            onOpenChange={(open) => setIsDeleteModalOpen(open)}
            title={`Delete ${_.capitalize(variant)}`}
          >
            <Flex p="24px">
              <Text>
                Are you sure you want to delete {title}? This action can't be
                undone.
              </Text>
            </Flex>
          </Modal>
          <Modal
            onCancel={() => setIsRenameModalOpen(false)}
            onSubmit={() => onRename(title)}
            submitBtnText="Rename"
            maxWidth="460px"
            open={isRenameModalOpen}
            onOpenChange={(open) => {
              setTitle(inputTitle);
              setIsRenameModalOpen(open);
            }}
            title="Rename"
          >
            <TextField.Root
              type="text"
              size="3"
              radius="large"
              autoFocus
              style={{
                margin: "24px",
                height: "48px",
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Modal>
        </Flex>

        {statusIndicator}
      </Header>
      <ScrollableContent style={{ width: "100%" }} variant={variant}>
        <Flex
          direction="column"
          style={{
            position: "relative",
            height: "100%",
          }}
        >
          <Foreground to={editPath}>
            <ForegroundContent>
              <EditButton radius="large" variant="outlined" mt="8">
                <PencilIcon size={16} />
                <Text size="3">Edit</Text>
              </EditButton>
            </ForegroundContent>
          </Foreground>
          <Flex
            direction="column"
            style={{
              overflowY: "auto",
              height: "100%",
              width: "100%",
              position: "relative",
            }}
          >
            {children
              ? children
              : previewImages.map((image, index) => (
                  <ImageContainer key={index}>
                    {image.title && <ImageTitle>{image.title}</ImageTitle>}
                    <ImagePreview
                      src={image.url}
                      alt={image.title || `Preview ${index + 1}`}
                    />
                  </ImageContainer>
                ))}
          </Flex>
        </Flex>
      </ScrollableContent>
    </Root>
  );
};

export default CampaignOverviewCard;
