import * as Form from "@radix-ui/react-form";
import { Flex } from "@radix-ui/themes";
import React from "react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { ActionCard } from "~/components/core/cards/ActionCard";
import {
  useCampaignWizardNextStep,
  useCampaignWizardPreviousStep,
  useCampaignWizardState,
} from "~/contexts/CampaignWizardContext";
import useCenterCampaignCard from "~/hooks/campaign/useCenterCampaignCard";

export type CampaignWizardCardProps = {
  children?: React.ReactNode;
  badgeTitle: string;
  nextButtonProps: {
    icon?: React.ReactNode;
    onClick?: () => void;
    text?: React.ReactNode | string;
    disabled?: boolean;
    validate?: boolean;
  };
  backButtonProps?: {
    onClick?: () => void;
    hide: boolean;
  };
  additionalButtons?: React.ReactNode;
  hideFooter?: boolean;
  step: number;
} & Pick<
  React.ComponentProps<typeof ActionCard.Root>,
  "subtitle" | "isLoading"
>;

const CampaignWizardCard = ({
  children,
  badgeTitle,
  subtitle,
  nextButtonProps,
  backButtonProps,
  additionalButtons,
  hideFooter,
  step,
  ...props
}: CampaignWizardCardProps) => {
  const previousStep = useCampaignWizardPreviousStep();
  const nextStep = useCampaignWizardNextStep();
  const { currentStep, currentRequiredStep } = useCampaignWizardState();
  const { centerCurrentCardSmooth } = useCenterCampaignCard({
    cardStep: step,
  });

  const handleClickBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    (backButtonProps?.onClick ?? previousStep)();
    e.currentTarget.blur();
  };

  const handleClickNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    (nextButtonProps.onClick ?? nextStep)();
    e.currentTarget.blur();
  };

  const nextButton = (
    <AppButton
      onClick={handleClickNext}
      variant="dark"
      disabled={nextButtonProps.disabled}
    >
      {nextButtonProps.icon}
      {nextButtonProps.text ?? "Looks good"}
    </AppButton>
  );

  const shouldAnimate = currentRequiredStep === step && currentStep === step;

  return (
    <Flex overflowX="hidden" overflowY="auto" p="32px" maxHeight="100vh">
      {currentRequiredStep >= step && (
        <ActionCard.Root
          title={badgeTitle}
          subtitle={subtitle ?? ""}
          variant="compact"
          footer={
            !hideFooter && (
              <>
                {!backButtonProps?.hide && (
                  <AppButton onClick={handleClickBack} variant="outlined">
                    Back
                  </AppButton>
                )}
                {additionalButtons}
                {nextButtonProps.validate ? (
                  <Form.Submit asChild>{nextButton}</Form.Submit>
                ) : (
                  nextButton
                )}
              </>
            )
          }
          animate={shouldAnimate}
          animationDelay={300}
          onAnimationHeightChange={centerCurrentCardSmooth}
          onAnimationStepChange={centerCurrentCardSmooth}
          {...props}
        >
          {children}
        </ActionCard.Root>
      )}
    </Flex>
  );
};

export default CampaignWizardCard;
