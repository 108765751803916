import { operations } from "../../../openapi";
import { useBrandUsersQueryDataPatch } from "../brand/useBrandUsersQuery";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { toast } from "sonner";

type InviteUserRequestData =
  operations["user_api_invite_user"]["requestBody"]["content"]["application/json"];
type InviteUserResponseData =
  operations["user_api_invite_user"]["responses"][200]["content"]["application/json"];

const useInviteUserMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}) => {
  const patchBrandUsersQuery = useBrandUsersQueryDataPatch();
  return useMutation<
    InviteUserResponseData,
    AxiosError | Error,
    InviteUserRequestData
  >({
    mutationFn: async (params) => {
      const response = await axios.post(`/api/v1/user/invite`, params, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken") ?? "",
        },
      });
      return response.data;
    },
    onError: (error) => {
      toast.error("Failed to send email invite");
      onError?.(error);
    },
    onSuccess: (data, { brand_id, email }) => {
      toast.success(`Invitation sent to ${email}`);
      patchBrandUsersQuery({
        brandId: brand_id,
        patchData: {
          upsertUsers: [data],
        },
      });
      onSuccess?.();
    },
  });
};

export default useInviteUserMutation;
