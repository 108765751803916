import { Avatar, AvatarProps } from "@radix-ui/themes";
import markPng from "assets/mark.png";

type MarkAvatarProps = Omit<AvatarProps, "fallback"> & {
  fallback?: AvatarProps["fallback"];
};
const MarkAvatar = (props: MarkAvatarProps) => {
  return (
    <Avatar src={markPng} radius="full" size="3" fallback="M" {...props} />
  );
};

export default MarkAvatar;
