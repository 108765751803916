import { RelatedAssetItem } from "~/components/core/inputs/ImageSelector";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useShopifyProductQuery from "~/hooks/shopify/useShopifyProductQuery";

const useGetProductRelatedImages = (productId?: string | null) => {
  const activeBrandID = useActiveBrandID();

  const { productData, isLoading, error } = useShopifyProductQuery(
    activeBrandID,
    productId
  );
  const relatedAssets: RelatedAssetItem[] =
    productData?.image_urls?.map((url) => ({
      url,
      commerceId: productData.product_id ?? undefined,
    })) ?? [];

  return { images: relatedAssets, isLoading: isLoading, error: error };
};
export default useGetProductRelatedImages;
