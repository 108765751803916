import ImageContainer from "../ads/previews/ImageContainer";
import { Text, Flex } from "@radix-ui/themes";
import { MailOpen } from "lucide-react";
import styled from "styled-components";
import { CampaignEmailData } from "~/hooks/campaign/useGetCampaignDetails";

const Root = styled(Flex)`
  border-radius: 16px;
  box-shadow: 0px 2px 8px 0px #0000000a;
  background: white;
`;

const ImagePreview = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

const ContentTitle = styled(Text)`
  font-weight: 500;
  font-size: 16px;
`;

const ContentSubtitle = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  color: #4c4747;
`;

const CampaignOverviewEmailContent = ({
  email,
}: {
  email: CampaignEmailData;
}) => (
  <Root direction="column">
    <Flex direction="column" gap="1" p="3">
      <Flex align={"center"} gap="2">
        <MailOpen size="16px" color="#595D62" />
        <ContentTitle>{email.title}</ContentTitle>
      </Flex>
      <ContentSubtitle>{email.subtitle}</ContentSubtitle>
    </Flex>
    <ImageContainer>
      <ImagePreview src={email.preview_image ?? undefined} alt={email.title} />
    </ImageContainer>
  </Root>
);
export default CampaignOverviewEmailContent;
