import AdMediaSVGCanvas from "../../AdMediaSVGCanvas";
import classes from "./AdPreview.module.css";
import AdCard from "./ad_cards/AdCard";
import FacebookAdCard from "./ad_cards/facebook/FacebookAdCard";
import InstagramAdCard from "./ad_cards/instagram/InstagramAdCard";
import StoryAdCard from "./ad_cards/story/StoryAdCard";
import { AdMediaAspectRatio } from "@openapi";
import { Dialog, Flex, ScrollArea } from "@radix-ui/themes";
import { XIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useAdEditorSelectedAdMediaState } from "~/contexts/AdEditorContext";

export type PreviewCardProps = {
  brandName?: string;
  primaryText?: string;
  headline?: string;
  destination?: string;
  content: JSX.Element;
  style?: React.CSSProperties;
};
const AdPreview = ({ isDisabled }: { isDisabled: boolean }) => {
  const { ad: adData, media } = useAdEditorSelectedAdMediaState();

  const previewContent = (ratio: AdMediaAspectRatio) => {
    const mediaUrl = media?.variants?.find(
      (variant) => variant.aspect_ratio === ratio
    )?.file;
    return (
      <AdMediaSVGCanvas
        svgUrl={mediaUrl}
        customStyle={{ borderRadius: "0px" }}
      />
    );
  };

  const previewAdProps = (ratio: AdMediaAspectRatio) => ({
    brandName: adData?.data.brand_name,
    destination: adData?.data.campaign.destination,
    content: previewContent(ratio),
    primaryText: adData?.data.primary_text,
    headline: adData?.data.headline,
  });

  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <AppButton variant="outlined" disabled={isDisabled}>
          Preview
        </AppButton>
      </Dialog.Trigger>

      <Dialog.Content className={classes.content}>
        <Flex
          className={classes.header}
          direction="row"
          justify="between"
          align="center"
        >
          <Dialog.Title style={{ alignContent: "center", marginBottom: 0 }}>
            Preview
          </Dialog.Title>

          <Dialog.Close>
            <AppButton variant="outlined">
              <XIcon color="#595D62" width="18px" height="18px" /> Close
            </AppButton>
          </Dialog.Close>
        </Flex>

        <ScrollArea className={classes.inner_content}>
          <Flex className={classes.previews_container} gap="32px">
            <AdCard title="Facebook (Square)">
              <FacebookAdCard {...previewAdProps(AdMediaAspectRatio.square)} />
            </AdCard>
            <AdCard title="Instagram (Square)">
              <InstagramAdCard {...previewAdProps(AdMediaAspectRatio.square)} />
            </AdCard>
            <AdCard title="Story (Square)">
              <StoryAdCard
                {...previewAdProps(AdMediaAspectRatio.full_screen_vertical)}
              />
            </AdCard>
          </Flex>
        </ScrollArea>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default AdPreview;
