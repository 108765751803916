import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type AdSetFormDataResponse =
  operations["meta_api_get_adset_form_data"]["responses"]["200"]["content"]["application/json"];

const useMetaAdSetFormQuery = (metaAdAccountId: string | null) => {
  const {
    data: formData,
    isLoading,
    isSuccess,
  } = useQuery<AdSetFormDataResponse>({
    queryKey: ["adset-form-data", metaAdAccountId],
    queryFn: async (): Promise<AdSetFormDataResponse> => {
      const { data } = await axios.get(
        `/api/v1/meta/adset-form-data?meta_ad_account_id=${metaAdAccountId}`
      );
      return data;
    },
    enabled: !!metaAdAccountId,
  });

  return {
    formData,
    isLoading,
    isSuccess,
  };
};

export default useMetaAdSetFormQuery;
