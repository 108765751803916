import HomeTopBar from "./visuals/HomeTopBar";
import { Flex } from "@radix-ui/themes";
import { Outlet } from "react-router-dom";
import BackgroundGradient from "~/components/core/layout/BackgroundGradient";

export default function HomeRoute() {
  return (
    <Flex
      gap="6"
      direction="column"
      maxWidth="1248px"
      minWidth="680px"
      width="100%"
      style={{
        padding: "0px 24px 24px 24px",
        alignItems: "center",
        alignContent: "center",
        margin: "0 auto",
      }}
    >
      <BackgroundGradient />
      <HomeTopBar />
      <Outlet />
    </Flex>
  );
}
