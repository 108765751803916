import { AccountSettingsSearchParam } from "./AccountSettings";
import { Dialog, Flex, Grid, IconButton, Text } from "@radix-ui/themes";
import React, { Dispatch, SetStateAction } from "react";
import { useSearchParams } from "react-router-dom";
import { Toaster } from "sonner";
import { XIcon } from "~/assets/icons";

const AccountSettingsDialog = ({
  trigger,
  children,
  isOpen,
  setIsOpen,
}: {
  trigger: React.ReactNode;
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      setSearchParams((prev) => {
        prev.delete(AccountSettingsSearchParam);
        return prev;
      });
    }
    setIsOpen(isOpen);
  };
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>
      <Dialog.Content
        maxWidth="70vw"
        style={{ backgroundColor: "rgba(252, 251, 250, 1)", paddingBottom: 0 }}
      >
        <Toaster richColors />
        <Flex mt="4px" mb="27px" justify="between" align="center">
          <Text size="6" weight="medium">
            My Account
          </Text>
          <Dialog.Close>
            <IconButton variant="ghost" radius="large">
              <XIcon color="var(--icon-primary)" />
            </IconButton>
          </Dialog.Close>
        </Flex>
        <Grid columns="1fr 2fr" gap="24px" height="70vh">
          {children}
        </Grid>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default AccountSettingsDialog;
