import HomeSegmentControl from "./HomeSegmentControl";
import { Flex } from "@radix-ui/themes";
import styled from "styled-components";
import TopRightMenu from "~/components/home/components/top-right-menu/TopRightMenu";
import { useMarkPopupContext } from "~/contexts/MarkPopupContext";

const Container = styled(Flex)`
  transition: all 0.5s;
`;
const HomeTopBar = () => {
  const { state } = useMarkPopupContext();
  return (
    <Container
      mt={"32px"}
      mb={state.markPopupOpen ? "100px" : "0px"}
      justify={"between"}
      align={"center"}
      position={"relative"}
      width={"100%"}
    >
      <HomeSegmentControl />
      <TopRightMenu />
    </Container>
  );
};
export default HomeTopBar;
