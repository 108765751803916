import React, { RefObject, useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div<{ $width?: number; $height?: number }>`
  width: ${(props) => props.$width ?? 0}px;
  height: ${(props) => props.$height ?? 0}px;
  opacity: ${(props) => (props.$height ? "1" : "0")}px;
  overflow: hidden;
  transition: all 0.6s;
`;

type ContainerDefaultStyles = {
  width: number;
  height: number;
  borderRadius: React.CSSProperties["borderRadius"];
  position: React.CSSProperties["position"];
};

const GrowingAnimation = ({
  children,
  elementRef,
  top,
  left,
  style,
}: {
  children: JSX.Element;
  elementRef: RefObject<HTMLDivElement>;
  top?: number;
  left?: number;
  style?: React.CSSProperties;
}) => {
  const [defaultStyles, setDefaultStyles] =
    useState<ContainerDefaultStyles | null>(null);
  useEffect(() => {
    if (elementRef.current) {
      const styles = window.getComputedStyle(elementRef.current);
      const position = elementRef.current.getBoundingClientRect();
      setDefaultStyles({
        borderRadius: styles.borderRadius,
        position: styles.position as React.CSSProperties["position"],
        width: position.width,
        height: position.height,
      });
    }
  }, [elementRef]);
  return (
    <Container
      style={{
        position: defaultStyles?.position,
        top: top,
        left: left,
        borderRadius: defaultStyles?.borderRadius,
        ...style,
      }}
      $width={defaultStyles?.width}
      $height={defaultStyles?.height}
    >
      {children}
    </Container>
  );
};
export default GrowingAnimation;
