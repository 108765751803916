import { useEffect, useState } from "react";
import { getFileSizeFromUrl, getImageMetaFromUrl } from "~/utils/helpers";

type Size = {
  width: number;
  height: number;
};

const useGetImageDimensionsAndSize = (img?: string) => {
  const [dimensions, setDimensions] = useState<string>("");
  const [size, setSize] = useState<Size | undefined>(undefined);

  useEffect(() => {
    const fetchDimensions = async () => {
      if (!img) return;
      const meta = await getImageMetaFromUrl(img);
      const size = await getFileSizeFromUrl(img);
      if (!meta || !size) return;
      setSize({ width: meta.naturalWidth, height: meta.naturalHeight });
      setDimensions(
        `${size} kb, ${meta.naturalWidth} x ${meta.naturalHeight} px`
      );
    };
    fetchDimensions();
  }, [img]);

  return { dimensions, size };
};

export default useGetImageDimensionsAndSize;
