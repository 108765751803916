import PreviewEmailDialog from "../dialogs/PreviewEmailDialog";
import SendTestEmailDialog from "../dialogs/SendTestEmailDialog";
import * as Popover from "@radix-ui/react-popover";
import { Flex, SegmentedControl, Select, Text, Theme } from "@radix-ui/themes";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  CheckIcon,
  ChevronDownIcon,
  MonitorIcon,
  SmartphoneIcon,
} from "~/assets/icons";
import DraperSegmentControl from "~/components/core/DraperSegmentedControl";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import EditorTopBar from "~/components/core/editor/layout/EditorTopBar";
import PublishCreativeButton from "~/components/core/editor/layout/top-bar/PublishCreativeButton";
import useGetCampaignDetails from "~/hooks/campaign/useGetCampaignDetails";
import { CAMPAIGNS_ROUTE } from "~/routes/constants";
import {
  useEmailState,
  useSetActiveEmailId,
  useSetDeviceView,
  useSetIsEmailDirty,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import useUpdateEmailCreative from "~/routes/intern/email_editor/hooks/useUpdateEmailCreative";
import usePreventDataLoss from "~/utils/usePreventDataLoss";

const SelectTrigger = styled(Select.Trigger)`
  font-size: 18px;
  font-weight: 500;
`;

const PopoverContent = styled(Popover.Content)`
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  width: 150px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd7d7;
`;

const MenuItem = styled(Flex)`
  cursor: pointer;
  padding: 4px;

  &:hover {
    background-color: #f0f0f0;
    border-radius: 8px;
  }
`;

const EmailEditorTopBar = () => {
  const { campaignId } = useParams();
  const email = useEmailState();
  const setDeviceView = useSetDeviceView();
  const setActiveEmailId = useSetActiveEmailId();
  const setIsDirty = useSetIsEmailDirty();

  const [previewEmailDialogOpen, setPreviewEmailDialogOpen] = useState(false);
  const [isSendTestDialogOpen, setIsSendTestDialogOpen] = useState(false);

  // Mutations and Queries
  const { data: campaignDetail, isLoading: isCreativesLoading } =
    useGetCampaignDetails({ campaignId: campaignId });
  const creativesData = campaignDetail?.email_creatives ?? [];
  const variant = email.variants[email.currentVariantIndex];
  const updateEmailCreativeMutation = useUpdateEmailCreative({
    onSuccess: () => {
      saveModalOnSucceed();
      setIsDirty(false);
    },
    onError: () => {
      saveModalOnFail();
    },
  });

  const mutate = useCallback(() => {
    // small delay so that the state is updated for blurred elements before the mutation is called
    setTimeout(() => {
      updateEmailCreativeMutation.mutate({
        id: email.id,
        variant_id: email.variants[email.currentVariantIndex]?.id ?? "",
        name: email.name,
        subtitle: email.subtitle,
        description: email.description,
        audiences: email.audiences,
        excluded_audiences: email.excluded_audiences,
        sections: variant?.sections // Manually spread because we have to update AND create
          ?.filter((section) => section.type !== null)
          ?.map((section, index) => {
            return {
              ...section,
              id: section.id.startsWith("new-section-") ? "" : section.id, // set empty string for new sections
              index: index,
            };
          })
          ?.filter(Boolean),
        campaignId,
      });
    }, 50);
  }, [updateEmailCreativeMutation]);

  const selectEmailComponent = creativesData.length > 0 && (
    <Select.Root
      onValueChange={setActiveEmailId}
      defaultValue={creativesData[0].id}
      size="2"
    >
      <SelectTrigger
        disabled={isCreativesLoading}
        variant="ghost"
        color="gray"
      />
      <Select.Content color="gray" highContrast position="popper">
        {creativesData.map((creative) => (
          <Select.Item key={creative.id} value={creative.id}>
            <Text size={"4"} weight={"medium"}>
              {creative.title}
            </Text>
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );

  //navigation blocker for unsaved state
  const {
    component: saveStateModal,
    onSucceed: saveModalOnSucceed,
    onFail: saveModalOnFail,
  } = usePreventDataLoss({
    enabled: email.isDirty,
    onProceed: mutate,
    isLoading: updateEmailCreativeMutation.isPending,
    description:
      "You have made some changes to the email, would you like to save them\
        before closing this email ?",
  });

  const previewTestDropdown = (
    <Flex gap="12px">
      <Popover.Root>
        <Popover.Trigger asChild>
          <AppButton variant="outlined">
            Actions
            <ChevronDownIcon strokeWidth={1.5} size={20} />
          </AppButton>
        </Popover.Trigger>
        <Popover.Portal>
          <Theme>
            <PopoverContent sideOffset={5} align="start">
              <Flex direction={"column"} gap="2">
                <MenuItem
                  onClick={() => {
                    setPreviewEmailDialogOpen(true);
                  }}
                >
                  <Text>Preview</Text>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsSendTestDialogOpen(true);
                  }}
                >
                  <Text>Send Test</Text>
                </MenuItem>
              </Flex>
            </PopoverContent>
          </Theme>
        </Popover.Portal>
      </Popover.Root>

      {email.published_at ? (
        <AppButton
          variant="outlined"
          style={{ background: "var(--primary-white)" }}
          disabled
        >
          <CheckIcon /> Published
        </AppButton>
      ) : (
        <>
          <PublishCreativeButton
            campaignId={campaignId ?? null}
            isDisabled={updateEmailCreativeMutation.isPending || email.isDirty}
            preselectedCreativeIds={{
              emails: [email.id],
            }}
          />
          <AppButton
            variant="primary"
            radius="full"
            onClick={mutate}
            disabled={updateEmailCreativeMutation.isPending || !email.isDirty}
          >
            {updateEmailCreativeMutation.isPending ? "Saving..." : "Save"}
          </AppButton>
        </>
      )}
    </Flex>
  );

  return (
    <>
      <EditorTopBar
        backButtonDestination={CAMPAIGNS_ROUTE}
        left={
          (creativesData.length ?? 0) > 1 ? (
            selectEmailComponent
          ) : (
            <Text size={"4"} weight={"medium"}>
              {creativesData?.[0]?.title}
            </Text>
          )
        }
        center={
          // <DraperSegmentControl
          //   style={{ paddingTop: "4px" }}
          //   size="3"
          //   defaultValue={email.deviceView}
          //   onValueChange={setDeviceView}
          // >
          //   <SegmentedControl.Item value="desktop">
          //     <MonitorIcon />
          //   </SegmentedControl.Item>
          //   <SegmentedControl.Item value="mobile">
          //     <SmartphoneIcon />
          //   </SegmentedControl.Item>
          // </DraperSegmentControl>
          <></>
        }
        right={previewTestDropdown}
      />

      <PreviewEmailDialog
        open={previewEmailDialogOpen}
        onOpenChange={setPreviewEmailDialogOpen}
      />
      <SendTestEmailDialog
        open={isSendTestDialogOpen}
        onOpenChange={setIsSendTestDialogOpen}
      />
      {saveStateModal}
    </>
  );
};

export default EmailEditorTopBar;
