import { useEffect, useState } from "react";
import ExploreLayoutVariantsSection from "~/components/core/editor/sidepane/ExploreLayoutVariantsSection";
import {
  useActiveBrandID,
  useCurrentUserState,
} from "~/contexts/CurrentUserContext";
import useEmailSectionTemplatesQuery from "~/hooks/emails/useEmailSectionTemplatesQuery";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { isNewSection } from "~/utils/emails/useSectionTypeCheck";

const GeneratedVariantSelector = ({
  title = "Explore layout variants",
}: {
  title?: string;
}) => {
  const { user } = useCurrentUserState();
  const isInternal = user.status === "LOGGED_IN" && user.isInternal;

  const updateSection = useUpdateSectionField();

  const { selectedSectionId, sections } = useEmailState();
  const section = sections.find((section) => section.id === selectedSectionId);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { templates, error, isLoading } = useEmailSectionTemplatesQuery(
    useActiveBrandID(),
    section?.type ?? null
  );

  const isAllDisabled =
    templates?.length <= 1 || isLoading || !section || error !== null;

  const isPreviousDisabled = currentIndex === 0 || isAllDisabled;
  const isNextDisabled =
    currentIndex === templates?.length - 1 || isAllDisabled;

  useEffect(() => {
    if (!section) {
      return;
    }
    if (isNewSection(section)) {
      return;
    }
    const activeTemplate = templates.findIndex(
      (template) => template.id === section?.template.id
    );

    if (activeTemplate !== -1) {
      setCurrentIndex(activeTemplate);
    }
  }, [section, templates]);

  const handlePrevious = () => {
    updateSection({
      sectionId: selectedSectionId,
      field: "template",
      value: templates[currentIndex - 1],
    });
    setCurrentIndex(currentIndex - 1);
  };

  const handleNext = () => {
    updateSection({
      sectionId: selectedSectionId,
      field: "template",
      value: templates[currentIndex + 1],
    });
    setCurrentIndex(currentIndex + 1);
  };

  return (
    <ExploreLayoutVariantsSection
      title={isInternal ? `${title} (${templates[currentIndex]?.name})` : title}
      isPreviousDisabled={isPreviousDisabled}
      isNextDisabled={isNextDisabled}
      onPrevious={handlePrevious}
      onNext={handleNext}
    />
  );
};

export default GeneratedVariantSelector;
