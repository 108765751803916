import { operations } from "@openapi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useCallback, useRef } from "react";
import { useAuthenticatedUserState } from "~/contexts/CurrentUserContext";
import { useDraperApiPatchMutation } from "~/utils/useDraperMutation";

export type OnboardedStatus =
  operations["user_api_get_user_onboarded"]["responses"]["200"]["content"]["application/json"];
export type OnboardedKeys = keyof OnboardedStatus;

const userOnboardedPath = "/api/v1/user/onboarded";
const useGetUserOnboardedQuery = () => {
  const { id } = useAuthenticatedUserState();
  const { data, isLoading, error } = useQuery({
    queryKey: [userOnboardedPath, id],
    queryFn: async (): Promise<OnboardedStatus> => {
      const response = await axios.get(userOnboardedPath);
      return response.data;
    },
  });
  return { data, isLoading, error };
};

type UpdateOnboardedRequestData =
  operations["user_api_patch_user_onboarded"]["requestBody"]["content"]["application/json"];
type UpdateOnboardedResponse =
  operations["user_api_patch_user_onboarded"]["responses"]["200"]["content"]["application/json"];

const useSetUserOnboarded = () => {
  const { id } = useAuthenticatedUserState();
  const queryClient = useQueryClient();
  const updateBrand = useDraperApiPatchMutation<
    UpdateOnboardedResponse,
    UpdateOnboardedRequestData
  >({
    mutationKey: [userOnboardedPath, id],
    path: `/user/onboarded`,
    onError: (error) => {
      console.log(`Error updating onboarding: ${error}`);
    },
    onSuccess: (data) => {
      queryClient.setQueryData([userOnboardedPath, id], data.data);
    },
  });
  return updateBrand;
};

const useUserOnboarding = () => {
  const { data, isLoading, error } = useGetUserOnboardedQuery();
  const updateOnboarded = useSetUserOnboarded();
  const shouldDisplay = useCallback(
    (onboardedKey?: OnboardedKeys) => {
      if (!data || !onboardedKey) {
        return false;
      }
      return !data[onboardedKey];
    },
    [data]
  );
  const setOnboarded = useCallback(
    (onboardedKey: OnboardedKeys, status: boolean) => {
      updateOnboarded.mutate({
        onboarded_key: onboardedKey,
        status: status,
      });
    },
    [updateOnboarded]
  );
  return { shouldDisplay, setOnboarded };
};
export default useUserOnboarding;
