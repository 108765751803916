import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";

export type GenerateImageStatusRequestData =
  operations["media_api_ai_generate_image_status"]["requestBody"]["content"]["application/json"];

export type GenerateImageStatusResponse =
  operations["media_api_ai_generate_image_status"]["responses"][200]["content"]["application/json"] & {
    error?: string;
  };

export type useGenerateMediaImageStatusProps = {
  brandId: string;
  jobSessionId: string | null;
};

const useGenerateMediaImageStatus = ({
  brandId,
  jobSessionId,
}: useGenerateMediaImageStatusProps) => {
  const { data } = useQuery<GenerateImageStatusResponse>({
    queryKey: ["image-ai-generation-status", brandId, jobSessionId],
    queryFn: async (): Promise<GenerateImageStatusResponse> => {
      const requestBody: GenerateImageStatusRequestData = {
        brand_id: brandId,
        job_session_id: jobSessionId!,
      };
      const response = await axios
        .post("/api/v1/media/images/ai-generate/status", requestBody, {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        })
        .catch((error) => {
          console.log("Error in fetching image generation status", error);
          return {
            data: {
              is_finished: true,
              error: "Failed to generate image",
              image_asset: null,
            },
          };
        });
      return response.data;
    },
    enabled: jobSessionId !== null,
    refetchInterval: 3000,
    retry: false,
    staleTime: Infinity,
  });
  return data;
};

export default useGenerateMediaImageStatus;
