import OnboardingOverlayCard, {
  OnboardingCardProps,
} from "./card/OnboardingOverlayCard";
import OnboardingPulse from "./pulse/OnboardingPulse";
import { useIsFetching } from "@tanstack/react-query";
import { debounce } from "lodash";
import { RefObject, useCallback, useEffect, useState } from "react";
import GlassmorphicOverlay from "~/components/common/GlassmorphicOverlay";
import useUserOnboarding, {
  OnboardedKeys,
} from "~/hooks/common/useUserOnboarding";

const OnboardingOverlay = ({
  children,
  pulseElementRef,
  pulseComponent,
  onboardedKey,
  topRelativeToElement,
  leftRelativeToElement,
  cardProps,
}: {
  children:
    | JSX.Element
    | JSX.Element[] /** Add z-index: 1 to children that should be visible */;
  pulseElementRef: RefObject<HTMLDivElement>;
  onboardedKey?: OnboardedKeys;
  pulseComponent: JSX.Element;
  topRelativeToElement?: number;
  leftRelativeToElement?: number;
  cardProps: Omit<OnboardingCardProps, "top" | "left">;
}) => {
  const { shouldDisplay, setOnboarded } = useUserOnboarding();
  const [debounceDisplay, setDebounceDisplay] = useState(false);
  const enabled = shouldDisplay(onboardedKey);
  const isFetching = useIsFetching();

  useEffect(() => {
    display();
  }, [enabled, isFetching]);

  const display = useCallback(
    debounce(() => {
      setDebounceDisplay(enabled && !isFetching);
    }, 400),
    [enabled, isFetching, setDebounceDisplay, debounce]
  );

  //setting position of card
  const [cardPosition, setCardPosition] = useState<null | {
    top: number;
    left: number;
  }>(null);
  useEffect(() => {
    if (!pulseElementRef.current || !debounceDisplay) return;
    const pos = pulseElementRef.current.getBoundingClientRect();
    setCardPosition({ top: pos.top, left: pos.left });
  }, [pulseElementRef, setCardPosition, debounceDisplay]);

  //actions
  const handleProceed = useCallback(() => {
    onboardedKey && setOnboarded(onboardedKey, true);
    cardProps.onProceed?.();
  }, [setOnboarded]);
  const handleCancel = useCallback(() => {
    onboardedKey && setOnboarded(onboardedKey, true);
    cardProps.onCancel?.();
  }, [setOnboarded]);

  return (
    <>
      <GlassmorphicOverlay enabled={debounceDisplay} />
      {!!cardPosition && debounceDisplay && (
        <OnboardingOverlayCard
          {...cardProps}
          top={cardPosition.top + (topRelativeToElement ?? 0)}
          left={cardPosition.left + (leftRelativeToElement ?? 0)}
          onProceed={handleProceed}
          onCancel={handleCancel}
        />
      )}
      <OnboardingPulse pulseStyle={pulseElementRef} enabled={debounceDisplay}>
        {pulseComponent}
      </OnboardingPulse>
      {children}
    </>
  );
};
export default OnboardingOverlay;
