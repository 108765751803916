import MarkAnnouncement from "./MarkAnnouncement";
import { Flex } from "@radix-ui/themes";
import { Plus } from "lucide-react";
import { Link } from "react-router-dom";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const TopRightMenu: React.FC = () => {
  return (
    <Flex gap="4">
      <Link
        to="/campaigns/wizard/new"
        style={{
          pointerEvents: "auto",
        }}
      >
        <AppButton>
          <Plus />
          New Campaign
        </AppButton>
      </Link>
      <MarkAnnouncement />
    </Flex>
  );
};

export default TopRightMenu;
