import { AdCreativeStatus, components, EmailCreativeStatus } from "@openapi";

export const getUnpublishedCreatives = (
  campaign: components["schemas"]["CampaignSchema"]
) => {
  return {
    emails: campaign.email_creatives.filter(
      ({ status }) =>
        status === EmailCreativeStatus.draft ||
        status === EmailCreativeStatus.unpublished
    ),
    ads: campaign.ad_creatives.filter(
      ({ status }) => status === AdCreativeStatus.unpublished
    ),
  };
};

export const getPublishedCreatives = (
  campaign: components["schemas"]["CampaignSchema"]
) => {
  return {
    emails: campaign.email_creatives.filter(
      ({ status }) =>
        status !== EmailCreativeStatus.draft &&
        status !== EmailCreativeStatus.unpublished
    ),
    ads: campaign.ad_creatives.filter(
      ({ status }) => status !== AdCreativeStatus.unpublished
    ),
  };
};

export const areAllCreativesPublished = (
  campaign: components["schemas"]["CampaignSchema"]
) => {
  return (
    getPublishedCreatives(campaign).emails.length ===
      campaign.email_creatives.length &&
    getPublishedCreatives(campaign).ads.length === campaign.ad_creatives.length
  );
};
