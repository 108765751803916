/**
 * Icons from https://www.figma.com/design/sQBAK14K5BlBBmnLEDa8oX/Icons?node-id=36-77&node-type=frame&t=CmPEkakWg4resfB1-0
 * Naming is kept from Lucide icons
 */
import {
  createElement,
  forwardRef,
  RefAttributes,
  Suspense,
  SVGProps,
} from "react";
import { lazy as _lazy } from "react";

type ReactComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & { title?: string }
>;

// inherit some defaults, props and logic from Lucide icons
const defaultAttributes = {
  xmlns: "http://www.w3.org/2000/svg",
  width: 24,
  height: 24,
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 2,
  strokeLinecap: "round",
  strokeLinejoin: "round",
} as const;

type SVGAttributes = Partial<SVGProps<SVGSVGElement>>;
type ElementAttributes = RefAttributes<SVGSVGElement> & SVGAttributes;
interface IconProps extends ElementAttributes {
  size?: string | number;
  absoluteStrokeWidth?: boolean;
  ignoreDefaultAttributes?: boolean;
}

type IconComponentProps = IconProps & {
  baseIcon: ReactComponent;
};

const Icon = forwardRef<SVGSVGElement, IconComponentProps>(
  (
    {
      baseIcon: BaseIcon,
      size = 24,
      strokeWidth: inputStrokeWidth,
      absoluteStrokeWidth,
      color = "currentColor",
      ignoreDefaultAttributes = false,
      children,
      ...rest
    },
    ref
  ) => {
    const defaultStrokeWidth = ignoreDefaultAttributes
      ? undefined
      : defaultAttributes.strokeWidth;
    const strokeWidth = inputStrokeWidth ?? defaultStrokeWidth;
    return (
      <BaseIcon
        {...(ignoreDefaultAttributes ? {} : defaultAttributes)}
        width={size}
        height={size}
        stroke={color}
        strokeWidth={
          absoluteStrokeWidth
            ? (Number(strokeWidth) * 24) / Number(size)
            : strokeWidth
        }
        {...rest}
        ref={ref}
      >
        {children}
      </BaseIcon>
    );
  }
);

function lazy(importFn: Function) {
  return _lazy(async () => {
    const m = await importFn();

    return { default: m.ReactComponent };
  });
}

function createIcon(importFn: Function, defaultProps?: IconProps) {
  const LazyIcon = lazy(importFn);

  return forwardRef<SVGSVGElement, IconProps>((props, ref) =>
    createElement(
      Suspense,
      { fallback: null },
      createElement(Icon, {
        ref,
        baseIcon: LazyIcon,
        ...defaultProps,
        ...props,
      })
    )
  );
}

export type IconType = typeof Icon;
export const ArrowRightIcon = createIcon(() => import("./arrow-right.svg"));
export const ArrowLeftIcon = createIcon(() => import("./arrow-left.svg"));
export const PackageOpenIcon = createIcon(() => import("./package-open.svg"));
export const PackageBoxIcon = createIcon(() => import("./package-box.svg"));
export const CheckIcon = createIcon(() => import("./check.svg"));
export const ChevronDownIcon = createIcon(() => import("./chevron-down.svg"));
export const ChevronUpIcon = createIcon(() => import("./chevron-down.svg"), {
  style: { transform: "rotate(180deg)" },
});
export const ChevronLeftIcon = createIcon(() => import("./chevron-left.svg"));
export const ChevronRightIcon = createIcon(() => import("./chevron-right.svg"));
export const XIcon = createIcon(() => import("./x.svg"));
export const TicketPercentIcon = createIcon(
  () => import("./ticket-percent.svg")
);
export const Calendar1Icon = createIcon(() => import("./calendar-1.svg"));
export const CropIcon = createIcon(() => import("./crop.svg"));
export const BookCheckIcon = createIcon(() => import("./book-check.svg"));
export const BadgePercentIcon = createIcon(() => import("./badge-percent.svg"));
export const Trash2Icon = createIcon(() => import("./trash-2.svg"));
export const LayoutDashboardIcon = createIcon(
  () => import("./layout-dashboard.svg")
);
export const DiscountIcon = createIcon(() => import("./discount.svg"));
export const DownloadIcon = createIcon(() => import("./download.svg"));
export const MonitorIcon = createIcon(() => import("./monitor.svg"));
export const MailIcon = createIcon(() => import("./mail.svg"));
export const PencilIcon = createIcon(() => import("./pencil.svg"));
export const EyeIcon = createIcon(() => import("./eye.svg"));
export const EyeOffIcon = createIcon(() => import("./eye-off.svg"));
export const HexagonIcon = createIcon(() => import("./hexagon.svg"));
export const ImageIcon = createIcon(() => import("./image.svg"));
export const BellDotIcon = createIcon(() => import("./bell-dot.svg"));
export const MessageSquareTextIcon = createIcon(
  () => import("./message-square-text.svg")
);
/** This uses a fill instead of stroke, so passing color and stroke related properties will not work well */
export const MetaIcon = createIcon(() => import("./meta.svg"), {
  color: "none",
  stroke: "none",
  fill: "currentColor",
});
export const MinusIcon = createIcon(() => import("./minus.svg"));
export const PlusIcon = createIcon(() => import("./plus.svg"));
export const SmartphoneIcon = createIcon(() => import("./smartphone.svg"));
export const RefreshCcwIcon = createIcon(() => import("./refresh-ccw.svg"));
export const SparkleIcon = createIcon(() => import("./sparkle.svg"));
export const SparklesIcon = createIcon(() => import("./sparkles.svg"));
export const LoaderIcon = createIcon(() => import("./loader.svg"));
export const StylesIcon = createIcon(() => import("./styles.svg"));
export const SendIcon = createIcon(() => import("./send.svg"));
export const UploadIcon = createIcon(() => import("./upload.svg"));
export const UserIcon = createIcon(() => import("./user.svg"));
export const LikeIcon = createIcon(() => import("./like.svg"));
export const DislikeIcon = createIcon(() => import("./dislike.svg"));
export const HornIcon = createIcon(() => import("./horn.svg"));
export const ShoppingBagClickIcon = createIcon(
  () => import("./shopping-bag-cursor-click.svg")
);
export const StarSparksIcon = createIcon(() => import("./star-sparks.svg"));
export const StorageBoxesIcon = createIcon(() => import("./storage-boxes.svg"));
export const LightBulbDots = createIcon(() => import("./bulb-light-dots.svg"));
export const HeartSparksIcon = createIcon(() => import("./heart-sparks.svg"));
export const TargetGoalIcon = createIcon(() => import("./target-goal.svg"));
export const TimeBackIcon = createIcon(() => import("./time-back.svg"));
export const QuestionMarkCircledIcon = createIcon(
  () => import("./question-mark-circled.svg"),
  {
    ignoreDefaultAttributes: true,
    color: "inherit",
    style: { overflow: "visible" },
  }
);

export const WandSparklesIcon = createIcon(() => import("./wand-sparkles.svg"));
