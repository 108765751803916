import { PreviewCardProps } from "../../AdPreview";
import classes from "./FacebookAdCard.module.css";
import { Flex, Text } from "@radix-ui/themes";
import {
  Earth,
  Ellipsis,
  Forward,
  MessageSquare,
  ThumbsUp,
} from "lucide-react";
import React from "react";
import { XIcon } from "~/assets/icons";

const interactFooterButton = (icon: React.ReactNode, text: string) => (
  <Flex gap="10px" align="center">
    {icon}
    <span className={classes.interactive_button_text}>
      <b>{text}</b>
    </span>
  </Flex>
);

const FacebookAdCard = ({
  brandName,
  primaryText,
  headline,
  destination,
  content,
  style,
}: PreviewCardProps) => {
  const domain = destination?.replace(/^(https?:\/\/)?(www.)?/, "");
  const header = (
    <Flex direction="column" className={classes.header} gap="8px">
      <Flex justify="between">
        <Flex gap="6px" align="center">
          <div className={classes.icon}></div>
          <Flex direction="column">
            <span className={classes.company_name}>{brandName}</span>
            <Flex gap="10px" align="center">
              <span className={classes.sponsored}>Sponsored</span>
              <Earth color="#656565" size="12px" />
            </Flex>
          </Flex>
        </Flex>
        <Flex gap="8px">
          <Ellipsis color="#656565" />
          <XIcon color="#656565" />
        </Flex>
      </Flex>
      <span>{primaryText}</span>
    </Flex>
  );

  const footer1 = (
    <Flex justify="between" className={classes.footer1} align="center">
      <Flex direction="column" gap="2px">
        <span className={classes.sponsored}>{domain}</span>
        <Text weight="bold" size="3">
          {headline}
        </Text>
      </Flex>
      <button className={classes.button}>Shop Now</button>
    </Flex>
  );

  const footer2 = (
    <Flex align="center" className={classes.footer2}>
      {interactFooterButton(<ThumbsUp color="#656565" />, "Like")}
      {interactFooterButton(<MessageSquare color="#656565" />, "Comment")}
      {interactFooterButton(<Forward color="#656565" />, "Share")}
    </Flex>
  );
  return (
    <Flex direction="column" className={classes.container} style={style}>
      {header}
      {content}
      {footer1}
      {footer2}
    </Flex>
  );
};

export default FacebookAdCard;
