import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import {
  RecommendedCampaignWizardSteps,
  useCampaignWizardDispatch,
} from "../../../contexts/CampaignWizardContext";
import { CampaignCardCommonProps } from "./CampaignCards";
import { ItemSetType } from "@openapi";
import axios from "axios";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useCampaignRecommendationQuery from "~/hooks/campaign/useCampaignRecommendationQuery";

const getItemSetTypeText = (itemSetType: ItemSetType) => {
  const itemSetTypeMap: { [key in ItemSetType]: string } = {
    [ItemSetType.back_in_stock]:
      "I was found some products that are now back in stock. Let's create a campaign to showcase it.",
    [ItemSetType.best_sellers]:
      "I was looking at some of your best selling products and found a set I think will work well in a campaign.",
    [ItemSetType.discounted_products]:
      "I saw you recently discounted a set of products. Let's create a campaign to get more people to see these great deals.",
    [ItemSetType.new_products]:
      "I saw you recently added some new products to your catalog. I picked a few to feature in a campaign.",
    [ItemSetType.new_collection]:
      "I saw you recently added a new collection. Let's create a campaign for it.",
    [ItemSetType.purchased_together]:
      "There's a set of products that your customers have been buying together recently.",
    [ItemSetType.slower_moving_goods]:
      "I found some products that have large inventory and are moving slower than others. Let's get some more eyes on them.",
    [ItemSetType.slowest_moving_goods]:
      "I found some products that have very large remaining inventory relative to your sales volume. We should run a promotion for these.",
    [ItemSetType.new_hero_product]:
      "I noticed a new hero product in your catalog. Let's create a campaign to showcase it.",
    [ItemSetType.hero_product]:
      "I noticed a hero product in your catalog. Let's create a campaign to showcase it.",
    [ItemSetType.trending_products]:
      "I found some trending products in your catalog. Let's create a campaign to showcase it.",
  };

  return itemSetTypeMap[itemSetType] || "";
};

const CampaignSummary: React.FC<CampaignCardCommonProps> = (props) => {
  const activeBrandID = useActiveBrandID();
  const { recommendationID } = useParams();
  const dispatch = useCampaignWizardDispatch();

  const {
    campaignRecommendation,
    isLoading,
    error: recommendationError,
  } = useCampaignRecommendationQuery(activeBrandID, recommendationID ?? null);

  const errorMessage = useMemo(() => {
    if (recommendationError && axios.isAxiosError(recommendationError)) {
      if (recommendationError.response?.data?.message) {
        return recommendationError.response.data.message;
      }
      if (recommendationError.response?.status === 404) {
        return "The campaign you're looking for doesn't exist.";
      }
      if (recommendationError.response?.status === 403) {
        return "You don't have permission to view this campaign.";
      }
    }
    return "An error occurred while fetching the campaign recommendation.";
  }, [recommendationError]);

  const subtitle = useMemo(() => {
    if (campaignRecommendation?.item_grouping_rationale) {
      const itemSetTypeText = getItemSetTypeText(
        campaignRecommendation.item_set_type as ItemSetType
      );
      return [itemSetTypeText, campaignRecommendation.item_grouping_rationale]
        .filter(Boolean)
        .join("\n\n");
    }
    return (
      campaignRecommendation?.summary ??
      campaignRecommendation?.creative_recommendations?.[0]?.title ??
      ""
    );
  }, [campaignRecommendation]);

  const subtitles = useMemo(() => {
    if (isLoading) {
      return "";
    }
    if (recommendationError) {
      return errorMessage;
    }
    return [
      subtitle,
      "Do you want to quickly review and tweak key campaign choices?",
    ];
  }, [isLoading, recommendationError, subtitle]);

  return (
    <CampaignWizardCard
      badgeTitle="Campaign Summary"
      subtitle={subtitles}
      isLoading={isLoading}
      backButtonProps={{
        hide: true,
      }}
      additionalButtons={
        <AppButton
          onClick={() => {
            dispatch({
              type: "SET_CURRENT_STEP",
              payload: {
                currentStep: RecommendedCampaignWizardSteps.CREATIVE_CONCEPT,
              },
            });
          }}
          variant="outlined"
          disabled={!campaignRecommendation}
        >
          All good. Let's see the creatives!
        </AppButton>
      }
      nextButtonProps={{
        text: "Yes, let's review.",
        disabled: !campaignRecommendation,
      }}
      hideFooter={isLoading}
      {...props}
    />
  );
};

export default CampaignSummary;
