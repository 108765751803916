import { Avatar, Flex, Text } from "@radix-ui/themes";
import { forwardRef } from "react";
import styled from "styled-components";
import markSrc from "~/assets/mark.png";
import { useCurrentUserState } from "~/contexts/CurrentUserContext";

const Container = styled(Flex)`
  min-width: 350px;
  max-width: fit-content;
  padding: 32px 32px 32px 48px;
  background-color: var(--primary-white);
  border-radius: 40px;
  font-size: 22px;
  font-weight: 600;
  box-shadow: 0px 1.57px 3.78px 0px #58422d06, 0px 6.93px 7.82px 0px #58422d0a,
    0px 17px 15.6px 0px #58422d0d, 0px 32.74px 30.58px 0px #58422d0f;
  & > .rt-Text {
    line-height: 1.2;
  }
`;

const MarkAvatar = styled(Avatar)`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  position: absolute;
  top: -12px;
  left: -12px;
`;

const StyledText = styled(Text)`
  font-family: var(--heading-font-family);
`;

type MarkPopupProps = {
  sayHello?: boolean;
  greetingsSuffix?: string;
} & React.ComponentProps<typeof Flex>;

const MarkPopup: React.FC<MarkPopupProps> = forwardRef<
  HTMLDivElement,
  MarkPopupProps
>(({ sayHello, children, greetingsSuffix, ...props }, ref) => {
  const { user } = useCurrentUserState();
  return (
    <Container direction="column" {...props} ref={ref}>
      <MarkAvatar src={markSrc} fallback="M" />
      {sayHello && user.status === "LOGGED_IN" && (
        <div>
          <StyledText style={{ color: "var(--primary-vibrant-orange)" }}>
            Hello {user.firstName}.
          </StyledText>
          {greetingsSuffix && <StyledText ml="1">{greetingsSuffix}</StyledText>}
        </div>
      )}
      {children}
    </Container>
  );
});

export default MarkPopup;
