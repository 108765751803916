import GrowingAnimation from "../../GrowingAnimation";
import { Flex } from "@radix-ui/themes";
import { ArrowRight } from "lucide-react";
import { useRef } from "react";
import styled from "styled-components";
import MarkPopup from "~/components/core/MarkPopup";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const StyledMarkPopup = styled(MarkPopup)`
  position: relative;
  width: 480px !important;
  height: 180px !important;
  padding: 24px 24px 24px 40px;
  box-shadow: unset !important;
  min-width: unset !important;
  min-height: unset !important;
  max-width: unset !important;
  max-height: unset !important;
`;

const Container = styled.div`
  overflow: visible;
  padding: 12px;
  width: fit-content;
  height: fit-content;
`;

export type OnboardingCardProps = {
  text: string;
  top: number;
  left: number;
  cancelText?: string;
  proceedText: string;
  onProceed?: () => void;
  onCancel?: () => void;
};

const OnboardingOverlayCard = ({
  text,
  top,
  left,
  cancelText,
  proceedText,
  onProceed,
  onCancel,
}: OnboardingCardProps) => {
  const cardRef = useRef<HTMLDivElement | null>(null);
  return (
    <GrowingAnimation
      elementRef={cardRef}
      top={top}
      left={left}
      style={{
        position: "fixed",
        borderRadius: "20px",
        boxShadow:
          "0px 0px 100px 100px rgb(255, 115, 0, 0.35), inset 0px 0px 100px 100px rgb(255, 115, 0, 0.35)",
      }}
    >
      <Container ref={cardRef}>
        <StyledMarkPopup sayHello greetingsSuffix={text}>
          <Flex direction="column">
            <Flex align="center" justify="end" mt="4" gap="4">
              <AppButton variant="outlined" onClick={onCancel}>
                {cancelText ?? "Later"}
              </AppButton>
              <AppButton onClick={onProceed}>
                {proceedText}
                <ArrowRight strokeWidth="1.5px" />
              </AppButton>
            </Flex>
          </Flex>
        </StyledMarkPopup>
      </Container>
    </GrowingAnimation>
  );
};
export default OnboardingOverlayCard;
