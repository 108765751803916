import { Skeleton } from "@radix-ui/themes";
import styled from "styled-components";

const Container = styled.div<{ $minHeight?: number }>`
  min-height: ${(props) => props?.$minHeight ?? 500}px;
  position: relative;
  width: 100%;
  z-index: 0;
`;
const Placeholder = styled(Skeleton)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: grey;
  z-index: -1;
`;

const ImageContainer = ({
  children,
  minHeight,
}: {
  children: JSX.Element;
  minHeight?: number;
}) => {
  return (
    <Container $minHeight={minHeight}>
      <Placeholder />
      {children}
    </Container>
  );
};
export default ImageContainer;
