import AudienceSelector, {
  AudienceSelectorItem,
} from "../assets/selector/AudienceSelector";
import AppButton from "../core/buttons/AppButton/AppButton";
import { Dialog, Flex, Text, IconButton } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { XIcon } from "~/assets/icons";

type AudienceDialogProps = {
  trigger: React.ReactNode;
  onConfirm: (
    audiences: AudienceSelectorItem[],
    exclusions: AudienceSelectorItem[]
  ) => void;
  initialAudiences: AudienceSelectorItem[];
  initialExclusions?: AudienceSelectorItem[];
};

const AudienceDialog: React.FC<AudienceDialogProps> = ({
  initialAudiences,
  initialExclusions,
  trigger,
  onConfirm,
}) => {
  const [selectedAudiences, setSelectedAudiences] = useState<
    AudienceSelectorItem[]
  >(initialAudiences ?? []);
  const [audienceExclusions, setAudienceExclusions] = useState<
    AudienceSelectorItem[]
  >(initialExclusions ?? []);

  useEffect(() => {
    setSelectedAudiences(initialAudiences ?? []);
  }, [initialAudiences]);

  useEffect(() => {
    setAudienceExclusions(initialExclusions ?? []);
  }, [initialExclusions]);

  return (
    <Dialog.Root>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>
      <Dialog.Content>
        <Flex direction="column" gap="4">
          <Flex direction="row" justify="between" align="center">
            <Text size="4" weight="bold">
              Select Audience
            </Text>
            <Dialog.Close>
              <IconButton
                style={{
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                color="gray"
                variant="outline"
              >
                <XIcon color="#000000A6" />
              </IconButton>
            </Dialog.Close>
          </Flex>

          <AudienceSelector
            audiences={selectedAudiences}
            onChange={(audiences) => setSelectedAudiences(audiences)}
            exclusions={audienceExclusions}
            onChangeExclusions={(exclusions) =>
              setAudienceExclusions(exclusions)
            }
          />
          <Flex justify="end" pt="16px">
            <Flex gap="3">
              <Dialog.Close>
                <AppButton variant="outlined" radius="large">
                  Cancel
                </AppButton>
              </Dialog.Close>
              <Dialog.Close>
                <AppButton
                  variant="dark"
                  radius="large"
                  onClick={() =>
                    onConfirm(selectedAudiences, audienceExclusions)
                  }
                  disabled={!selectedAudiences.length}
                >
                  Confirm
                </AppButton>
              </Dialog.Close>
            </Flex>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default AudienceDialog;
