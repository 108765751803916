import { components } from "@openapi";

type EmailCollectionElementSchema =
  components["schemas"]["EmailCollectionElementSchema"];
type EmailProductElementSchema =
  components["schemas"]["EmailProductElementSchema"];

type EmailSectionElementUnionSchema =
  | EmailCollectionElementSchema
  | EmailProductElementSchema;

export const isCollectionElement = (
  data: EmailSectionElementUnionSchema
): data is EmailCollectionElementSchema => {
  return data.type === "collection";
};
export const isProductElement = (
  data: EmailSectionElementUnionSchema
): data is EmailProductElementSchema => {
  return data.type === "product";
};
