import { Flex, Skeleton } from "@radix-ui/themes";
import styled from "styled-components";
import { ArrowRightIcon } from "~/assets/icons";
import DraperText from "~/components/core/DraperText";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const Card = styled(Flex)`
  opacity: 0.5;
  border: 1px solid var(--border-primary);
  border-radius: 32px;
  background-color: var(--primary-white);
  box-shadow: 0px 4px 8px 0px #0000000a;
`;

const TitleText = styled(DraperText)`
  font-family: var(--heading-font-family);
  font-weight: 600;
`;
const ImagePlaceholder = styled.div`
  background: var(--background-pink-1);
  border-radius: 12px;
  width: 100%;
  height: 220px;
`;

const CampaignDraftDummyCard = () => {
  return (
    <Card direction="column" p="16px" gap="5" justify="between">
      <Flex direction="column" gap="4">
        <ImagePlaceholder />

        <TitleText size="7" weight="medium" clamp={2}>
          Draft
        </TitleText>

        <Skeleton
          width="100%"
          height="25px"
          style={{ borderRadius: "12px" }}
        ></Skeleton>
      </Flex>

      <AppButton variant="outlined" style={{ width: "max-content" }}>
        Resume Editing
        <ArrowRightIcon />
      </AppButton>
    </Card>
  );
};

export default CampaignDraftDummyCard;
