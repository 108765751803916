import { useState } from "react";
import CampaignPublishDialog from "~/components/campaign/overview/CampaignPublishDialog";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import useGetCampaignDetails from "~/hooks/campaign/useGetCampaignDetails";

type PublishCreativeButtonProps = {
  isDisabled?: boolean;
  campaignId: string | null;
} & Required<
  Pick<
    React.ComponentProps<typeof CampaignPublishDialog>,
    "preselectedCreativeIds"
  >
>;

const PublishCreativeButton: React.FC<PublishCreativeButtonProps> = ({
  isDisabled = false,
  campaignId,
  ...dialogProps
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { data: campaignDetail, isLoading } = useGetCampaignDetails({
    campaignId: campaignId ?? undefined,
  });
  return (
    <>
      <AppButton
        variant="outlined"
        disabled={isDisabled || !campaignDetail || isLoading}
        onClick={() => setIsDialogOpen(true)}
      >
        Publish
      </AppButton>
      {campaignDetail && (
        <CampaignPublishDialog
          campaign={campaignDetail}
          open={isDialogOpen}
          onOpenChange={(open) => setIsDialogOpen(open)}
          {...dialogProps}
        />
      )}
    </>
  );
};

export default PublishCreativeButton;
