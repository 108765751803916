import DraperText from "./DraperText";
import { Flex } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";

type PillSize = "small" | "medium" | "large" | "full";

type PillProps = {
  backgroundColor?: string;
  size?: PillSize;
  icon?: React.ReactNode;
  children: React.ReactNode;
} & Omit<React.ComponentProps<typeof Flex>, "children" | "ref">;

const PillContainer = styled(Flex)<{
  $backgroundColor: string;
  size: PillSize;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.$backgroundColor};
  padding: ${(props) => {
    switch (props.size) {
      case "small":
        return "4px 8px";
      case "medium":
        return "6px 12px";
      case "large":
        return "8px 16px";
      case "full":
        return "8px 16px";
      default:
        return "6px 12px";
    }
  }};
  border-radius: ${(props) => (props.size === "full" ? "9999px" : "12px")};
`;

const DraperPill: React.FC<PillProps> = ({
  backgroundColor = "#DFF4F5",
  size = "medium",
  icon,
  children,
  ...containerProps
}) => {
  return (
    <PillContainer
      $backgroundColor={backgroundColor}
      size={size}
      gap="2"
      {...containerProps}
    >
      {icon}
      <DraperText
        size={size === "small" ? "1" : size === "large" ? "3" : "2"}
        weight="medium"
        variant="secondary"
      >
        {children}
      </DraperText>
    </PillContainer>
  );
};

export default DraperPill;
